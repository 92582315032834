import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import SignatureCreditService from '../../services/SignatureCreditService';
import CreditTransactionsTable from './CreditTransactionsTable';
import DebitCreditsTable from './DebitCreditsTable';
import { Pagination } from '@material-ui/lab';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/club/Footer';

const SignatureCredit = () => {
  const { t } = useTranslation('translation');
  const [totalCreditAmount, setTotalCreditAmount] = useState(null);
  const [loadingCreditTransactions, setLoadingCreditTransactions] = useState(false);
  const [loadingDebitTransactions, setLoadingDebitTransactions] = useState(false);
  const [creditTransactions, setCreditTransactions] = useState({
    current_page: 1,
    per_page: 10,
    data: [],
  });
  const [debitTransactions, setDebitTransactions] = useState({
    current_page: 1,
    per_page: 10,
    data: [],
  });
  const [filters, setFilters] = useState({
    start: '',
    end: '',
    status: '',
  });

  useEffect(() => {
    SignatureCreditService.getCreditAmount().then(setTotalCreditAmount);
  }, []);
  useEffect(() => {
    setLoadingCreditTransactions(true);
    SignatureCreditService.getSignatureTransactions(creditTransactions.current_page, creditTransactions.per_page, {
      ...filters,
      status: 'pending_payment',
    })
      .then(setCreditTransactions)
      .finally(() => setLoadingCreditTransactions(false));
  }, [filters, creditTransactions.current_page, creditTransactions.per_page]);

  useEffect(() => {
    setLoadingDebitTransactions(true);
    SignatureCreditService.getSignatureTransactions(debitTransactions.current_page, debitTransactions.per_page, {
      ...filters,
      status: filters.status || 'done_payment,spent',
    })
      .then(setDebitTransactions)
      .finally(() => setLoadingDebitTransactions(false));
  }, [filters, debitTransactions.current_page, debitTransactions.per_page]);
  const StatusOptions = [
    {
      value: 'spent',
      name: t('SignatureCreditPage.Spent'),
    },
    {
      value: 'done_payment',
      name: t('SignatureCreditPage.DonePayment'),
    },
  ];
  const changeFilter = (e) => {
    setFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value == 0 ? '' : e.target.value,
    }));
  };
  return (
    <React.Fragment>
      <div style={{ boxSizing: 'border-box', overflowY: 'scroll', minHeight: 'calc(100vh - 201px)' }}>
        <div style={{ maxWidth: 'calc(100% - 60px)', margin: '0 auto', display: 'flex', gap: 16 }}>
          <div
            className='card'
            style={{
              marginTop: 10,
              width: 'min-content',
              flex: 1,
            }}
          >
            <p className='card_title'>{t('SignatureCreditPage.FilterTitle')}</p>

            <form
              style={{ display: 'flex', gap: 22, alignItems: 'center', flexDirection: 'row' }}
              className='form_card'
            >
              <div className='form_group' style={{ width: 'min-content', flex: 1 }}>
                <label for='start'>{t('SignatureCreditPage.StartPeriod')}</label>
                <input id='start' name='start' onChange={changeFilter} className='form_control' type='date' />
              </div>
              <div className='form_group' style={{ width: 'min-content', flex: 1 }}>
                <label for=''>{t('SignatureCreditPage.EndPeriod')}</label>
                <input className='form_control' name='end' onChange={changeFilter} type='date' />
              </div>
              <div className='form_group' style={{ width: 'min-content', flex: 1 }}>
                <label for=''>{t('SignatureCreditPage.Status')}</label>
                <select onChange={changeFilter} name='status' className='form_control select'>
                  <option value={0}>Nenhuma das opções</option>
                  {StatusOptions.map((option) => (
                    <option value={option.value}>{option.name}</option>
                  ))}
                </select>
              </div>
            </form>

            {/* <div
            style={{
              borderRadius: 8,
              background: '#F2F2F2',
              display: 'flex',
              alignItems: 'center',
              padding: '16px 24px',
              marginTop: 16,
            }}
          >
            <input
              placeholder='Buscar por código, nome, CPF/CNPJ ou Telefone...'
              style={{ backgroundColor: 'transparent', border: 'none', flex: 1 }}
            />
            <FaSearch />
          </div> */}
          </div>
          <div
            className='card'
            style={{
              marginTop: 10,
              width: 'min-content',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                <div
                  style={{
                    width: 60,
                    height: 60,
                    backgroundColor: '#C4DBE47D',
                    borderRadius: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src='img/icones/request_quote.svg' />
                </div>
                <div>
                  <p>
                    {t('SignatureCreditPage.Balance')}

                    <button
                      style={{ border: 'none', backgroundColor: 'transparent' }}
                      data-tip={t('SignatureCreditPage.BalanceDescription')}
                    >
                      <img src='/img/icones/info.svg' />
                    </button>
                    <ReactTooltip backgroundColor='#D9D9D9' place='top' type='dark' effect='float' />
                  </p>
                  <p>
                    {totalCreditAmount !== null && (
                      <span style={{ color: '#004F2E', fontSize: '1.125rem', fontWeight: 700, lineHeight: 1 }}>
                        {totalCreditAmount}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <Link
                className='btn_card'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                to='/buy-signature-credit'
              >
                Comprar créditos
                <img src='img/icones/arrow_forward_ios.svg' />
              </Link>
            </div>
          </div>
        </div>

        <div className='d-flex mx-auto' style={{ maxWidth: 'calc(100% - 60px)' }}>
          <div style={{ flex: 0.6, display: 'flex', flexDirection: 'column' }} className='card'>
            <h2 className='card_title'>Transações pendentes</h2>
            {loadingCreditTransactions ? (
              <Loading type='1' style={{ alignSelf: 'center' }} />
            ) : (
              <>
                <CreditTransactionsTable
                  // lineColor={({ id }) => responseColor(formQuestions[id])}
                  transactions={creditTransactions.data}
                />
                <Pagination
                  count={creditTransactions.last_page ?? 1}
                  page={creditTransactions.current_page}
                  variant='outlined'
                  shape='rounded'
                  onChange={(_, page) => {
                    setCreditTransactions((prev) => ({
                      ...prev,
                      current_page: page,
                    }));
                  }}
                />
              </>
            )}
          </div>
          <div style={{ flex: 0.4, marginLeft: 16, flexDirection: 'column' }} className='card'>
            <h2 className='card_title'>Transações concluídas</h2>
            {loadingDebitTransactions ? (
              <Loading type='1' style={{ alignSelf: 'center' }} />
            ) : (
              <>
                <DebitCreditsTable
                  // lineColor={({ id }) => responseColor(formQuestions[id])}
                  transactions={debitTransactions.data}
                />
                <Pagination
                  count={debitTransactions.last_page ?? 1}
                  page={debitTransactions.current_page}
                  variant='outlined'
                  shape='rounded'
                  onChange={(_, page) => {
                    setDebitTransactions((prev) => ({
                      ...prev,
                      current_page: page,
                    }));
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default SignatureCredit;
