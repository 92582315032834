const translation: Translation = {
  translation: {
    FormatFunction: {
      intlCurrencyWithOptions: '{{value, currency(currency: BRL)}}',
      ParcelsTextFormatted: 'em até {{quantity}} x {{value, currency(currency: BRL)}}',
      intlPercentWithOptions: '{{value, number(minimumFractionDigits: 2)}}%',
      formatListConjunction: '{{value, list(type: conjunction)}}',
      formatListDisjunction: '{{value, list(type: disjunction)}}',
    },
    Mask: {
      RG: '',
      CPF: '###.###.###-##',
      CNPJ: '##.###.###/####-##',
      ZipCode: '#####-###',
      CellPhone: '(##)# ####-####',
      CellPhoneDDI: '+## (##)# ####-####',
      ThousandSeparator: '.',
      DecimalSeparator: ',',
    },
    SystemMenu: {
      Home: 'Home',
      'Controle de Acesso': 'Controle de Acesso',
      Ecommerce: 'Ecommerce',
      Cadastros: 'Cadastros',
      Comunicação: 'Comunicação',
      Aluno: 'Aluno',
      Funcionário: 'Funcionário',
      Turma: 'Turma',
      Contrato: 'Contrato',
      Comunicado: 'Comunicado',
      Perfil: 'Perfil',
      EAD: 'EAD',
      Configurações: 'Configurações',
      'Controle de Estoque': 'Controle de Estoque',
      'Ordem de Compra': 'Extrato de Vendas',
      Inventário: 'Inventário',
      'Extrado de Vendas': 'Extrato de Vendas',
      Funcionários: 'Funcionários',
      Alunos: 'Alunos',
      Turmas: 'Turmas',
      Avaliação: 'Avaliação',
      Contratos: 'Contratos',
      Pedidos: 'Pedidos',
      Produtos: 'Produtos',
      Financeiro: 'Financeiro',
      Relatórios: 'Relatórios',
      Cursos: 'Cursos',
      Fornecedor: 'Fornecedor',
      'Assinaturas digitais': 'Assinaturas digitais',
      'Pedidos Vendas': 'Pedidos Vendas',
      'Produtos Clube': 'Produtos Clubes',
      Exercícios: 'Exercícios',
      Frequência: 'Frequência',
      Escolas: 'Escolas',
      Estoque: 'Estoque',
      'Meus Pedidos': 'Meus Pedidos',
      Questionário: 'Questionário',
      Documentos: 'Documentos',
      'Upload assinatura': 'Upload assinatura',
      'Doc. Alunos': 'Doc. Alunos',
      'Token API': 'Token API',
      'Aluguel de Quadra': 'Aluguel de Quadra',
      'Doc. Escolas': 'Doc. Escolas',
      'Planej. Aulas': 'Planej. Aulas',
      Aulas: 'Aulas',
      Certificados: 'Certificados',
      OrdemDeCompra: 'Ordem de compra',
      Invetory: 'Inventário',
      StockAdjustment: 'Ajuste de estoque',
      ControleDeEstoque: 'Controle de Estoque',
      SportsTypes: 'Tipos de Esportes',
      Produto: 'Produto',
      'Documentos Importantes': 'Documentos Importantes',
      'Cobrança Avulsa': 'Cobrança Avulsa',
      'Planejamento de Aula': 'Planejamento de Aula',
      Exercício: 'Exercício',
      'Documentos Obrigatórios': 'Documentos Obrigatórios',
      'Personalizar Contrato': 'Personalizar Contrato',
      'Upload da Assinatura': 'Upload da Assinatura',
      'Fluxo de Caixa': 'Fluxo de Caixa',
      'Financeiro por turma': 'Financeiro por turma',
      'Tipos de Esportes': 'Tipos de Esportes',
      'Alterar Senha': 'Alterar Senha',
      Metodologia: 'Metodologia',
      'Notas fiscais': 'Notas fiscais',
      'Politica de Preços': 'Politica de Preços',
    },
    SystemFooter: {
      HelpCenter: 'Central de ajuda',
      UseTerms: 'Termos de uso',
      PrivacyPolicy: 'Política de privacidade',
      Copyright: 'Grupo Fensor. Todos os direitos reservados.',
    },
    SystemMessage: {
      Loading: {
        WaitValidating: 'Aguarde, estamos validando...',
        WaitSending: 'Aguarde, estamos enviando...',
        Processing: 'Processando...',
        Wait: 'Aguarde...',
        WaitDeleting: 'Aguarde, estamos excluindo...',
        WaitProcessing: 'Aguarde, estamos processando...',
        Loading: 'Carregando...',
      },
      Success: {
        Redirect: 'Estamos redirecionando...',
        UserCreated: 'Usuário salvo(a).',
        UserUpdated: 'Usuário atualizado(a).',
        DocumentSaved: 'Documento salvo(a)',
        DocumentDeleted: 'Documento excluído(a).',
        StudentCreated: 'Aluno(a) cadastrado(a).',
        StudentUpdated: 'Aluno(a) atualizado(a).',
        ClassCreated: 'Turma criada com sucesso.',
        ClassUpdated: 'Turma atualizada com sucesso.',
        EvaluationSaved: 'Avaliação salva com sucesso.',
        EvaluationDeleted: 'Avaliação excluída com sucesso.',
        SavedSuccessfully: 'Salvo com sucesso.',
        CoverUpdated: 'Capa atualizada com sucesso!',
        ProductCreatedSuccess: 'Produto cadastrado com sucesso.',
        ProductUpdatedSuccess: 'Produto atualizado com sucesso.',
        PurchaseOrderCreatedSuccess: 'Ordem de compra criada com sucesso.',
        PurchaseOrderUpdatedSuccess: 'Ordem de compra atualizada com sucesso.',
        CategoryProductCreated: 'Categoria criada.',
        TypeServiceProductCreated: 'Tipo de serviço criado.',
        ScheduledBillingSuccessfully: 'Cobrança agendada com sucesso. O cliente será cobrado em todo vencimento.',
        AccountAndBillingDeleted: 'Conta e cobrança excluída.',
        CreateSuccess: 'Criado com sucesso.',
        UpdateSuccess: 'Atualizado com sucesso.',
        JustificationSaved: 'Justificativa salva com sucesso.',
        JustificationSavedWarning:
          'A justificativa foi salva, porém não foi possível remover alguns dos anexos. Por favor, tente novamente.',
        CancellationRequested: 'O pedido foi cancelado.',
        StockAdjustmentUpdate: 'O estoque foi atualizado com sucesso.',
      },
      Fail: {
        RequeridFieldsWith: 'Os campos são obrigatórios quando {{field}} está presente.',
        UniqueFields: 'O campo deve ser unico.',
        RequeridFields: 'Campos obrigatórios não preenchidos.',
        InternalError: 'Ocorreu um erro interno. Por favor, recarregue a página e tente novamente.',
        InternalErrorTryAgain: 'Ocorreu um erro interno. Por favor, tente novamente.',
        EmailAlreadyExists: 'Este email já está cadastrado na plataforma.',
        LoginAlreadyExists: 'Este login já está cadastrado na plataforma, por favor, escolha outro.',
        LoginEmpty: 'O campo login não pode ser vazio.',
        CPFAlreadyExists: 'Este CPF já está cadastrado na plataforma.',
        ErrorRetry: 'Houve um erro. Confira os dados e tente novamente, por favor.',
        InvalidCNPJ: 'O CNPJ informado é inválido.',
        InvalidCPF: 'O CPF informado é inválido.',
        InvalidEmail: 'O e-mail informado é inválido.',
        InvalidBirthDate: 'A data de nascimento informada é inválida.',
        CPFOrCNPJRegisteredRequired: 'É obrigatório que o cliente ou fornecedor tenham um CPF ou CNPJ cadastrados.',
        DueDateInvalid: 'O vencimento para receitas não pode ser menor que hoje.',
        PaymentNotApproved: 'Pagamento não aprovado, tente novamente.',
        CommunicationProblemServer: 'Estamos com problema de comunicação com o servidor, tente novamente mais tarde.',
        SchoolMustBeParameterized: 'Esta escola deve ser parametrizada pela equipe Fensor. Entre em contato.',
        BillNotDeleted: 'Conta não excluída, tente novamente.',
        InternalErrorMissingData: 'Erro interno. Dados ausentes.',
        InvalidBody: 'A requisição possui campos inválidos.',
        ForbiddenResource: 'Infelizmente você não tem permissão para acessar esse recurso.',
        FinanceNotFound: 'Cobrança não encontrada.',
        FinanceNotPending: 'Não é possível receber em dinheiro pois esta cobrança não está pendente.',
        LongAdress: 'O campo endereço não pode ultrapssar 50 caracteres.',
        ContratEmpty: 'O contrato não pode ser vazio.',
        CPFAndEmailAlreadyExists: 'Este email e CPF já estão cadastrados na plataforma.',
      },
      File: {
        Success: {
          UploadSuccess: 'Upload Realizado.',
          PhotoUpdateSuccess: 'Foto atualizada.',
          PhotoSendSuccess: 'Foto enviada com sucesso.',
          PhotoDeletedSuccess: 'Foto excluída com sucesso.',
          DocumentAttachedSuccess: 'Documento anexado com sucesso.',
          FileDeleted: 'Arquivo excluído.',
          DocumentSaved: 'Documento salvo(a).',
        },
        Fail: {
          InvalidFile: 'Falha no upload. Contate o administrador.',
          InvalidFileMaximumSizeExceeded: 'Falha no upload. Arquivo maior do que 10MB. Contate o administrador.',
          MaximumSizeExceeded: 'O arquivo ultrapassa o limite de tamanho.',
          PartialUpload: 'O upload do arquivo foi feito parcialmente.',
          FileNotUploaded: 'Não foi feito o upload do arquivo.',
          DirectoryNotExists: 'Diretório não existe.',
          FailedWriteOnDisk: 'Falha ao gravar arquivo no disco.',
          ExtensionNotAllowed: 'Extensão de arquivo não permitida.',
          FileIsNotImage: 'O arquivo enviado não é uma imagem.',
          MimeTypeNotAllowed: 'O mime-type do arquivo não é permitido.',
          WidthHeightExceeded: 'A imagem excede o limite de altura ou largura permitidos.',
          FileSizeLargerThanAllowed: 'O arquivo é maior que o permitido pela aplicação.',
          FileSizeSmallerThanAllowed: 'A imagem é menor do que o tamanho mínimo solicitado.',
          DocumentNotFound: 'Documento não encontrado.',
          FileDeleteTryAgain: 'Erro ao remover arquivo. Por favor, tente recarregar a página.',
          InternalErrorMissingData: 'Erro interno. Dados ausentes.',
          InternalError: 'Erro interno. Por favor, recarregue a página e tente novamente.',
        },
      },
    },
    PaymentSystem: {
      Success: {},
      Fail: {
        NotFoundInvalidToken:
          'Esta fatura não está integrada com a conta atual. Por isso, foi cancelada apenas no sistema Fensor.',
      },
    },
    LoginPage: {
      ForgotPassword: 'Esqueceu a sua senha?',
      LoadingValidation: 'Aguarde, estamos validando...',
      LogInButton: 'Entrar',
      LoginResultMessage: {
        BlockByOverTrying: 'Você realizou muitas tentativas de login. Por favor, tente novamente em alguns segundos.',
        UserInactive: 'Usuário Inativo',
        UserBlocked: 'Usuário Bloqueado',
        UnauthorizedUserHere: 'Sem permissão para acessar neste endereço',
        ClubInactive: 'Clube Inativo',
        SchoolInactive: 'Escolinha Inativa',
        UserIsStudent:
          'Plataforma disponível para Escolinhas. Caso você seja um atleta acesse por www.fensor.com.br, caso seja fotógrafo ou convidado, acesse através do endereço www.labfut.com.br',
        Redirect: 'Estamos redirecionando...',
        InvalidCredentials: 'Este login não está ativo ou não existe.',
        LoginNotExists: 'Este login não está ativo ou não existe.',
      },
    },
    RecoveryPasswordPage: {
      LoadingValidation: 'Aguarde, estamos validando...',
      ToLogin: 'Para efetuar login, clique aqui',
      RecoveryButton: 'Recuperar',
      NewPasswordBackText: 'Após redigir a senha e salvar, clique aqui',
      RecoveryResultMessage: {
        InvalidEmail: 'Email não pertente a plataforma, favor realizar o cadastro.',
        Success: 'Email enviado, favor acessá-lo e seguir as instruções.',
        PasswordsNotMatch: 'As senhas não são iguais.',
        SuccessfulChange: 'Senha alterada. Redirecionando para o login...',
        InternalError: 'Ocorreu um erro interno. Tente novamente, por favor.',
        IncorrectPassword: 'Senha atual incorreta.',
        InvalidToken: 'Token inválido. Por favor, acesse a página de recuperação de senha novamente.',
      },
    },
    SchoolHomePage: {
      BirthdaySubtitle: 'Veja abaixo os aniversariantes desse mês.',
      AlunoEmDia: 'Alunos em dia',
      AlunoEmDiaSubtitle: 'Alunos sem parcelas vencidas',
      OverdueStudents: 'Alunos inadimplentes',
      OverdueStudentsSubtitle: 'Alunos com parcelas atrasadas',
      RegisteredStudents: 'Alunos cadastrados',
      RegisteredStudentsSubtitle: 'Quantidade total de alunos',
      InactiveStudents: 'Alunos inativos',
      InactiveStudentsSubtitle: 'Quantidade de alunos inativos',
      ContractsExpiring: 'Contratos vencendo',
      ContractsExpiringSubtitle: 'Contratos a vencer no mês',
      ExpiredContracts: 'Contratos vencidos',
      ExpiredContractsSubtitle: 'Contratos vencidos no mês',
      ActiveContracts: 'Contratos ativos',
      ActiveContractsSubtitle: 'Quantidade total de contratos ativos',
      NewContracts: 'Contratos novos',
      NewContractsSubtitle: 'Quantidade total de contratos novos',
      BillingsToReceive: 'Faturamento a receber',
      BillingsToReceiveSubtitle: 'Valor a receber no mês',
      OverdueBillings: 'Faturamento vencidos',
      OverdueBillingsSubtitle: 'Total de faturamento vencido',
      ReceivedBillings: 'Faturamento recebido',
      ReceivedBillingsSubtitle: 'Valor recebido no mês',
      TotalBilling: 'Faturamento total',
      TotalBillingSubtitle: 'Valor faturado no mês',
      MedicalExamExpired: 'Exame médico vencido',
      MedicalExamExpiredSubtitle: 'Alunos com exame médico vencido a 6 meses',
      NoMedicalExam: 'Sem exame médico',
      NoMedicalExamSubtitle: 'Alunos sem exame médico',
      StudentsMissingMandatoryDocuments: 'Doc. incompleta',
      StudentsMissingMandatoryDocumentsSubtitle: 'Alunos com documentação obrigatória ausente',
      StudentsActiveWithouContract: {
        Title: 'Alunos sem contrato',
        Subtitle: 'Alunos ativos sem contrato ativo',
      },
    },
    StudentHomePage: {
      AttendanceCard: {
        Title: 'Minha frequência',
        Subtitle: 'Verifique a sua frequência nas aulas.',
        Button: 'Ir para frequência',
      },
      CommunicationCard: {
        Title: 'Comunicação',
        Subtitle: 'Veja abaixo comunicados e notícias para você.',
      },
    },
    EmployeePage: {
      PageTitle: 'Funcionários',
      PageSubtitle: 'Veja abaixo o seu quadro de funcionários.',
      AddEmployeeCard: {
        Title: 'Adicionar funcionário',
        Subtitle: 'Clique abaixo para adicionar um funcionário.',
        Button: 'Adicionar funcionário',
      },
      FilterEmployeeCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de funcionários.',
      },
      Modal: {
        TitleAdd: 'Incluir funcionário',
        TitleEdit: 'Alterar funcionário',
        ButtonAdd: 'Incluir funcionário',
        ButtonEdit: 'Alterar funcionário',
        SystemMessage: {
          ErrorPhotoUserNotSaved: 'O funcionário precisa ser salvo antes de enviar a Foto!',
        },
      },
      FormErrors: {
        Name: 'Por favor, insira um nome.',
        Email: 'Por favor, insira um endereço de e-mail.',
        Phone: 'Por favor, insira um número de celular.',
        ValidPhone: 'Por favor, insira um número de celular válido.',
        Cpf: 'Por favor, insira um cpf válido.',
        Status: 'Por favor, selecione um status.',
        DataInicio: 'Por favor, insira uma data de início.',
        Role: 'Por favor, selecione uma função.',
      },
    },
    StudentPage: {
      PageTitle: 'Alunos',
      PageSubtitle: 'Veja abaixo os seus alunos.',
      AddStudentCard: {
        Title: 'Adicionar aluno',
        Subtitle: 'Clique abaixo para adicionar um aluno.',
        Button: 'Adicionar aluno',
        CopyLinkButton: 'Link Completo',
        CopyShortLinkButton: 'Link Resumido',
        CopyLinkSuccessButton: 'Copiado!',
      },
      FilterStudentCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de atletas.',
      },
      Modal: {
        TitleAdd: 'Incluir aluno',
        TitleEdit: 'Alterar aluno',
        ButtonAdd: 'Incluir aluno',
        ButtonEdit: 'Alterar aluno',
        OnlyStudentInThisClass: 'Mostrar apenas alunos dessa turma',
      },
      ExternalLink: {
        Title: 'Adicionar Aluno.',
        Subtitle: 'Preencha os dados para adicionar um aluno.',
        ButtonAdd: 'Cadastrar aluno',
        ButtonEdit: 'Atualizar aluno',
      },
      SystemMessage: {
        ErrorPhotoUserNotSaved: 'O aluno precisa ser salvo antes do enviar da Foto!',
        InvalidFields: 'Atenção! Campos destacados abaixo inválidos!',
        InvalidStudentCPF: 'CPF do aluno inválido',
        StudentRGExceedMaxSize: 'O RG/CERTIDÃO do aluno só pode ter no máximo 32 digitos',
        InvalidResponsibleCPF: 'CPF do responsável inválido',
        ResponsibleRGExceedMaxSize: 'O RG do responsável só pode ter no máximo 14 digitos',
        StudentAgeNotAllowed:
          'Nessa turma só são permitidos alunos que nasceram nos anos {{value, list(type: disjunction)}}. Por favor, contate a escola.',
        ClassNotFound: 'Turma não encontrada. Por favor, contate a escola.',
        MissingMandatoryDocuments: 'Os seguintes documentos são obrigatórios e ainda não foram anexados:',
        ConfirmDeleteTrainingCenter: 'Tem certeza de que deseja excluir este Centro de Treinamento?',
        InvalidPhoneNumberStudent: 'O número de telefone do aluno é inválido',
        InvalidPhoneNumberMother: 'O número de telefone da mãe é inválido',
        InvalidPhoneNumberFather: 'O número de telefone do pai é inválido',
        InvalidInputMaxLenght: 'O {{input}} ultrapassa o máximo de {{count}} caracters permitidos!',
        InvalidInputMinLenght: 'O {{input}} não atinge o mínimo de {{count}} caracters permitidos!',
        InvalidPhoneNumber: 'O número de telefone {{input}} é inválido',
        InvalidSchoolYear: 'O ano escolar {{input}} é inválido',
        BirthDateCannotBeFuture: 'A data de nascimento não pode ser maior que a data atual',
      },
      DocumentFileHelp: 'Tipos de arquivos permitidos: .pdf, .png, .jpg e .jpeg',
      DocumentFileUploadConfirm: 'Você já tem um arquivo salvo para esse arquivo deseja substituir?',
    },
    InactivateStudentModal: {
      FetchBaseDataFailMessage: 'Erro ao recuperar as informações sobre as turmas e contratos do aluno.',
      CancelContractFailMessage: 'Alguns faturamentos não foram cancelados.',

      StudentClassesSubtitle_one: 'Este aluno está em {{count}} turma.',
      StudentClassesSubtitle_other: 'Este aluno está em {{count}} turmas.',
      StudentClassesInstruction:
        'No botão ao lado você pode removê-lo de todas as turmas ou pode escolher individualmente na lista abaixo.',
      StudentNoClasses: 'Este aluno não está em nenhuma turma.',
      StudentContractsSubtitle_one: 'Este aluno possui {{count}} contrato ativo.',
      StudentContractsSubtitle_other: 'Este aluno possui {{count}} contratos ativos.',
      StudentContractsInstruction:
        'No botão ao lado você pode encerrar todos os contratos ou pode escolher individualmente na lista abaixo.',
      StudentNoContracts: 'Este aluno não possui nenhum contrato ativo.',

      NoBilling: 'Nenhum faturamento em aberto encontrado para este contrato.',
    },
    ClassPage: {
      PageTitle: 'Turmas cadastradas',
      PageSubtitle: 'Veja abaixo as turmas cadastradas.',
      CopyLinkSuccessButton: 'Copiado!',
      Year: 'Anos',
      AddClassCard: {
        Title: 'Adicionar Turma',
        Subtitle: 'Clique abaixo para adicionar uma turma.',
        Button: 'Adicionar turma',
      },
      FilterClassCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de turmas.',
      },
      Modal: {
        TitleAdd: 'Incluir turma',
        TitleEdit: 'Alterar turma',
        ButtonAdd: 'Incluir turma',
        ButtonEdit: 'Alterar turma',
        AllowedAges: 'Idades permitidas <0>(pelo ano de nascimento)</0>',
        AllowedAgesSimple: 'Idades permitidas (ano)',
      },
      FrequencyCard: {
        Title: 'Frequência dos alunos',
        Subtitle: 'Verifique a frequência dos seus alunos.',
        Button: 'Ir para Frequência',
        DownloadPDF: 'Baixar PDF',
      },
      LessonsCard: {
        Title: 'Aulas planejadas',
        Subtitle: 'Clique abaixo para ver as aulas planejadas',
        Button: 'Planejamento de Aulas',
      },
      LinkRegisterCard: {
        RegistrationLink: 'Link de cadastro',
        Description: 'Link para cadastro do aluno e inclusão direta na turma.',
        CopyLink: 'Copiar link',
        SendViaWhatsApp: 'Enviar link pelo WhatsApp',
      },
      SystemMessage: {
        MaxStudentsExceed: 'A quantidade máxima de alunos nessa turma foi atingida.',
        ScheduleAlreadyIncluded: 'Este dia e horário já foram incluídos.',
        StudentAgeNotFound: 'Não foram encontrados alunos com essa idade.',
        FirstChooseAges: 'Primeiro escolha as idades permitidas para escolher os alunos.',
        SelectSchedule: 'Selecione o dia e o horário da aula.',
        StudentAlreadyIncluded: 'Este aluno já foi incluído.',
        EmployeeAlreadyIncluded: 'Este funcionário já foi incluído.',
        StudentAgeNotAllowed: 'Há alunos cuja data de nascimento não atende aos requisitos:',
        RequiredFields: {
          Schedule: 'Os campos "Dia da semana" e "Horário" são obrigatórios.',
          Student: 'O campo "Aluno" é obrigatório.',
          Employee: 'O campo "Funcionário" é obrigatório.',
        },
      },
    },
    LessonPages: {
      LessonName: 'Nome da aula',
      LinkedClasses: 'Turmas vinculadas',
      Actions: 'Ações',

      IncludeLesson: 'Criar aula',
      ChangeLesson: 'Alterar aula',
      AddClubLesson: 'Adicionar aula do clube',

      ChooseLeast1Lesson: 'É necessário escolher ao menos 1 aula',
      ChooseLeast1Class: 'É necessário escolher ao menos 1 turma',

      AddLessonPlanning: 'Criar planejamento de aula',
      ChangeLessonPlanning: 'Alterar planejamento de aula',

      AddLesson: 'Adicionar aula',
      AddClass: 'Adicionar turma',

      FilterByClass: {
        Title: 'Filtrar',
        Subtitle: 'Filtrar avaliações por turma',
        Label: 'Turma',
        Name: 'Filtrar por nome',
      },

      PlanningForDate: 'Planejamento para a data',
      SelectedLessons: 'Aulas selecionadas',
      NoLessonSelected: 'Nenhuma aula selecionada',
      SelectedClasses: 'Turmas selecionadas',
      NoClassSelected: 'Nenhuma turma selecionada',
      Responsible: 'Responsáveis',
      NumberStudents: 'Quantidade de alunos',

      LessonPageTitle: 'Aulas',
      LessonPageSubtitle: 'Veja abaixo as aulas cadastradas',

      ButtonAddLesson: 'Adicionar aula',
      ButtonAddLessonSubtitle: 'Clique abaixo para adicionar uma aula.',

      ButtonAddLessonPlanning: 'Adicionar planejamento',
      ButtonAddLessonPlanningSubtitle: 'Clique abaixo para adicionar um planejamento.',

      FilterTitle: 'Procurar',
      FilterSubtitle: 'Filtrar as aulas',

      LessonPlanningTitle: 'Calendário de planejamento',
      LessonPlanningSubtitle: 'Veja abaixo o calendário das aulas planejadas',

      StudentLessonPage: {
        PageTitle: 'Aulas',
        PageSubtitle: 'Essas são as aulas planejadas para o dia selecionado',
        hasClasses: 'Possui aulas',
        TodayDay: 'Dia de hoje',
        SelectedDay: 'Dia selecionado',
        Assisted: 'Assistidas',
        Pending: 'Assistidas',
        SeeClasses: 'Ver turmas',
      },

      SystemMessage: {
        LessonCreated: 'Aula criada com sucesso.',
        LessonUpdated: 'Aula atualizada com sucesso.',

        LessonPlanningCreated: 'Planejamento de aula criado com sucesso.',
        LessonPlanningUpdated: 'Planejamento de aula atualizado com sucesso.',
      },
    },
    ClassDetailPage: {
      DetailCard: {
        Subtitle: 'Escolha abaixo um dia da turma que deseja ver.',
      },
      EmployeeCard: {
        Title: 'Funcionários',
        Subtitle: 'Veja abaixo os funcionários cadastrados nesta turma.',
      },
      StudentCard: {
        Title: 'Alunos',
        Subtitle: 'Veja abaixo os alunos cadastrados nesta turma.',
      },
    },
    ClassStudentAttendancePage: {
      ActionCard: {
        Title: 'Frequência',
        Subtitle: 'Escolha abaixo um dia da semana para realizar a chamada. Ou pesquise pelo nome da turma.',
        NoStudentClassesFound: 'Nenhuma turma encontrada para o dia selecionado.',
      },

      OptionsCard: {
        Title: 'Frequência',
        Subtitle: 'Escolha abaixo um dia para realizar a chamada.',
      },
      RollCard: {
        Title: 'Anotar frequência',
        Subtitle: 'Clique abaixo para realizar a chamada.',
        Button: 'Realizar chamada',
      },
      RollModal: {
        Title: 'Anotar frequência',
        Button: 'Buscar alunos',
      },
      ContentCard: {
        NoStudentClassSelected: 'Selecione uma turma ao lado.',
        Title: 'Frequência dos alunos',
        Subtitle: 'Veja abaixo os alunos cadastrados nesta turma.',
      },
      RegisterStudentAttendance: {
        NoClassSelected: 'Nenhuma turma selecionada',
        NoDateSelected: 'É necessário selecionar uma data e buscar os alunos.',
      },
      SystemMessage: {
        NoNotesFound: 'Nenhuma anotação de frequência para esta turma neste mês.',
        ChooseADay: 'Escolha um dia da semana para ver a frequência das turmas.',
        ChooseAClass: 'Escolha uma turma para ver a frequência dos alunos.',
        NoClassRegisteredInSchedule: 'Nenhuma turma cadastrada neste dia e mês.',
      },
    },
    AssessmentPage: {
      PageTitle: 'Avaliação',
      PageSubtitle: 'Realize avaliações e consulte avaliações antigas.',
      InputSearchPlaceholder: 'Pesquise por nome...',
      ButtonAddEvaluation: 'Adicionar nova avaliação',
      ButtonEditEvaluation: 'Editar avaliação',
      ButtonDeleteEvaluation: 'Excluir avaliação',
      ButtonExportEvaluationPDF: 'Exportar como PDF',
      ButtonSendEvaluation: 'Enviar através do WhatsApp',
      SystemMessage: {
        DeleteConfirmation:
          '<0>Atenção!</0> Deseja realmente excluir a avaliação deste aluno? <2>Se sim, clique aqui!</2>',
      },
    },
    AssessmentListPage: {
      PageTitle: 'Avaliação',
      PageSubtitle: 'Veja todas as avaliações dos alunos.',
    },
    AssessmentDetailsPage: {
      PageTitle: 'Avaliação',
      PageSubtitle: 'Veja a pontuação desta avaliação.',
    },
    AssessmentFormPage: {
      PageTitle: 'Avaliação',
      PageSubtitle: 'Avalie o aluno.',
      SaveButton: 'Salvar Avaliação',
      ExportButton: 'Exportar como PDF',
      SystemMessage: {
        AllFieldsRequired: 'Todos os campos são obrigatórios, exceto observação.',
        TeacherRequired: 'Selecione um professor para avaliação do aluno.',
      },
    },
    ReportsPage: {
      PageTitle: 'Relatórios',
      PageSubtitle: 'Veja abaixo os relatórios disponíveis.',
      EmployeeReports: 'Relatório dos funcionários',
      StudentReports: 'Relatório dos alunos',
      ContractReports: 'Relatório dos contratos',
      FinancialReports: 'Relatório do financeiro',
      athletesClasses: 'Relatório financeiro com turma',
      AttendanceReport: 'Relatório de frequência',
      DetailedFinancialReport: 'Relatório financeiro detalhado',
      CashFlowReport: 'Relatório de fluxo de caixa',
      BirthdaysMonthReport: 'Relatório de aniversariantes do Mês',
    },
    ClassFrequencyReportsPage: {
      Filter: {
        Title: 'Preparar relatório de frequência',
        Subtitle: 'Selecione uma turma, horário e período',
        ChooseTheClass: 'Escolha a turma',
        ChooseTime: 'Escolha o horário',
        ChooseTheYear: 'Escolha o ano',
        ChooseTheMonth: 'Escolha o mês',
        SearchAthlete: 'Buscar aluno',
        Athlete: 'Aluno',
      },
      Report: {
        Title: 'Relatório de frequência',
        Subtitle: 'Relatório gerado automaticamente com base nos dados fornecidos',
        Name: 'nome',
        Level: 'Nível',
        AbsencePercent: '% de faltas',
        PresencePercent: '% de presença',
        Absences: 'Faltas',
        Presences: 'Presenças',
        Justifications: 'Justificativas',
        AllClasses: 'Todas as turmas',
      },
      Indicators: {
        TotalStudents: 'Total de alunos',
        TotalClasses: 'Total de turmas',
        StudentAttendanceRate: 'Taxa de presença de alunos',
      },
    },
    FinancialReportsPage: {
      Filter: {
        Title: 'Relatório financeiro',
      },
      Indicators: {
        TotalExpense: 'Total de despesa',
        TotalIncome: 'Total de receita',
        Balance: 'Saldo no período',
      },
      xlsFile: 'relatório-financeiro',
    },
    CashFlowReportsPage: {
      PageTitle: 'Fluxo de caixa',
      xlsFile: 'relatório-fluxo-caixa',
    },
    CommunicationPage: {
      PageTitle: 'Resultado da pesquisa',
      PageSubtitle: 'Veja abaixo as últimas notícias cadastradas.',
      AddCommunicationCard: {
        NewsTitle: 'Adicionar notícia',
        NewsSubtitle: 'Clique abaixo para adicionar notícias para os alunos.',
        NewsButton: 'Adicionar notícia',
        CommunicationTitle: 'Adicionar comunicado',
        CommunicationSubtitle: 'Clique abaixo para se comunicar com os alunos.',
        CommunicationButton: 'Adicionar comunicado',
      },
      FilterCommunicationsCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de comunicação.',
      },
      CommunicationCard: {
        Change: 'Alterar',
        SeeDetails: 'Ver detalhes',
        SendMail: 'Enviar e-mail',
        SendViaWhatsApp: 'Enviar WhatsApp',
      },
      Modal: {
        TitleAddNews: 'Incluir notícia',
        TitleAddCommunication: 'Incluir comunicado',
        TitleEditNews: 'Alterar notícia',
        TitleEditCommunication: 'Alterar comunicado',
        ButtonAddNews: 'Incluir notícia',
        ButtonAddCommunication: 'Incluir comunicado',
        ButtonEditNews: 'Alterar notícia',
        ButtonEditCommunication: 'Alterar comunicado',
      },
      SystemMessage: {
        ClassAlreadyIncluded: 'Esta Turma ja foi incluída.',
        DateInvalid: 'A data tem que ser igual ou maior que hoje.',
        FileNewsMustBeSaved: 'A notícia precisa ser salva antes do enviar do arquivo!',
        FileCommunicationMustBeSaved: 'O comunicado precisa ser salvo antes do enviar do arquivo!',
      },
    },
    ViewControlPage: {
      PageTitle: 'Controle de acesso',
      PageSubtitle: 'Configure abaixo a que módulos um usuário tem acesso. As alterações tem efeito ao relogar.',
    },
    CoursesPage: {
      PageTitle: 'Cursos',
      PageSubtitle: 'Veja abaixo a lista dos cursos disponíveis.',
      NoCoursesAvailable: 'Nenhum curso foi disponibilizado ainda.',
    },
    ExercisesPage: {
      PageTitle: 'Exercícios',
      PageSubtitle: 'Veja abaixo a lista dos exercícios disponíveis.',
      NoCoursesAvailable: 'Nenhum exercício foi disponibilizado ainda.',
    },
    InvoiceCreditConfigPage: {
      PageTitle: 'Planos de cŕedito de notas fiscais',
      PageSubtitle: 'Veja abaixo a lista dos planos disponíveis.',
      NoCoursesAvailable: 'Nenhum plano foi criado ainda.',
    },
    DocumentsPage: {
      PageTitle: 'Documentos anexos',
      PageSubtitle: 'Veja abaixo os documentos disponíveis para download.',
      NoDocumentsProvidedByClub: 'Nenhum documento disponibilizado pelo clube.',
    },
    ClubSchoolDocumentsPage: {
      PageTitle: 'Documentos anexos',
      PageSubtitle: 'Veja abaixo os documentos disponíveis para download.',
      ButtonAddClubSchoolDocuments: 'Adicionar documento',
      Modal: {
        TitleAdd: 'Incluir documento',
        TitleEdit: 'Alterar documento',
        ButtonAdd: 'Incluir documento',
        ButtonEdit: 'Alterar documento',
      },
      SystemMessage: {
        DeleteConfirmation: 'Tem certeza que deseja apagar esse documento?',
      },
    },
    DocumentsAttachedDefault: {
      'Avaliação de alunos': 'Avaliação de alunos',
      'Avaliação de Instrutores do América': 'Avaliação de Instrutores do América',
      'Avaliação de pais de alunos': 'Avaliação de pais de alunos',
      'Avaliações - Testes internos': 'Avaliações - Testes internos',
      'Contrato de Matrícula': 'Contrato de Matrícula',
      'Ficha de Matrícula': 'Ficha de Matrícula',
      'Modelo Sintese Avaliação de Alunos': 'Modelo Sintese Avaliação de Alunos',
      'Planejamento de treinos - em branco': 'Planejamento de treinos - em branco',
      'Planejamento de treinos': 'Planejamento de treinos',
      'Plano de negócios e resultados escolas': 'Plano de negócios e resultados escolas',
    },
    ProductsManagerPage: {
      PageTitle: 'Produtos',
      PageSubtitle: 'Veja abaixo o seu quadro de produtos.',
      AddProductsCard: {
        Title: 'Cadastrar produtos/serviço',
        Subtitle: 'Clique abaixo para cadastrar novos produtos ou serviços.',
        Button: 'Cadastrar novo produto/serviço',
      },
      FilterProductsCard: {
        Title: 'Procurar produto/serviço',
        Subtitle: 'Resultados correspondentes.',
      },
      Modal: {
        TitleAdd: 'Adicionar produto/Serviço',
        TitleEdit: 'Alterar produto/Serviço',
        ButtonAdd: 'Criar produto/serviço',
        ButtonEdit: 'Alterar produto/serviço',
      },
    },
    RequestsPage: {
      PageTitle: 'Pedidos',
      PageSubtitle: 'Acompanhe aqui o andamento de seus pedidos.',
      FilterRequestsCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de pedidos.',
      },
    },
    MyRequestsPage: {
      PageTitle: 'Pedidos',
      PageSubtitle: 'Acompanhe aqui o andamento de seus pedidos.',
      FilterCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de pedidos.',
      },
    },
    StudentProductsPage: {
      PageTitle: 'Produtos',
      PageSubtitle: 'Veja abaixo os produtos disponíveis para compra.',
      FilterCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de produto.',
      },
    },
    StudentDocumentsPage: {
      PageTitle: 'Documentos',
      PageSubtitle: 'Veja abaixo os seus documentos.',
      PageSubtitleWarning: 'Não é possível remover os documentos enviados pela escola.',
      CertificateSubtitle: 'Clique abaixo para visualizar o seu certificado atual.',
      ButtonCertificate: 'Visualizar certificado',
      ActionCard: {
        Title: 'Enviar documento',
        Subtitle: 'Clique abaixo para fazer o upload de um documento.',
        Button: 'Upload',
      },
      SystemMessage: {
        DeleteConfirmation: 'Tem certeza que deseja apagar este documento?',
        DocumentTypeIsRequired: 'O tipo do arquivo é obrigatório.',
      },
    },
    StudentFinancialPage: {
      PageTitle: 'Resultado da pesquisa',
      PageSubtitle: 'Veja abaixo seus pagamentos cadastrados.',
      EmptyTable: 'Nenhum pagamento criada até o momento neste período.',
      FilterCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar seus pagamentos.',
      },
    },
    StudentCartPage: {
      PageTitle: 'Seu carrinho',
      PageSubtitle: 'Veja abaixo os produtos adicionados ao carrinho.',
      EmptyCartMessage: 'Nenhum produto adicionado ao carrinho.',
    },
    StudentContractsPage: {
      PageTitle: 'Resultado da pesquisa',
      PageSubtitle: 'Veja abaixo os contratos cadastrados.',
      ListCard: {
        Title: 'Alunos com contrato',
        Subtitle: 'Lista de Alunos com contrato ativos',
        List: {
          Student: 'Aluno',
          Class: 'Turma',
          Value: 'Valor',
          Status: 'Status',
          Action: '',
          Invoiced: 'Faturado',
          Yes: 'Sim',
          No: 'Não',
        },
      },
      ActionCard: {
        Title: 'Adicionar contrato',
        Subtitle: 'Clique abaixo para adicionar contratos para os alunos.',
        Button: 'Adicionar contrato',
        ButtonMultiple: 'Criar Contratos Múltiplos',
      },
      CustomContractCard: {
        title: 'Personalizar contrato',
        Subtitle: 'Clique abaixo para personalizar o contrato da sua escola.',
        Button: 'Personalizar contrato',
      },
      FilterCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os contrato.',
      },
      ActionsTableList: {
        addButton: 'Criar novo contrato',
        editButton: 'Editar contrato',
        WhatsAppButton: 'Enviar pelo whatsApp',
        printerButton: 'Imprimir contrato',
        showPass: 'Ver Carteirinha',
        showStudent: 'Visualizar Aluno',
      },
      Modal: {
        TitleAdd: 'Incluir contrato',
        TitleEdit: 'Alterar contrato',
        ButtonAdd: 'Incluir contrato',
        ButtonEdit: 'Alterar contrato',
      },
      SystemMessage: {
        WarningInvoiceContract:
          '<0>Atenção!</0> Após faturar, serão agendadas as cobranças deste Aluno e não serão permitidas alterações referentes ao financeiro deste contrato.<2 /><3 />Deseja continuar? <5>Se sim, clique aqui!</5>',
        WarningCancelInvoiceContract:
          '<0>Info!</0> Este contrato está faturado, o aluno recebe a cobrança em todo vencimento.<2 /><3 />Deseja encerrar ou cancelar esta cobrança? <5>Se sim, clique aqui!</5>',
        WarningInvoiceContractMoney:
          '<0>Atenção!</0> Após faturar, serão agendadas as cobranças em dinheiro deste Aluno e não serão permitidas alterações referentes ao financeiro deste contrato.<2 /><3 />Deseja continuar? <5>Se sim, clique aqui!</5>',
        WarningCancelInvoiceContractMoney:
          '<0>Info!</0> Este contrato esta faturado. Ao cancelar, todas as parcelas em aberto serão excluídas.<2 /><3 />Deseja encerrar ou cancelar esta cobrança? <5>Se sim, clique aqui!</5>',
        WarningInactiveContractInvoiced:
          'Para inativar um contrato com faturamento em vigor, primeiro deve-se cancelar o faturamento, depois inativar o contrato.',

        FirstDueDateInvalid: 'A "Data do primeiro vencimento" deve ser superior a "Data início do Contrato".',
        EndDateInvalid: 'A "Data fim do Contrato" deve ser superior a "Data início do Contrato".',
        ResponsibleCPFInvalid: 'O CPF do responsável é inválido, altere o cadastro e tente novamente.',
        SubscribeApprovedNotifyStudent: 'Assinatura aprovada. O Aluno será comunicado em todo vencimento.',
        SubscribeApproved: 'Assinatura aprovada.',
        SubscribeUnapproved: 'Assinatura não aprovada, tente novamente.',
        CommunicationProblemServer: 'Estamos com problema de comunicação com o servidor, tente novamente mais tarde.',
        SchoolMustBeParameterized: 'Esta escola deve ser parametrizada pela equipe Fensor. Entre em contato.',
        ValidationError: 'Houve um erro de validação. Revise os dados e tente novamente.',
        InvoiceCanceled: 'Faturamento cancelado.',
        InvoiceCancelFail: 'Faturamento não cancelado. Por favor, tente novamente.',
        InvoiceCancelFailTemp: 'Faturamento não cancelado. Por favor, espere um pouco e tente novamente.',
        ServiceCreatedSuccess: 'Serviço cadastrado com sucesso.',
        ServiceDeletedSuccess: 'Serviço excluído com sucesso. Caso exista contrato com este serviço, favor atualizar.',
        MaximumStudentsExceed: 'A quantidade máxima de alunos nessa turma foi atingida.',
        DateValidationError: 'Erro ao validar datas.',
        CreateSuccess: 'Criado com sucesso.',
        UpdateSuccess: 'Atualizado com sucesso.',
      },
    },
    SchoolFinancePage: {
      PageTitle: 'Resultado da pesquisa',
      PageSubtitle: 'Veja abaixo os contratos cadastrados.',
      ActionCard: {
        Title: 'Adicionar conta',
        Subtitle: 'Clique abaixo para adicionar uma conta.',
        Button: 'Adicionar conta',
      },
      FilterCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar as contas a receber e a pagar.',
      },
      Modal: {
        TitleAdd: 'Incluir conta',
        TitleEdit: 'Alterar conta',
        ButtonAdd: 'Incluir conta',
        ButtonEdit: 'Alterar conta',
        ButtonDelete: 'Excluir conta',
        ButtonDeleteCancelInvoice: 'Excluir conta e cobrança',
        ReceiveInCash: 'Receber em dinheiro',
      },
      SystemMessage: {
        WarningInvoice:
          '<0>Atenção!</0> Após faturar, serão agendadas as cobranças deste cliente e não serão permitidas alterações nesta conta.<2 /><3 />Deseja continuar? <5>Se sim, clique aqui!</5>',
        WarningCancelInvoice:
          '<0>Info.!</0> Esta conta foi faturada e o cliente recebe a cobrança em todo vencimento.<2 /><3 />Deseja realmente excluir esta cobrança? <5>Se sim, clique aqui!</5>',
      },
    },
    MandatoryDocsPage: {
      PageTitle: 'Documentos obrigatórios dos alunos',
      PageSubtitle: 'Configure abaixo quais documentos dos alunos são obrigatórios.',
    },
    ClubDashboardPage: {
      TotalStudents: 'Total de alunos',
      TotalStudentsSubtitle: 'Total de alunos de todas as escolas do clube',
      TotalStudentsFilename: 'total-alunos',
      ActiveStudents: 'Alunos ativos',
      ActiveStudentsSubtitle: 'Total de alunos ativos de todas as escolas do clube',
      ActiveStudentsFilename: 'alunos-ativos',
      InactiveStudents: 'Alunos inativos',
      InactiveStudentsSubtitle: 'Total de alunos inativos de todas as escolas do clube',
      InactiveStudentsFilename: 'alunos-inativos',
      NoDefaultingStudents: 'Alunos adimplentes',
      NoDefaultingStudentsSubtitle: 'Total de alunos adimplentes de todas as escolas do clube',
      NoDefaultingStudentsFilename: 'alunos-adimplentes',
      DefaultingStudents: 'Alunos inadimplentes',
      DefaultingStudentsSubtitle: 'Total de alunos inadimplentes de todas as escolas do clube',
      DefaultingStudentsFilename: 'alunos-inadimplentes',
      MaleStudents: 'Alunos ativos',
      MaleStudentsSubtitle: 'Total de alunos do sexo biológico masculino da escola',
      MaleStudentsFilename: 'alunos-inadimplentes',
      FemaleStudents: 'Alunas ativas',
      FemaleStudentsSubtitle: 'Total de alunas do sexo biológico feminino da escola',
      FemaleStudentsFilename: 'alunos-inadimplentes',

      MonthlyPlans: 'Planos mensais',
      MonthlyPlansSubtitle: 'Total de contratos ativos com ciclo mensal de todas as escolas do clube',
      MonthlyPlansFilename: 'planos-mensais',
      QuarterlyPlans: 'Planos trimestrais',
      QuarterlyPlansSubtitle: 'Total de contratos ativos com ciclo trimestral de todas as escolas do clube',
      QuarterlyPlansFilename: 'planos-trimestrais',
      AnnualPlans: 'Planos anuais',
      AnnualPlansSubtitle: 'Total de contratos ativos com ciclo anual de todas as escolas do clube',
      AnnualPlansFilename: 'planos-anuais',
      PlansToExpire: 'Planos à vencer ',
      PlansToExpireSubtitle: 'Total de contratos que vencerão nos próximos 30 dias de todas as escolas',
      PlansToExpireFilename: 'planos-à-vencer ',
      ExpiredPlans: 'Planos vencidos',
      ExpiredPlansSubtitle: 'Total de contratos ativos que estão vencidos de todas as escolas do clube',
      ExpiredPlansFilename: 'planos-vencidos',

      GrossIncome: 'Faturamento bruto',
      GrossIncomeSubtitle: 'Faturamento total de todas as escolas do clube',
      GrossIncomeFilename: 'faturamento-bruto',
      TuitionsIncome: 'Renda de mensalidades',
      TuitionsIncomeSubtitle: 'Faturamento total de todos os contratos das escolas do clube',
      TuitionsIncomeFilename: 'renda-mensalidades',
      IncomeWithOrders: 'Faturamento com pedidos',
      IncomeWithOrdersSubtitle: 'Faturamento de todas as vendas de produtos das escolas do clube',
      IncomeWithOrdersFilename: 'faturamento-pedidos',
      IncomeWithUniforms: 'Faturamento com uniformes',
      IncomeWithUniformsSubtitle: 'Faturamento de todas as vendas de uniformes das escolas do clube',
      IncomeWithUniformsFilename: 'faturamento-uniformes',
      IncomeWithEvents: 'Faturamento com eventos',
      IncomeWithEventsSubtitle: 'Faturamento recebido de eventos de todas as escolas do clube',
      IncomeWithEventsFilename: 'faturamento-eventos',
      TuitionsFee: 'Taxa de matrículas',
      TuitionsFeeSubtitle: '',
      TuitionsFeeFilename: 'taxa-matrículas',
    },
    ModalSignatureUpload: {
      Title: 'Upload da assinatura',
      Observation:
        'Obs.: Essa é a assinatura do gestor da escola. Ela será apresentada nos contratos dos alunos e nos recibos de pagamento.',
      Attention:
        '<0>Atenção:</0> Para uma qualidade melhor, garanta que a imagem contem apenas a assinatura, com fundo branco e o mínimo possível de borda branca.',
    },
    ModalApiSettings: {
      Title: 'Token API',
    },
    ModalHealthForm: {
      AreSmoker: 'É tabagista?',
      HowLong: 'Há quanto tempo?',
      Questions: {
        'Tem ou já teve as doenças abaixo?': 'Tem ou já teve as doenças abaixo?',
        'Doenças infecto-contagiosa (AIDS, Hepatite, Chagas, Tuberculose, meningite, outros)':
          'Doenças infecto-contagiosa (AIDS, Hepatite, Chagas, Tuberculose, meningite, outros)',
        'Doença respiratória (Efisema, Asthma, Bronquite, Pneumonia) e outros':
          'Doença respiratória (Efisema, Asthma, Bronquite, Pneumonia) e outros',
        'Problemas ortopédicos (Escoliose, Cifose, Artrose, Fraturas, Hérnia de Disco ou outro distúrbio ósseo ou de Membros)':
          'Problemas ortopédicos (Escoliose, Cifose, Artrose, Fraturas, Hérnia de Disco ou outro distúrbio ósseo ou de Membros)',
        'Doença neurológica (Derrame cerebral ou outros / Desmaios)':
          'Doença neurológica (Derrame cerebral ou outros / Desmaios)',
        'Diabetes (Açúcar no sangue)': 'Diabetes (Açúcar no sangue)',
        'Câncer (Tumoração ou Leucemia)': 'Câncer (Tumoração ou Leucemia)',
        'Hérnia de qualquer natureza': 'Hérnia de qualquer natureza',
        'Doença cardíaca (Insuficiência Cardíaca, Insuficiência coronariana, Angina, Cardiopatia congênita, arritmia) e outros':
          'Doença cardíaca (Insuficiência Cardíaca, Insuficiência coronariana, Angina, Cardiopatia congênita, arritmia) e outros',
        'Doença vascular (Varizes, Insuf. Arterial, Aneurisma, Tromboangelite) e outros':
          'Doença vascular (Varizes, Insuf. Arterial, Aneurisma, Tromboangelite) e outros',
        'Hipertensão arterial': 'Hipertensão arterial',
        'Doença Urinária (Rins, Bexiga, Próstata, Uretra, Cálc. Renal, Obstr. Urinária, Prostatismo, Incont. Urinária, Insuf. Renal, Perda de Urina, Pedra nos Rins) outros':
          'Doença Urinária (Rins, Bexiga, Próstata, Uretra, Cálc. Renal, Obstr. Urinária, Prostatismo, Incont. Urinária, Insuf. Renal, Perda de Urina, Pedra nos Rins) outros',
        'Distúrbio psquiátrico ou psicológico': 'Distúrbio psquiátrico ou psicológico',
        'Doença ginecológica (Cisto de ovário, endometriose, mioma, incontinência urinária, Tumor de mama) e outros':
          'Doença ginecológica (Cisto de ovário, endometriose, mioma, incontinência urinária, Tumor de mama) e outros',
        'Doença reumatológica (Reumatismo, artrite) e outros': 'Doença reumatológica (Reumatismo, artrite) e outros',
        'Sequela de acidente, Moléstia adquirida ou congênita (nasceu com a doença)':
          'Sequela de acidente, Moléstia adquirida ou congênita (nasceu com a doença)',
        'Úlcera ou doença do Ap. Digestivo (Probl. De Intestino, Hemorróida, Ictericia)':
          'Úlcera ou doença do Ap. Digestivo (Probl. De Intestino, Hemorróida, Ictericia)',
        'Tem indicação para submeter-se a alguma cirurgia': 'Tem indicação para submeter-se a alguma cirurgia',
        'Doença de Otorrinolaringologia (Adenóide, Amigdalite, Sinusite, Problemas de ouvido, Audição - Apneia Noturna ) e outros':
          'Doença de Otorrinolaringologia (Adenóide, Amigdalite, Sinusite, Problemas de ouvido, Audição - Apneia Noturna ) e outros',
        'Doença Oftalmológica ( Miopia de Alto Grau, problemas de retina - córnea) e outros / dificuldade para enxergar de perto ou de longe':
          'Doença Oftalmológica ( Miopia de Alto Grau, problemas de retina - córnea) e outros / dificuldade para enxergar de perto ou de longe',
        'Tem qualquer deficiência visual, catarata, glaucoma (pressão alta no olho) e outros':
          'Tem qualquer deficiência visual, catarata, glaucoma (pressão alta no olho) e outros',
        'Tratamento de Quimioterapia ou Radioterapia': 'Tratamento de Quimioterapia ou Radioterapia',

        'Cirurgias Realizadas': 'Cirurgias Realizadas',
        Especificação: 'Especificação',
        'Há quanto tempo?': 'Há quanto tempo?',
        'Já foi indicado para fazer algum tratamento clínico ou cirúrgico que não tenha realizado?':
          'Já foi indicado para fazer algum tratamento clínico ou cirúrgico que não tenha realizado?',
        Tratamento: 'Tratamento',
        'Nome do médico que indicou': 'Nome do médico que indicou',
      },
    },
    WhatsAppComponent: {
      Title: 'WhatsApp Web - QR CODE',
      NewQrCode: 'Conectar',
      SendMessageButton: 'Enviar WhatsApp',
      SendLinkButton: 'Enviar WhatsApp',
      SendAssessmentButton: 'Enviar avaliação',
      CloseSession: 'Encerrar sessão',
      RemoveSessionConfirmation: 'Você tem certeza que deseja encerrar a sessão?',
      RemoveSessionInstruction: 'Ao remover a sessão ativa, será necessário refazer a leitura do QR-CODE.',
      RemoveSession: 'Remover sessão',
      WriteYourMessage: 'Escreva a sua mensagem',
      SendMessage: 'Enviar mensagem',
      SendingMessages: 'Enviando mensagens...',
      SendButtonText: {
        SendNews: 'Enviar notícia',
        SendCommunication: 'Enviar comunicado',
        SendReceipt: 'Enviar recibo',
      },
      SendSuccessMessage: {
        LinkSentSuccessfully: 'Link enviado com sucesso pelo WhatsApp.',
        SendSuccessfully: 'Mensagem enviada com sucesso pelo WhatsApp.',
        EvaluationSendSuccessfully: 'Avaliação enviada com sucesso pelo WhatsApp.',
        ContractSendSuccessfully: 'Contrato enviado com sucesso pelo WhatsApp.',
        ModelCardSendSuccessfully: 'Carteirinha enviada com sucesso pelo WhatsApp.',
        ReceiptSendSuccessfully: 'Recibo enviado com sucesso pelo WhatsApp.',
      },
      SendFailMessage: {
        Fail: 'Algumas mensagens não foram enviadas.',
        IncorrectNumber: 'Número incorreto.',
        UnableRetrieveContactList: 'Não foi possível recuperar a lista de contatos.',
        NoContactsToSend: 'Não há contatos para enviar.',
      },
      Messages: {
        StudentRegistrationLink: 'Olá, *{{name}}*.\nSegue o link para realizar o cadastro: {{link}}',
        StudentClassRegistrationLink: 'Olá, *{{name}}*.\nSegue o link para realizar o cadastro: {{link}}',
      },
    },
    CertificatesModal: {
      ModalTitle: 'Certificados',
      CertificateHelpText: 'O texto entre asteriscos (*) ficará em <0>negrito</0>',
      LevelPlaceholder: 'Selecione um Nível para configurá-lo',
    },
    StatusType: {
      A: 'Ativo',
      I: 'Inativo',
      P: 'Pré-cadastrado',
      Active: 'Ativo',
      Active_question: 'Ativo?',
      Inactive: 'Inativo',
      PreRegistered: 'Pré-cadastrado',
      Closed: 'Encerrado',
      Ativo: 'Ativo',
      Inativo: 'Inativo',
      Encerrado: 'Encerrado',
      'Pré-cadastrado': 'Pré-cadastrado',
    },
    SportsTypesPage: {
      title: 'Esportes',
      textField: 'Adicionar Categorias de Esportes',
      Columns: {
        sports: 'Esportes',
        remove: 'Remover',
      },
    },
    Sports: {
      Name: {
        'Futebol Society Fut 7': 'Futebol Society Fut 7',
        Futsal: 'Futsal',
        Volei: 'Volei',
        Basquete: 'Basquete',
        'Futebol Campo': 'Futebol Campo',
        Natação: 'Natação',
        Tênis: 'Tênis',
        Academia: 'Academia',
        Personal: 'Personal',
        Ballet: 'Ballet',
        Dodgeball: 'Dodgeball',
        Hidroginástica: 'Hidroginástica',
        Handebol: 'Handebol',
        'Little Kickers': 'Little Kickers',
        'Beach Tennis': 'Beach Tennis',
      },

      Position: {
        Atacante: 'Atacante',
        'Atacante Beirada': 'Atacante Beirada',
        Zagueiro: 'Zagueiro',
        '1° Volante': '1° Volante',
        'Meia Direita': 'Meia Direita',
        'Lateral Esquerdo': 'Lateral Esquerdo',
        'Lateral Direito': 'Lateral Direito',
        Goleiro: 'Goleiro',
        Centroavante: 'Centroavante',
        '2° Volante': '2° Volante',
        'Meia Esquerda': 'Meia Esquerda',
        Lateral: 'Lateral',
        'Meio-Campo': 'Meio-Campo',
        Pivô: 'Pivô',
        Meia: 'Meia',
        Ponta: 'Ponta',
        'Armador Central': 'Armador Central',
        Fixo: 'Fixo',
        Ala: 'Ala',
        Libero: 'Líbero',
        Líbero: 'Líbero',
        'Ponta Direita': 'Ponta Direita',
        Levantador: 'Levantador',
        'Ponta Esquerda': 'Ponta Esquerda',
        Central: 'Central',
        Oposto: 'Oposto',
        Armador: 'Armador',
        'Ala-Armador': 'Ala-Armador',
        'Ala-Pivô': 'Ala-Pivô',
        Natação: 'Natação',
        'Sem posição': 'Sem posição',
      },
    },
    DocumentTypeModel: {
      'Atestado Médico - 30 dias': 'Atestado Médico - 30 dias',
      'Atestado Médico - 90 dias': 'Atestado Médico - 90 dias',
      'Autorização dos Pais': 'Autorização dos Pais',
      CPF: 'CPF',
      'Certidão de Nascimento': 'Certidão de Nascimento',
      'Comprovante de Endereço': 'Comprovante de Endereço',
      Contrato: 'Contrato',
      'Declaração de Matrícula Escolar': 'Declaração de Matrícula Escolar',
      'Eletro com Laudo Médico': 'Eletro com Laudo Médico',
      'Ficha de cadastro': 'Ficha de cadastro',
      'Foto 3 x 4': 'Foto 3 x 4',
      Passaporte: 'Passaporte',
      'RG - Carteira Identidade (Frente)': 'RG - Carteira Identidade (Frente)',
      'RG - Carteira Identidade (Verso)': 'RG - Carteira Identidade (Verso)',
      'RG e CPF do Responsável': 'RG e CPF do Responsável',
      'RG/CNH frente (responsável)': 'RG/CNH frente (responsável)',
      'RG/CNH verso (responsável)': 'RG/CNH verso (responsável)',
      RGS3: 'RGS3',
      'Relatório Avaliação Fisica': 'Relatório Avaliação Fisica',
      'Relatório Avaliação Fisica 2': 'Relatório Avaliação Fisica 2',
      'Relatório Avaliação Fisica 3': 'Relatório Avaliação Fisica 3',
      Tacticup: 'Tacticup',
      'Termo de Responsabilidade': 'Termo de Responsabilidade',
      'Teste COVID': 'Teste COVID',
      Boleto: 'Boleto',
      'Comprovante de pagamento': 'Comprovante de pagamento',
      'Carteirinha do convênio médico': 'Carteirinha do convênio médico',
      'Carta Clube/Escolinha': 'Carta Clube/Escolinha',
      'Declaração de Freq. Escola': 'Declaração de Freq. Escola',
      'Manual do Atleta': 'Manual do Atleta',
      'Contrato do Atleta': 'Contrato do Atleta',
      'Relatório Avaliação Física 2022/1': 'Relatório Avaliação Física 2022/1',
      'Relatório Avaliação Física 2022/2': 'Relatório Avaliação Física 2022/2',
    },
    MonthName: {
      janeiro: 'Janeiro',
      fevereiro: 'Fevereiro',
      março: 'Março',
      abril: 'Abril',
      maio: 'Maio',
      junho: 'Junho',
      julho: 'Julho',
      agosto: 'Agosto',
      setembro: 'Setembro',
      outubro: 'Outubro',
      novembro: 'Novembro',
      dezembro: 'Dezembro',

      January: 'Janeiro',
      January_abbreviated: 'Jan',
      February: 'Fevereiro',
      February_abbreviated: 'Fev',
      March: 'Março',
      March_abbreviated: 'Mar',
      April: 'Abril',
      April_abbreviated: 'Abr',
      May: 'Maio',
      May_abbreviated: 'Mai',
      June: 'Junho',
      June_abbreviated: 'Jun',
      July: 'Julho',
      July_abbreviated: 'Jul',
      August: 'Agosto',
      August_abbreviated: 'Ago',
      September: 'Setembro',
      September_abbreviated: 'Set',
      October: 'Outubro',
      October_abbreviated: 'Out',
      November: 'Novembro',
      November_abbreviated: 'Nov',
      December: 'Dezembro',
      December_abbreviated: 'Dez',
    },
    WeekdayName: {
      Sunday: 'Domingo',
      Monday: 'Segunda-feira',
      Tuesday: 'Terça-feira',
      Wednesday: 'Quarta-feira',
      Thursday: 'Quinta-feira',
      Friday: 'Sexta-feira',
      Saturday: 'Sábado',
      Domingo: 'Domingo',
      'Segunda-Feira': 'Segunda-feira',
      'Terça-Feira': 'Terça-feira',
      'Quarta-Feira': 'Quarta-feira',
      'Quinta-Feira': 'Quinta-feira',
      'Sexta-Feira': 'Sexta-feira',
      Sábado: 'Sábado',
      Dom: 'Dom',
      Seg: 'Seg',
      Ter: 'Ter',
      Qua: 'Qua',
      Qui: 'Qui',
      Sex: 'Sex',
      Sáb: 'Sáb',
    },
    EmployeeModel: {
      Occupations: {
        Director: 'Diretor(a)',
        Manager: 'Gestor(a)',
        Teacher: 'Professor(a)',
        Secretary: 'Secretário(a)',
        Coordinator: 'Coordenador(a)',
        Intern: 'Estagiário(a)',
        Physiotherapist: 'Fisioterapeuta',
        MassageTherapist: 'Marketing',
        Other: 'Outro(a)',
        Adm: 'Adm',
        Atleta: 'Atleta',
        'Diretor(a)': 'Diretor(a)',
        'Fotógrafo(a)': 'Fotógrafo(a)',
        Visitante: 'Visitante',
        'Gestor(a)': 'Gestor(a)',
        'Professor(a)': 'Professor(a)',
        'Secretário(a)': 'Secretário(a)',
        'Coordenador(a)': 'Coordenador(a)',
        'Estagiário(a)': 'Estagiário(a)',
        'Outro(a)(s)': 'Outro(a)(s)',
        Fisioterapeuta: 'Fisioterapeuta',
        Massagista: 'Marketing',
        Marketing: 'Marketing',
      },
    },
    StudentModel: {
      Type: {
        Social: 'Social',
        Regular: 'Regular',
        Selective: 'Seletiva',
        Housed: 'Alojado',
        Seletiva: 'Seletiva',
        Alojado: 'Alojado',
      },
    },
    RequestModel: {
      PaymentMethod: {
        Cartão: 'Cartão',
        Boleto: 'Boleto',
        Dinheiro: 'Dinheiro',
      },
      PaymentStatus: {
        Aprovado: 'Aprovado',
        Pendente: 'Pendente',
        Vencido: 'Vencido',
        Cancelado: 'Cancelado',
      },
      Status: {
        'Ag. Confirmação': 'Ag. confirmação',
        'Em Separação': 'Em separação',
        Entregue: 'Entregue',
        Cancelado: 'Cancelado',
      },
    },
    RegisteredServicesModel: {
      'aulas de futebol': 'Aulas de futebol',
      'treinamento de alto rendimento': 'Treinamento de alto rendimento',
      'personal soccer': 'Personal soccer',
      'treinamento virtual de futebol': 'Treinamento virtual de futebol',
      outros: 'Outros',
      'aulas de natação': 'Aulas de natação',
    },
    EvaluationModel: {
      Características: 'Características',
      Tática: 'Tática',
      Fisicas: 'Físicas',
      Físicas: 'Físicas',
      AttributeName: {
        Agilidade: 'Agilidade',
        'Contato Corporal': 'Contato Corporal',
        Coordenação: 'Coordenação',
        'Corrida 5 mts': 'Corrida 5 mts',
        'Corrida 10 mts': 'Corrida 10 mts',
        'Corrida 20 mts': 'Corrida 20 mts',
        'Resistência Velocidade': 'Resistência Velocidade',
        'Velocidade Recuperação': 'Velocidade Recuperação',
        '1 x 1 defensivo': '1 x 1 defensivo',
        'Dinâmica de jogo': 'Dinâmica de jogo',
        'Jogadas combinadas': 'Jogadas combinadas',
        'Leitura de jogo': 'Leitura de jogo',
        'Movimento sem bola': 'Movimento sem bola',
        Posicionamento: 'Posicionamento',
        'Senso de cobertura': 'Senso de cobertura',
        Transição: 'Transição',
      },
    },
    ContractModel: {
      DescriptionCardMonthly: 'Contrato com cobrança mensal por cartão de crédito',
      DescriptionCardQuarterly: 'Contrato com cobrança trimestral por cartão de crédito',
      DescriptionCardSemiannually: 'Contrato com cobrança semestral por cartão de crédito',
      DescriptionCardYearly: 'Contrato com cobrança anual por cartão de crédito',
      DescriptionBillMonthly: 'Contrato com cobrança mensal por boleto',
      DescriptionBillQuarterly: 'Contrato com cobrança trimestral por boleto',
      DescriptionBillSemiannually: 'Contrato com cobrança semestral por boleto',
      DescriptionBillYearly: 'Contrato com cobrança anual por boleto',
      DescriptionMoneyMonthly: 'Contrato com cobrança mensal em dinheiro',
      DescriptionMoneyQuarterly: 'Contrato com cobrança trimestral em dinheiro',
      DescriptionMoneySemiannually: 'Contrato com cobrança semestral em dinheiro',
      DescriptionMoneyYearly: 'Contrato com cobrança anual em dinheiro',
    },
    TableBorderedComponent: {
      emptyTableMessage: 'Até o momento, nenhuma informação foi adicionada',
    },
    SupplierPage: {
      PageTitle: 'Fornecedores',
      PageSubtitle: 'Veja abaixo o seus fornecedores.',
      AddSupplierCard: {
        Title: 'Adicionar fornecedor',
        Subtitle: 'Clique abaixo para adicionar um fornecedor.',
        Button: 'Adicionar fornecedor',
      },
      FilterSupplierCard: {
        Title: 'Procurar',
        Subtitle: 'Filtrar os tipos de fornecedores.',
      },
      Modal: {
        TitleAdd: 'Incluir fornecedor',
        TitleEdit: 'Alterar fornecedor',
        ButtonAdd: 'Incluir fornecedor',
        ButtonEdit: 'Alterar fornecedor',
        ViewSupplier: 'Informações do fornecedor',
      },
    },
    Email: 'Email',
    User: 'Usuário',
    Password: 'Senha',
    Attention_exclamation: 'Atenção!',
    Authenticated_exclamation: 'Autenticado!',
    Success_exclamation: 'Sucesso!',
    Documents: 'Documentos',
    Settings: 'Configurações',
    ChangePassword: 'Alterar Senha',
    Disconnect: 'Desconectar',
    Language: 'Idioma',
    YourCart: 'Seu Carrinho',
    Birthday: 'Aniversário',
    Birthday_one: 'Aniversariante',
    Birthday_other: 'Aniversariantes',
    Student: 'Aluno',
    Students: 'Alunos',
    Student_one: 'Aluno',
    StudentCount_one: '{{count}} aluno',
    StudentCount_other: '{{count}} alunos',
    Employee_one: 'Funcionário',
    Employee_male_one: 'Funcionário',
    Employee_female_one: 'Funcionária',
    Birth: 'Nascimento',
    BirthDate: 'Data de Nascimento',
    ShowMore: 'Ver Mais',
    Name: 'Nome',
    Status: 'Status',
    Function: 'Função',
    Occupation: 'Função',
    Position: 'Posição',
    SeeStudent: 'Ver aluno',
    EditStudent: 'Editar aluno',
    SeeAthlete: 'Ver atleta',
    EditAthlete: 'Editar atleta',
    SeeEmployee: 'Ver funcionário',
    EditEmployee: 'Editar funcionário',
    SeeSupplier: 'Ver fornecedor',
    EditSupplier: 'Editar fornecedor',
    SeeAll: 'Ver tudo',
    SeeAll_male: 'Ver todos',
    SeeAll_female: 'Ver todas',
    All: 'Todos',
    All_male: 'Todos',
    All_female: 'Todas',

    Search: 'Buscar',
    CPF: 'CPF',
    CellPhone: 'Celular',
    CEP: 'CEP',
    Address: 'Endereço',
    State: 'Estado',
    Location: 'Localização',
    City: 'Cidade',
    Neighborhood: 'Bairro',
    StartDate: 'Data de início',
    EndDate: 'Data final',
    Description: 'Descrição',
    ChangePhoto: 'Alterar foto',
    DeletePhoto: 'Excluir foto',
    Select: 'Selecione',
    BiologicalSex: 'Sexo biológico',
    Feminine: 'Feminino',
    Masculine: 'Masculino',
    StudentCod: 'Cod. Aluno',
    StudentCode: 'Código do Aluno',
    YearOfBirth: 'Ano de nascimento',
    SportType: 'Tipo de Esporte',
    StudentType: 'Tipo do Aluno',
    Contract: 'Contrato',
    Contracts: 'Contratos',
    RegisteredStudents: 'Alunos cadastrados',
    RegisteredStudentsThisSchool: 'Alunos cadastrados nesta escola',
    ActiveStudents: 'Alunos ativos',
    ActiveStudentsThisSchool: 'Alunos ativos nesta escola',
    InactiveStudents: 'Alunos inativos',
    InactiveStudentsThisSchool: 'Alunos inativos nesta escola',
    ContractsThisSchool: 'Contratos desta escola',
    School: 'Escola',
    Uninformed: 'Não informado',
    AttachDocs: 'Anexar docs',
    Type: 'Tipo',
    Types: 'Tipos',
    LoadFile: 'Carregar arquivo',
    AttachedDocuments: 'Documentos anexados',
    NoDocumentsAttached: 'Nenhum documento anexado',
    View: 'Visualizar',
    Delete: 'Excluir',
    RG: 'RG',
    HowKnowSchool: 'Como conheceu a escola?',
    Benefit: 'Benefício',
    Recommendation: 'Indicação',
    Indicação: 'Indicação',
    DisabledOption: 'Opção inativada',
    MotherName: 'Nome da mãe',
    MotherCPF: 'CPF da mãe',
    MotherCellPhone: 'Celular da mãe',
    FatherName: 'Nome do pai',
    FatherCPF: 'CPF do pai',
    FatherCellPhone: 'Celular do pai',
    FinancialResponsible: 'Responsável financeiro',
    FinancialResponsibleName: 'Nome Responsável Financeiro',
    FinancialResponsibleEmail: 'Email do responsável',
    FinancialResponsibleRG: 'RG Responsável Financeiro',
    FinancialResponsibleCPF: 'CPF Responsável Financeiro',
    FinancialResponsibleCellPhone: 'Cel. Responsável Financeiro',
    Indication: 'Indicação',
    Grandparent: 'Avô(ó)',
    Mother: 'Mãe',
    Father: 'Pai',
    UncleAunt: 'Tio(a)',
    Cousin: 'Primo(a)',
    Sibling: 'Irmão(ã)',
    None: 'Nenhum',
    'Avô(ó)': 'Avô(ó)',
    Pai: 'Mãe',
    Mãe: 'Pai',
    'Tio(a)': 'Tio(a)',
    'Primo(a)': 'Primo(a)',
    'Irmão(ã)': 'Irmão(ã)',
    Nenhum: 'Nenhum',
    Kinship: 'Parentesco',
    StudentName: 'Nome do aluno',
    InstallmentAmount: 'Valor da parcela',
    InstallmentDue: 'Vencimento da parcela',
    Responsible: 'Responsável',
    Responsible_abbreviated: 'Resp.',
    ResponsiblePhoneNumber: 'Celular do responsável',
    ContractStart: 'Início do contrato',
    ContractEnd: 'Fim de contrato',
    BillingCycle: 'Ciclo de cobrança',
    RemoveSession: 'Remover sessão',
    Back: 'Voltar',
    SendMessage: 'Enviar mensagem',
    Connected: 'Conectado',
    Disconnected: 'Desconectado',
    SendLink: 'Enviar link',
    RequiredField: 'O campo "{{fieldName}}" é obrigatório!',
    Laterality: 'Lateralidade',
    Ambidextrous: 'Ambidestro',
    LeftHanded: 'Canhoto',
    RightHanded: 'Destro',
    Day_one: '{{count}} dia',
    Day_other: '{{count}} dias',
    TimeAtSchool: 'Tempo na escola',
    ParticipationInSelectives: 'Participação em seletivas',
    ApprovalInSelective: 'Aprovação em seletivas',
    NumberOfClasses: 'Número de aulas',
    NumberOfAbsences: 'Número de faltas',
    Absences: 'Faltas',
    Absences_alt: 'Falta(s)',
    StudentInformation: 'Informações do aluno',
    BestFoot: 'Melhor pé',
    Right: 'Direito',
    Left: 'Esquerdo',
    About: 'Sobre',
    Financial: 'Financeiro',
    SeeStudentInstallmentsBelow: 'Veja abaixo as parcelas do aluno.',
    Everything: 'Tudo',
    Overdue: 'Vencido',
    Overdues: 'Vencidos',
    Overdue_male_one: 'Vencido',
    Overdue_female_one: 'Vencida',
    Overdue_male_other: 'Vencidos',
    Overdue_female_other: 'Vencidas',
    ToExpire: 'A receber',
    Paid: 'Pago',
    Paid_male_one: 'Pago',
    Paid_female_one: 'Paga',
    Paid_male_other: 'Pagos',
    Paid_female_other: 'Pagas',

    Customer: 'Cliente',
    DueDate: 'Data de vencimento',
    DueDate_abbreviated: 'Vcto',
    Installment: 'Parcela',
    Installment_abbreviated: 'Parc.',
    Installment_one: 'Parcela',
    Installment_other: 'Parcelas',
    Value: 'Valor',
    Received: 'Recebido',
    Received_abbreviated: 'Receb.',
    Action: 'Ação',
    Receipt: 'Recibo',
    NoOpenInstallment: 'Nenhuma parcela em aberto até o momento.',
    Yes: 'Sim',
    Not: 'Não',
    S: 'S',
    N: 'N',
    Change: 'Alterar',
    Selective: 'Seletiva',
    Selective_one: 'Seletiva',
    Selective_other: 'Seletivas',
    StudentSelectiveSubtitle: 'Veja abaixo as seletivas que seu aluno participou.',
    Club: 'Clube',
    Place: 'Local',
    Date: 'Data',
    StudentAttendance: 'Frequência do aluno',
    StudentAttendanceSubtitle: 'Veja abaixo a frequência do aluno na turma.',
    Class: 'Aula',
    Class_one: 'Aula',
    Class_other: 'Aulas',
    Classes: 'Turma',
    Classes_one: 'Turma',
    My_classes: 'Minhas turmas',
    Classes_other: 'Turmas',
    Day: 'Dia',
    Days: 'Dias',
    Schedule: 'Horário',
    See: 'Ver',
    ClassName: 'Nome da turma',
    MaxStudentsInClass: 'Máximo de alunos na turma',
    Weekday: 'Dia da semana',
    IncludeStudent: 'Incluir aluno',
    IncludeEmployee: 'Incluir funcionário',
    NumClassPerWeek: 'Quantidade de aulas na semana',
    CardForgettingFee: 'Taxa de esquecimento de carteirinha',
    CardFee: 'Taxa da Carteirinha',
    ChooseADay: 'Escolha um dia',
    Information: 'Informação',
    Information_abbreviation: 'Info.',
    Information_abbreviation_exclamation: 'Info.!',
    Confirm: 'Confirmar',
    Localization: 'Localização',
    CNPJ: 'CNPJ',
    StudentsEvaluated: 'Alunos avaliados',
    Average: 'Média',
    Sum: 'Soma',
    Actions: 'Ações',
    Assessment: 'Avaliação',
    Characteristics: 'Características',
    Observation: 'Observação',
    Observations: 'Observações',
    NoTeacherForThisName: 'Nenhum professor com este nome',
    Revenue: 'Receita',
    Revenues: 'Receitas',
    Expense: 'Despesa',
    Expenses: 'Despesas',
    Employee: 'Funcionário',
    Employees: 'Funcionários',
    CreatedAt: 'Criado em',
    CreatedBy: 'Criado por',
    UpdatedAt: 'Atualizado em',
    UpdatedBy: 'Atualizado por',
    Profile: 'Perfil',
    Role: 'Função',
    Service: 'Serviço',
    Billing: 'Faturando',
    Payment: 'Pagamento',
    Payments: 'Pagamentos',
    PaymentId: 'Pagamento ID',
    ContractNumber: 'Número do contrato',
    Provider: 'Fornecedor',
    Account: 'Conta',
    Mode: 'Modo',
    AmountReceived: 'Valor recebido',
    DowntownUser: 'Usuário da baixa',
    News: 'Notícia',
    News_one: 'Notícia',
    News_other: 'Notícias',
    Communication: 'Comunicado',
    Communications: 'Comunicados',
    Close: 'Fechar',
    Notícia: 'Notícia',
    Comunicado: 'Comunicado',
    Title: 'Título',
    Subtitle: 'Subtítulo',
    AsFrom: 'A partir de',
    SendTo: 'Enviar para',
    Athlete: 'Atleta',
    Athletes: 'Atletas',
    Attachment: 'Anexo',
    Attachments: 'Anexos',
    AttachFile: 'Anexar arquivo',
    CurrentPassword: 'Senha atual',
    NewPassword: 'Nova senha',
    ConfirmNewPassword: 'Confirme a nova senha',
    Module: 'Módulo',
    MarkAll: 'Marcar todos',
    MarkAll_question: 'Marcar todos?',
    AllowAccess: 'Permitir acesso',
    Save: 'Salvar',
    Product: 'Produto',
    Products: 'Produtos',
    ProductCode: 'Código do produto',
    ServiceCode: 'Código de serviço',
    ProductCode_abbreviated: 'Cód. do produto',
    RegisteredProducts: 'Produtos cadastrados',
    TotalStockValue: 'Valor total em estoque',
    ProductsSold: 'Produtos vendidos',
    Price: 'Preço',
    Photo: 'Foto',
    Photos: 'Fotos',
    EditProduct: 'Editar produto',
    AttachPhotos: 'Anexar fotos',
    LoadImage: 'Carregar imagem',
    AttachedPhotos: 'Fotos Anexadas',
    NoPhotoAttached: 'Nenhuma foto anexada',
    Cover: 'Capa',
    BarCode: 'Código de Barras',
    Size: 'Tamanho',
    Sizes: 'Tamanhos',
    Size_one: 'Tamanho',
    Size_other: 'Tamanhos',
    Letter: 'Letra',
    Number: 'Número',
    Single: 'Único',
    PlaceholderMoneyZero: 'R$ 0,00',
    RequestNumber: 'Número do pedido',
    ProductName: 'Nome do produto',
    RequestDate: 'Data do pedido',
    Approved: 'Aprovado',
    Pending: 'Pendente',
    AwaitingConfirmation: 'Aguardando confirmação',
    AwaitingConfirmation_abbreviated: 'Ag. confirmação',
    InSeparation: 'Em separação',
    Delivered: 'Entregue',
    EnterStudentName: 'Digite o nome do aluno',
    EnterRequestNumber: 'Digite o número do pedido',
    EnterProductName: 'Digite o nome do produto',
    TotalOrders: 'Total de pedidos',
    TotalApproved: 'Total aprovados',
    TotalPending: 'Total pendentes',
    RequestNumberValue: 'Pedido {{number}}',
    SeeDetails: 'Ver detalhes',
    SendMail: 'Enviar e-mail',
    ChangeToDelivered: 'Alterar para entregue',
    Quantity: 'Quantidade',
    PaymentMethod: 'Forma de pagamento',
    PaymentMethods: 'Formas de pagamento',
    TotalOrder: 'Total do pedido',
    MyAttendance: 'Minha frequência',
    PurchaseDate: 'Data da compra',
    TotalPurchases: 'Total de compras',
    ProductsInCart: 'Produtos no carrinho',
    InitialPrice: 'Preço Inicial',
    FinalPrice: 'Preço final',
    Example123456: 'Exemplo: 123456',
    AvailableProducts: 'Produtos disponíveis',
    SeeProduct: 'Ver produto',
    Add: 'Adicionar',
    Choose: 'Escolher',
    UnitCount_one: '{{value}} unidade',
    UnitCount_other: '{{value}} unidades',
    AddToCart: 'Adicionar ao carrinho',
    EnterTheSize: 'Informe o tamanho!',
    DocumentType: 'Tipo de documento',
    IsSure: 'Tem certeza?',
    Cancel: 'Cancelar',
    Card: 'Cartão',
    Bill: 'Boleto',
    Money: 'Dinheiro',
    InitialOverdue: 'Vencimento Inicial',
    EndOverdue: 'Vencimento Final',
    InitialPaymentDate: 'Data de Pagamento Inicial',
    EndPaymentDate: 'Data de Pagamento Final',
    TotalPayment: 'Pagamento Total',
    Subtotal: 'Subtotal',
    OrderSummary: 'Resumo do pedido',
    Total: 'Total',
    Checkout: 'Finalizar compra',
    KeepBuying: 'Continuar comprando',
    ContractedService: 'Serviço contratado',
    ActiveContracts: 'Contratos Ativos',
    InactiveContracts: 'Contratos Inativos',
    SoccerLessons: 'Aulas de futebol',
    HighPerformanceTraining: 'Treinamento de alto rendimento',
    Other: 'Outro',
    Others: 'Outros',
    PersonalSoccer: 'Personal soccer',
    SwimmingClasses: 'Aulas de natação',
    VirtualSoccerTraining: 'Treinamento virtual de futebol',
    ContractNumberValue: 'Contrato {{number}}',
    EditContract: 'Editar contrato',
    PDFContract: 'PDF contrato',
    SendWhatsApp: 'Enviar WhatsApp',
    ModelCard: 'Carteirinha',
    SendContract: 'Enviar contrato',
    SendModelCard: 'Enviar carteirinha',
    President: 'Presidente',
    PDFModelCard: 'PDF carteirinha',
    InactivatedClass: 'turma inativada',
    NoStudentName: 'Nenhum aluno com este nome',
    AddDeleteServices: 'Adicionar ou excluir serviços',
    Monthly: 'Mensal',
    Quarterly: 'Trimestral',
    Semiannually: 'Semestral',
    Yearly: 'Anual',
    TakeInsurance: 'Contratar seguro DMHO',
    InsuranceTypeFormatted: '{{value, currency(currency: BRL)}}/mês (Cobertura {{coverage, currency(currency: BRL)}})',
    NoInsurance: 'Sem seguro',
    DiscountType: 'Tipo de desconto',
    LateInterestType: 'Tipo de juros',
    LateFeeType: 'Tipo de multa',
    NoDiscount: 'Sem desconto',
    NoLateInterest: 'Sem juros',
    NoLateFee: 'Sem multa',
    Percentage: 'Percentual',
    Fixed: 'Fixo',
    DiscountAmount: 'Valor de desconto',
    PlaceholderPercentageZero: '0,00%',
    MonthlyAmountWithoutDiscount: 'Valor mensal sem desconto',
    AmountInsurance: 'Valor total + seguro',
    LateInterestAmount: 'Valor do juros',
    LateFeeAmount: 'Valor da multa',
    ContractStatus: 'Status do contrato',
    MonthlyWorkload: 'Carga Horária Mensal',
    Workload: 'Carga Horária',
    FirstDueDate: 'Data do primeiro vencimento',
    ContractStartDate: 'Data de início do contrato',
    ContractEndDate: 'Data de fim do contrato',
    InvoiceContract: 'Faturar contrato',
    CancelInvoicing: 'Cancelar faturamento',
    AddContractedServices: 'Adicionar serviços contratados',
    ServiceName: 'Nome do serviço',
    Include: 'Incluir',
    NoRegisteredServices: 'Nenhum serviço cadastrado',
    ListRegisteredServices: 'Lista dos serviços cadastrados',
    CustomerOrSupplier: 'Cliente ou Fornecedor',
    NoCustomerOrSupplier: 'Nenhum cliente ou fornecedor com este nome',
    EnterCustomerSupplierName: 'Digite o nome do cliente ou fornecedor',
    EnterTheName: 'Digite o nome',
    BillsToReceive: 'Contas a receber',
    BillsToPay: 'Contas a pagar',
    PeriodBalance: 'Saldo no período',
    SearchCustomer: 'Pesquisar cliente',
    SearchSupplier: 'Pesquisar fornecedor',
    NoRevenueInPeriod: 'Nenhuma receita criada até o momento neste período.',
    NoExpenseInPeriod: 'Nenhuma despesa criada até o momento neste período.',
    CreateOrUpdateCustomer: 'Criar ou atualizar cliente',
    PostingAccount: 'Conta do lançamento',
    IntegratedOnly: 'Somente integrado',
    Integrated: 'Integrado',
    InCash: 'À vista',
    PaymentInstallments: 'Parcelado',
    ContractSigning: 'Assinatura do Contrato',
    ExpirationDate: 'Data do vencimento',
    InstallmentValueWithoutDiscount: 'Valor parcela sem desconto',
    NumberOfInstallments: 'Número de parcelas',
    PayDay: 'Data do pagamento',
    PayDay_abbreviated: 'Data pag.',
    NoDocument: 'Sem documento',
    ViewAttachedDocument: 'Exibir documento anexado',
    CustomerAlreadyExists: 'Este cliente já existe. Faça a pesquisa novamente para conferir os dados.',
    SupplierAlreadyExists: 'Este fornecedor já existe. Faça a pesquisa novamente para conferir os dados.',
    Research: 'Pesquisar',
    CPFOrCNPJ: 'CPF ou CNPJ',
    Alert: 'Alerta',
    ManagedClasses: 'Turmas administradas',
    EmployeeInformation: 'Informações do funcionário',
    EmployeeClassesSubtitle: 'Veja abaixo as turmas cadastrados para esse funcionario.',
    SeeClasses: 'Ver turmas',
    ExpirationDateMedicalExamination: 'Data de vencimento do exame médico',
    StudentDontHaveClassesRegistered: 'Este aluno não está cadastrado em nenhuma turma',
    StudentBirthYear: 'Ano de nascimento do aluno',
    Link: 'Link',
    TrainingTime: 'Horário de treino',
    Morning: 'Manhã',
    Evening: 'Tarde',
    Night: 'Noite',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    Internet: 'Internet',
    Signed: 'Assinado',
    ResetPassword: 'Redefinir senha',
    Active: 'Ativar',
    Inactive: 'Inativar',
    ContractValue: 'Valor do contrato',
    RemoveAll: 'Remover tudo',
    RemoveFromClass: 'Remover da turma',
    CloseAll: 'Encerrar tudo',
    TerminateContractCancelBillings: 'Encerrar contrato e cancelar faturamentos',
    AwaitingPayment: 'Aguardando pagamento',
    OverdueBilling: 'Faturamento vencido',
    Finalize: 'Finalizar',
    Present: 'Presente',
    Absent: 'Ausente',
    Recipient_alt: 'Destinatário(s)',
    ContractedInsurance: 'Seguro contratado',
    AmountPaid: 'Valor pago',
    MakeMandatory: 'Tornar obrigatório',
    Mandatory: 'Obrigatório',
    Mandatory_question: 'Obrigatório?',
    MissingDocuments: 'Documentos ausentes',
    Weight: 'Peso',
    Height: 'Altura',
    Age: 'Idade',
    AnamnesisForm: 'Formulário de Anamnese',
    AnamnesisForm_abbreviated: 'Form. anamnese',
    Remove: 'Remover',
    AddLine: 'Adicionar linha',
    Print: 'Imprimir',
    BalanceMonthStart: 'Saldo no início do mês',
    Balance: 'Saldo',
    Export: 'Exportar',
    Complement: 'Complemento',
    'E-commerce': 'E-commerce',
    Document: 'Documento',
    DeleteDocument: 'Excluir documento',
    PermanentAction: 'Essa ação é permanente',
    PermanentAction_dot: 'Essa ação é permanente.',
    FileIsRequired: 'É obrigatório escolher um arquivo.',
    ContractAlreadyInvoiced: 'Este contrato já está faturado.',
    SkillLevel: 'Nível habilidade',
    Sublevel: 'Subnível',
    SchoolYear: 'Ano escolar',
    Beginner: 'Iniciante',
    Intermediary: 'Intermediário',
    Advanced: 'Avançado',
    ComplimentaryProduct: 'Produto de cortesia',
    Discontinued: 'Descontinuado',
    IMC: 'IMC',
    BodyFatPercentage: 'Porcentagem de gordura corporal',
    IMCPlaceholder: 'Preencha os campos "Peso" e "Altura"',
    AgeNotTabulated: 'Idade não tabelada',
    VideoURL: 'URL do vídeo',
    Lesson: 'Aula',
    Lessons: 'Aulas',
    Summary: 'Resumo',
    Forward: 'Avançar',
    Planning: 'Planejamento',
    FilterByDate: 'Filtrar por data',
    CTLocation: 'Localização do CT',
    AddDeleteCTs: "Adicionar ou excluir CT's",
    TrainingCenter: 'Centro de treinamento',
    NoRegisteredTrainingCenter: 'Nenhum centro de treinamento cadastrado',
    ListRegisteredTrainingCenters: 'Lista dos centros de treinamento cadastrados',
    EditTrainingCenter: 'Editar centro de treinamento',
    Clear: 'Limpar',
    BackgroundImage: 'Imagem de fundo',
    NotRequired: 'não obrigatório',
    MaxSize: 'Tamanho máximo',
    Certificates: 'Certificados',
    Certificate: 'Certificado',
    Level: 'Nível',
    Complete: 'Completo',
    SearchByStudentName: 'Buscar por nome do aluno',
    FrequencyInClasses: 'Frequência nas aulas',
    FrequencyInClasses_abbreviated: 'Freq. nas aulas',
    UnjustifiedAbsences: 'Faltas não justificadas',
    UnjustifiedAbsences_abbreviated: 'Faltas não justif.',
    JustifiedAbsences: 'Faltas justificadas',
    JustifiedAbsences_abbreviated: 'Faltas justif.',
    CallTheRoll: 'Realizar chamada',
    SelectDate: 'Selecionar data',
    FetchStudents: 'Buscar alunos',
    JustifyAbsence: 'Justificar falta',
    AddJustify: 'Adicionar justificativa',
    ViewJustifiedAbsences: 'Faltas justificadas',
    AbsenceReason: 'Motivo da falta',
    MaxCharacters: 'Máximo de {{value}} caracteres',
    Remuneration: 'Remuneração',
    Canceled: 'Cancelado',
    ReleaseCertificate: 'Liberar certificado?',
    SecondCopy_abbreviated: '2° via',
    CancelOrder: 'Cancelar pedido',
    PayNow: 'Pagar agora',
    Choose_a_category: 'Escolha uma categória',
    Choose_a_type: 'Escolha um tipo de serviço',
    Create_Purchase_Order: 'Criar ordem de compra',
    AnnualReview: 'Analise Anual',
    Registration: 'Matriculas',
    Dropouts: 'Desistentes',
    Defaulters: 'Inadimplentes',
    PaidOut: 'Pagos',
    TotalOfStudents: 'T. de Alunos',
    SignatureCreditPage: {
      Balance: 'Saldo',
      BalanceDescription: 'A quantidade de documentos que você ainda possuí disponível para assinar digitalmente.',
      BuyCredits: 'Comprar créditos créditos de assinatura',
      FilterTitle: 'Extrato de documentos comprados/assinados',
      AdvancedFilters: 'Filtros avançados',
      StartPeriod: 'Período inicial:',
      EndPeriod: 'Período final:',
      Status: 'Status',
      Date: 'Data',
      Quantity: 'Qtde.',
      Value: 'Valor',
      Description: 'Descrição',
      Link: 'Link',
      NoOption: 'Nenhuma das opções',
      PendingPayment: 'Pagamento pendente',
      DonePayment: 'Pagamento concluído',
      Spent: 'Crédito gasto',
    },
    BuySignatureCreditPage: {
      ChoosePlanDescription: 'Escolha um plano para prosseguir:',
      Back: 'Voltar',
    },
    PlanCard: {
      Plano: 'Plano',
    },
    SignatureContractModal: {
      Title: 'Assinar contrato',
      AddSigner: 'Adicionar signatário',
      RequestSigns: 'Solicitar assinaturas',
      Name: 'Nome completo',
      DeliveryMethod: 'Método de envio',
      Phone: 'Telefone',
      SignAction: 'Ação de assinatura',
      Email: 'Email',
      Signed: 'Assinado',
      Unsigned: 'Não Assinado',
      NameValidationError: 'O campo deve ter o nome completo'
    },
  },
};

export default translation;
