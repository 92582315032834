const translation: Translation = {
  translation: {
    FormatFunction: {
      intlCurrencyWithOptions: '{{value, currency(currency: USD)}}',
      ParcelsTextFormatted: 'up to {{quantity}} x {{value, currency(currency: USD)}}',
      intlPercentWithOptions: '{{value, number(minimumFractionDigits: 2)}}%',
      formatListConjunction: '{{value, list(type: conjunction)}}',
      formatListDisjunction: '{{value, list(type: disjunction)}}',
    },
    Mask: {
      RG: '',
      CPF: '',
      CNPJ: '',
      ZipCode: '#####-####',
      CellPhone: '',
      CellPhoneDDI: '',
      ThousandSeparator: ',',
      DecimalSeparator: '.',
    },
    SystemMenu: {
      Home: 'Home',
      'Controle de Acesso': 'Access Control',
      Ecommerce: 'Ecommerce',
      Cadastros: 'Registers',
      Aluno: 'Student',
      Estoque: 'Stock',
      Comunicação: 'Communication',
      Funcionário: 'Employee',
      Turma: 'Class',
      Contrato: 'Contract',
      Comunicado: 'Communication',
      Perfil: 'Profile',
      EAD: 'EAD',
      Configurações: 'Settings',
      'Controle de Estoque': 'Inventory Control',
      'Ordem de Compra': 'Purchase Order',
      Inventário: 'Inventory',
      'Extrado de Vendas': 'Sales Statement',
      Funcionários: 'Employee',
      Alunos: 'Students',
      Turmas: 'Classes',
      Avaliação: 'Assessment',
      Contratos: 'Contracts',
      Pedidos: 'Requests',
      Produtos: 'Products',
      Financeiro: 'Financial',
      Fornecedor: 'Supplier',
      'Assinaturas digitais': 'Digital Signatures',
      'Pedidos Vendas': 'Sales Orders',
      'Produtos Clube': 'Club Products',
      Relatórios: 'Reports',
      Cursos: 'Courses',
      Exercícios: 'Exercise',
      Frequência: 'Attendance',
      Escolas: 'Schools',
      'Meus Pedidos': 'My Requests',
      Questionário: 'Questionnaire',
      Documentos: 'Documents',
      'Upload assinatura': 'Upload Signature',
      'Doc. Alunos': 'Doc. Students',
      'Token API': 'Token API',
      'Aluguel de Quadra': '',
      'Doc. Escolas': 'Doc. Schools',
      'Planej. Aulas': 'Lesson Plan',
      Aulas: 'Classes',
      Certificados: 'Certificates',
      OrdemDeCompra: 'Purchase Order',
      Invetory: 'Inventory',
      StockAdjustment: 'Stock adjustment',
      ControleDeEstoque: 'Inventory Control',
      SportsTypes: 'Sports Types',
      Produto: 'Product',
      'Documentos Importantes': 'Important Documents',
      'Cobrança Avulsa': 'Individual Billing',
      'Planejamento de Aula': 'Lesson Planning',
      Exercício: 'Exercise',
      'Documentos Obrigatórios': 'Mandatory Documents',
      'Personalizar Contrato': 'Customize Contract',
      'Upload da Assinatura': 'Upload Signature',
      'Fluxo de Caixa': 'Cash Flow',
      'Financeiro por turma': 'Financial by class',
      'Tipos de Esportes': 'Sports Types',
      'Alterar Senha': 'Change Password',
      Metodologia: 'Methodology',
      'Notas fiscais': 'Invoice',
      'Politica de Preços': 'Price Policy',
    },
    SystemFooter: {
      HelpCenter: 'Help Center',
      UseTerms: 'Terms of use',
      PrivacyPolicy: 'Privacy Policy',
      Copyright: 'Fensor group. All Rights reserved.',
    },
    SystemMessage: {
      Loading: {
        WaitValidating: 'Wait, we are validating...',
        WaitSending: 'Wait, we are sending...',
        Processing: 'Processing...',
        Wait: 'Wait...',
        WaitDeleting: 'Wait, we are deleting...',
        WaitProcessing: 'Wait, we are processing...',
        Loading: 'Loading...',
      },
      Success: {
        Redirect: 'We are redirecting...',
        UserCreated: 'User saved.',
        UserUpdated: 'User updated.',
        DocumentSaved: 'Document saved.',
        DocumentDeleted: 'Document deleted.',
        StudentCreated: 'Student created.',
        StudentUpdated: 'Student updated.',
        ClassCreated: 'Class created successfully.',
        ClassUpdated: 'Class updated successfully.',
        EvaluationSaved: 'Evaluation saved successfully.',
        EvaluationDeleted: 'Evaluation deleted successfully.',
        SavedSuccessfully: 'Saved successfully!',
        CoverUpdated: 'Cover updated successfully!',
        ProductCreatedSuccess: 'Product created successfully.',
        ProductUpdatedSuccess: 'Product updated successfully.',
        PurchaseOrderCreatedSuccess: 'Purchase Order created successfully.',
        PurchaseOrderUpdatedSuccess: 'Purchase Order updated successfully.',
        CategoryProductCreated: 'Category created.',
        TypeServiceProductCreated: 'Type service created.',
        ScheduledBillingSuccessfully:
          'Billing scheduled successfully. The customer will be billed at every expiration date.',
        AccountAndBillingDeleted: 'Account and billing deleted.',
        CreateSuccess: 'Created successfully.',
        UpdateSuccess: 'Updated successfully.',
        JustificationSaved: 'Justification saved successfully.',
        JustificationSavedWarning:
          'The justification was saved, but some of the attachments could not be removed. Please try again.',
        CancellationRequested: 'The order has been cancelled.',
        StockAdjustmentUpdate: 'Inventory has been updated successfully',
      },
      Fail: {
        RequeridFieldsWith: 'Required fields with: {{fields}}',
        UniqueFields: 'The following fields must be unique: {{fields}}',
        RequeridFields: 'Required fields',
        InternalError: 'An internal error has occurred. Please, reload the page and try again.',
        InternalErrorTryAgain: 'An internal error has occurred. Please try again.',
        EmailAlreadyExists: 'This email is already registered on the platform.',
        LoginAlreadyExists: 'This user login is already registered, try another user login.',
        LoginEmpty: 'The login field is required.',
        CPFAlreadyExists: 'This ID is already registered on the platform.',
        ErrorRetry: 'There was an error. Check the data and try again, please.',
        InvalidCNPJ: 'The ID entered is invalid.',
        InvalidCPF: 'The ID informed is invalid.',
        InvalidEmail: 'Email informed is invalid.',
        InvalidBirthDate: 'The date of birth informed is invalid.',
        CPFOrCNPJRegisteredRequired: 'It is mandatory that the customer or supplier have a CPF or CNPJ registered.',
        DueDateInvalid: 'The due date for recipes cannot be less than today.',
        PaymentNotApproved: 'Payment not approved, please try again.',
        CommunicationProblemServer: 'We have a problem communicating with the server, please try again later.',
        SchoolMustBeParameterized: 'This school must be parameterized by the Fensor team. Contact.',
        BillNotDeleted: 'Account not deleted, please try again.',
        InternalErrorMissingData: 'Internal error. Missing data.',
        InvalidBody: 'The request has invalid fields.',
        ForbiddenResource: 'Unfortunately you do not have permission to access this feature.',
        FinanceNotFound: 'Billing not found.',
        FinanceNotPending: 'It is not possible to receive cash as this charge is not pending',
        LongAdress: 'The address field cannot exceed 50 characters.',
        ContratEmpty: 'The contract cannot be empty.',
        CPFAndEmailAlreadyExists: 'This email and ID are already registered on the platform.',
      },
      File: {
        Success: {
          UploadSuccess: 'Upload success.',
          PhotoUpdateSuccess: 'Photo updated.',
          PhotoSendSuccess: 'Photo sent successfully.',
          PhotoDeletedSuccess: 'Photo deleted successfully.',
          DocumentAttachedSuccess: 'Document attached successfully.',
          FileDeleted: 'File deleted.',
          DocumentSaved: 'Document saved.',
        },
        Fail: {
          InvalidFile: 'Upload failed. Contact the administrator.',
          InvalidFileMaximumSizeExceeded: 'Upload failed. File larger than 10MB. Contact the administrator.',
          MaximumSizeExceeded: 'The file exceeds the size limit.',
          PartialUpload: 'The file was partially uploaded.',
          FileNotUploaded: 'The file has not been uploaded.',
          DirectoryNotExists: 'Directory does not exist.',
          FailedWriteOnDisk: 'Failed to write file to disk.',
          ExtensionNotAllowed: 'File extension not allowed.',
          FileIsNotImage: 'The uploaded file is not an image.',
          MimeTypeNotAllowed: 'The file is mime-type is not allowed.',
          WidthHeightExceeded: 'The image exceeds the allowed height or width limit.',
          FileSizeLargerThanAllowed: 'The file is larger than the application allows.',
          FileSizeSmallerThanAllowed: 'The image is smaller than the requested minimum size.',
          DocumentNotFound: 'Document not found.',
          FileDeleteTryAgain: 'Error removing file. Please, try to reload the page.',
          InternalErrorMissingData: 'Internal error. Missing data.',
          InternalError: 'Internal error. Please, reload the page and try again.',
        },
      },
    },
    PaymentSystem: {
      Success: {},
      Fail: {
        NotFoundInvalidToken:
          'This invoice is not integrated with the current account. Therefore, it was canceled only in the Fensor system.',
      },
    },
    LoginPage: {
      ForgotPassword: 'Forgot password?',
      LoadingValidation: 'Wait, we are validating...',
      LogInButton: 'Log In',
      LoginResultMessage: {
        BlockByOverTrying: 'You made too many login attempts. Please try again in a few seconds.',
        UserInactive: 'User Inactive',
        UserBlocked: 'User Blocked',
        UnauthorizedUserHere: 'No permission to access this address',
        ClubInactive: 'Club Inactive',
        SchoolInactive: 'School Inactive',
        UserIsStudent:
          'Platform available for Little Schools. If you are an athlete, go through www.fensor.com.br, if you are a photographer or guest, go through the address www.labfut.com.br',
        Redirect: 'We are redirecting...',
        InvalidCredentials: 'This login is not active or does not exist.',
        LoginNotExists: 'This login is not active or does not exist.',
      },
    },
    RecoveryPasswordPage: {
      LoadingValidation: 'Wait, we are validating...',
      ToLogin: 'To login click here',
      RecoveryButton: 'Recover',
      NewPasswordBackText: 'After writing the password and saving, click here',
      RecoveryResultMessage: {
        InvalidEmail: 'Email does not belong to the platform, please register.',
        Success: 'Email sent, please access it and follow the instructions.',
        PasswordsNotMatch: 'Passwords do not match.',
        SuccessfulChange: 'Changed password. Redirecting to login...',
        InternalError: 'An internal error has occurred. Try again, please.',
        IncorrectPassword: 'Current password incorrect.',
        InvalidToken: 'Invalid token. Please go to the password recovery page again.',
      },
    },
    SchoolHomePage: {
      BirthdaySubtitle: "See below for this month's birthdays",
      AlunoEmDia: 'Students up to date',
      AlunoEmDiaSubtitle: 'Students without overdue installments',
      OverdueStudents: 'Defaulting Students',
      OverdueStudentsSubtitle: 'Students with overdue installments',
      RegisteredStudents: 'Registered Students',
      RegisteredStudentsSubtitle: 'Total number of students',
      InactiveStudents: 'Inactive Students',
      InactiveStudentsSubtitle: 'Number of inactive students',
      ContractsExpiring: 'Contracts expiring',
      ContractsExpiringSubtitle: 'Contracts to expire in the month',
      ExpiredContracts: 'Expired contracts',
      ExpiredContractsSubtitle: 'Expired contracts in the month',
      ActiveContracts: 'Active contracts',
      ActiveContractsSubtitle: 'Total number of active contracts',
      NewContracts: 'New contracts',
      NewContractsSubtitle: 'Total number of new contracts',
      BillingsToReceive: 'Billings to receive',
      BillingsToReceiveSubtitle: 'Amount to receive in the month',
      OverdueBillings: 'Overdue billings',
      OverdueBillingsSubtitle: 'Total overdue billing',
      ReceivedBillings: 'Received billings',
      ReceivedBillingsSubtitle: 'Received amount in the month',
      TotalBilling: 'Total billing',
      TotalBillingSubtitle: 'Total amount in the month',
      MedicalExamExpired: 'Medical exam expired',
      MedicalExamExpiredSubtitle: 'Students with a 6-month medical exam',
      NoMedicalExam: 'No medical exam',
      NoMedicalExamSubtitle: 'Students without medical exam',
      StudentsMissingMandatoryDocuments: 'Incomplete doc.',
      StudentsMissingMandatoryDocumentsSubtitle: 'Students with missing mandatory documentation',
      StudentsActiveWithouContract: {
        Title: 'Students without contract',
        Subtitle: 'Students with active status without active contract',
      },
    },
    StudentHomePage: {
      AttendanceCard: {
        Title: 'My attendance',
        Subtitle: 'Check your class attendance.',
        Button: 'Go to attendance',
      },
      CommunicationCard: {
        Title: 'Communication',
        Subtitle: 'See below for releases and news for you.',
      },
    },
    EmployeePage: {
      PageTitle: 'Employees',
      PageSubtitle: 'See below your staff.',
      AddEmployeeCard: {
        Title: 'Add Employee',
        Subtitle: 'Click below to add an employee.',
        Button: 'Add Employee',
      },
      FilterEmployeeCard: {
        Title: 'Search for',
        Subtitle: 'Filter employees types.',
      },
      Modal: {
        TitleAdd: 'Include Employee',
        TitleEdit: 'Change employee',
        ButtonAdd: 'Include employee',
        ButtonEdit: 'Change employee',
        SystemMessage: {
          ErrorPhotoUserNotSaved: 'Employee needs to be saved before uploading Photo!',
        },
      },
      FormErrors: {
        Name: 'Please enter a name.',
        Email: 'Please enter an email address.',
        Phone: 'Please enter a cell phone number.',
        ValidPhone: 'Please enter a valid cell phone number',
        Cpf: 'Please enter a valid CPF.',
        Status: 'Please select a status.',
        DataInicio: 'Please, enter a start date.',
        Role: 'Please select a role.',
      },
    },
    StudentPage: {
      PageTitle: 'Students',
      PageSubtitle: 'See your students below.',
      AddStudentCard: {
        Title: 'Add student',
        Subtitle: 'Click below to add a student',
        Button: 'Add student',
        CopyLinkButton: 'Complete Link',
        CopyShortLinkButton: 'Short Link',
        CopyLinkSuccessButton: 'Copied!',
      },
      FilterStudentCard: {
        Title: 'Search for',
        Subtitle: 'Filter athletes types',
      },
      Modal: {
        TitleAdd: 'Include student',
        TitleEdit: 'Change student',
        ButtonAdd: 'Include student',
        ButtonEdit: 'Change student',
        OnlyStudentInThisClass: 'Show only students in this class',
      },
      ExternalLink: {
        Title: 'Add student',
        Subtitle: 'Fill in the data to add a student.',
        ButtonAdd: 'Add student',
        ButtonEdit: 'Change student',
      },
      SystemMessage: {
        ErrorPhotoUserNotSaved: 'Student needs to be saved before uploading Photo!',
        InvalidFields: 'Attention! There are invalid fields.',
        InvalidStudentCPF: 'Invalid Student ID',
        StudentRGExceedMaxSize: 'The student is ID/CERTIFICATE can only have a maximum of 32 digits',
        InvalidResponsibleCPF: 'Invalid Responsible ID',
        ResponsibleRGExceedMaxSize: 'The responsible ID can only have a maximum of 14 digits',
        StudentAgeNotAllowed:
          'Only students born in {{value, list(type: disjunction)}} are allowed in this class. Please contact the school.',
        ClassNotFound: 'Class not found. Please, contact the school.',
        MissingMandatoryDocuments: 'The following documents are mandatory and have not yet been attached:',
        ConfirmDeleteTrainingCenter: 'Are you sure you want to delete this Training Center?',
        InvalidPhoneNumberStudent: "Student's phone number is invalid",
        InvalidPhoneNumberMother: "Mother's phone number is invalid",
        InvalidPhoneNumberFather: "Father's phone number is invalid",
        InvalidInputMaxLenght: 'The {{input}} exceeds the maximum of {{count}} characters allowed!',
        InvalidInputMinLenght: 'The {{input}} must have at least {{count}} characters!',
        InvalidPhoneNumber: '{{input}} phone number is invalid',
        InvalidSchoolYear: 'The school year {{year}} is invalid',
        BirthDateCannotBeFuture: 'Birthdate cannot be greater than current date',
      },
      DocumentFileHelp: 'Available file types: .pdf, .png, .jpg e .jpeg',
      DocumentFileUploadConfirm: 'Do you already have a file saved for this file you want to replace?',
    },
    InactivateStudentModal: {
      FetchBaseDataFailMessage: "Error retrieving information about the student's classes and contracts.",
      CancelContractFailMessage: 'Some billings have not been cancelled.',

      StudentClassesSubtitle_one: 'This student is in {{count}} class.',
      StudentClassesSubtitle_other: 'This student is in {{count}} classes.',
      StudentClassesSubtitle_zero: 'This student is in {{count}} classes.',
      StudentClassesSubtitle_two: 'This student is in {{count}} classes.',
      StudentClassesSubtitle_few: 'This student is in {{count}} classes.',
      StudentClassesSubtitle_many: 'This student is in {{count}} classes.',

      StudentClassesInstruction:
        'In the button on the side you can remove it from all classes or you can choose individually from the list below.',
      StudentNoClasses: 'This student is not in any class.',

      StudentContractsSubtitle_one: 'This student has {{count}} active contract.',
      StudentContractsSubtitle_other: 'This student has {{count}} active contracts.',
      StudentContractsSubtitle_zero: 'This student has {{count}} active contracts.',
      StudentContractsSubtitle_two: 'This student has {{count}} active contracts.',
      StudentContractsSubtitle_few: 'This student has {{count}} active contracts.',
      StudentContractsSubtitle_many: 'This student has {{count}} active contracts.',

      StudentContractsInstruction:
        'In the button on the side you can end all contracts or you can choose individually from the list below.',
      StudentNoContracts: 'This student does not have any active contracts.',
      NoBilling: 'No open billing found for this contract.',
    },
    ClassPage: {
      PageTitle: 'Research result',
      PageSubtitle: 'See the registered classes below',
      CopyLinkSuccessButton: 'Copied!',
      Year: 'Years',
      AddClassCard: {
        Title: 'Add Class',
        Subtitle: 'Click below to add a class',
        Button: 'Add Class',
      },
      FilterClassCard: {
        Title: 'Search for',
        Subtitle: 'Filter the types of class',
      },
      Modal: {
        TitleAdd: 'Include class',
        TitleEdit: 'Change class',
        ButtonAdd: 'Include class',
        ButtonEdit: 'Change class',
        AllowedAges: 'Allowed ages <0>(by year of birth)</0>',
        AllowedAgesSimple: 'Allowed ages (year)',
      },
      FrequencyCard: {
        Title: 'Student Attendance',
        Subtitle: 'Check Your students attendance',
        Button: 'Go to Attendance',
        DownloadPDF: 'Download PDF',
      },
      LessonsCard: {
        Title: 'Planned Lessons',
        Subtitle: 'Click below to see planned lessons',
        Button: 'Lessons Planning',
      },
      LinkRegisterCard: {
        RegistrationLink: 'Registration link',
        Description: 'Link to student registration and direct inclusion in the class.',
        CopyLink: 'Copy link',
        SendViaWhatsApp: 'Send link via WhatsApp',
      },
      SystemMessage: {
        MaxStudentsExceed: 'The maximum number of students in this class has been reached.',
        ScheduleAlreadyIncluded: 'This day and time have already been included.',
        StudentAgeNotFound: 'No students of this age were found.',
        FirstChooseAges: 'First choose the allowed ages to choose students.',
        SelectSchedule: 'Select the day and time of class.',
        StudentAlreadyIncluded: 'This student has already been included.',
        EmployeeAlreadyIncluded: 'This employee has already been included.',
        StudentAgeNotAllowed: 'There are students whose date of birth does not meet the requirements:',
        RequiredFields: {
          Schedule: 'The fields "Day of the week" and "Schedule" are required.',
          Student: 'The field "Student" is required.',
          Employee: 'The field "Employee" is required.',
        },
      },
    },
    LessonPages: {
      LessonName: 'Lesson name',
      LinkedClasses: 'Linked classes',
      Actions: 'Actions',

      IncludeLesson: 'Include lesson',
      ChangeLesson: 'Change lesson',
      AddClubLesson: 'Add club lesson',

      ChooseLeast1Lesson: 'You must choose at least 1 lesson',
      ChooseLeast1Class: 'You must choose at least 1 class',

      AddLessonPlanning: 'Include lesson plan',
      ChangeLessonPlanning: 'Change lesson plan',

      AddLesson: 'Add lesson',
      AddClass: 'Add class',

      FilterByClass: {
        Title: 'Filter',
        Subtitle: 'Choose the class to filter the lessons',
        Label: 'Class',
        Name: 'Filter by Name',
      },

      PlanningForDate: 'Planning for the date',
      SelectedLessons: 'Selected lessons',
      NoLessonSelected: 'No lesson selected',
      SelectedClasses: 'Selected classes',
      NoClassSelected: 'No class selected',
      Responsible: 'Responsible',
      NumberStudents: 'Number of students',

      LessonPageTitle: 'Lessons',
      LessonPageSubtitle: 'See below for registered lessons.',

      ButtonAddLesson: 'Add lesson',
      ButtonAddLessonSubtitle: 'Click below to add a lesson.',

      ButtonAddLessonPlanning: 'Add lesson planning',
      ButtonAddLessonPlanningSubtitle: 'Click below to add a lesson planning.',

      FilterTitle: 'Search',
      FilterSubtitle: 'Filter lessons',

      LessonPlanningTitle: 'Planning calendar',
      LessonPlanningSubtitle: 'See the schedule of planned classes below.',

      StudentLessonPage: {
        PageTitle: 'Lessons',
        PageSubtitle: 'These are the classes planned for the selected day',
        hasClasses: 'Has classes',
        TodayDay: 'Todays day',
        SelectedDay: 'Selected Day',
        Assisted: 'Assisted',
        Pending: 'Pending',
        SeeClasses: 'See classes',
      },

      SystemMessage: {
        LessonCreated: 'Lesson created successfully.',
        LessonUpdated: 'Lesson updated successfully.',

        LessonPlanningCreated: 'Lesson planning created successfully.',
        LessonPlanningUpdated: 'Lesson planning updated successfully.',
      },
    },
    ClassDetailPage: {
      DetailCard: {
        Subtitle: 'Choose below a day of the class you want to see.',
      },
      EmployeeCard: {
        Title: 'Employees',
        Subtitle: 'See below the employees registered in this class.',
      },
      StudentCard: {
        Title: 'Students',
        Subtitle: 'See below the students registered in this class.',
      },
    },
    ClassStudentAttendancePage: {
      ActionCard: {
        Title: 'Attendance',
        Subtitle: 'Choose a day of the week below to make the call. Or search by class name.',
        NoStudentClassesFound: 'No classes found for the selected day.',
      },

      OptionsCard: {
        Title: 'Attendance',
        Subtitle: 'Choose below a day to call the roll',
      },
      RollCard: {
        Title: 'Note attendance',
        Subtitle: 'Click below to call the roll',
        Button: 'Call the roll',
      },
      RollModal: {
        Title: 'Note attendance',
        Button: 'Fetch students',
      },
      ContentCard: {
        NoStudentClassSelected: 'Select a class on the side.',
        Title: 'Attendance of students',
        Subtitle: 'See below the students registered in this class.',
      },
      RegisterStudentAttendance: {
        NoClassSelected: 'No class selected.',
        NoDateSelected: 'It is necessary to select a date and pick up students.',
      },
      SystemMessage: {
        NoNotesFound: 'No attendance notes for this class this month.',
        ChooseADay: 'Choose a day of the week to see classes attendance.',
        ChooseAClass: 'Choose a classes to see student attendance.',
        NoClassRegisteredInSchedule: 'No classes registered on this day and month.',
      },
    },
    AssessmentPage: {
      PageTitle: 'Assessment',
      PageSubtitle: 'Conduct reviews and see past reviews.',
      InputSearchPlaceholder: 'Search by name...',
      ButtonAddEvaluation: 'Add assessment',
      ButtonEditEvaluation: 'Edit assessment',
      ButtonDeleteEvaluation: 'Delete assessment',
      ButtonExportEvaluationPDF: 'Export as PDF',
      ButtonSendEvaluation: 'Send via WhatsApp',
      SystemMessage: {
        DeleteConfirmation:
          "<0>Attention!</0> Do you really want to delete this student's assessment? <2>If yes, click here!</2>",
      },
    },
    AssessmentListPage: {
      PageTitle: 'Assessment',
      PageSubtitle: 'See all student assessments',
    },
    AssessmentDetailsPage: {
      PageTitle: 'Assessment',
      PageSubtitle: 'See the score for this assessment',
    },
    AssessmentFormPage: {
      PageTitle: 'Assessment',
      PageSubtitle: 'Rate the student.',
      SaveButton: 'Save assessment',
      ExportButton: 'Export as PDF',
      SystemMessage: {
        AllFieldsRequired: 'All fields are required, except Observations.',
        TeacherRequired: 'Select a teacher for student assessment.',
      },
    },
    ReportsPage: {
      PageTitle: 'Reports',
      PageSubtitle: 'See the available reports below.',
      EmployeeReports: 'Employee reports',
      StudentReports: 'Student reports',
      ContractReports: 'Contract reports',
      FinancialReports: 'Financial reports',
      athletesClasses: 'Financial report with class',
      AttendanceReport: 'Attendance reports',
      DetailedFinancialReport: 'Detailed financial report',
      CashFlowReport: 'Cash flow report',
      BirthdaysMonthReport: 'BirthDays of the month report',
    },
    ClassFrequencyReportsPage: {
      Filter: {
        Title: 'Prepare attendance report',
        Subtitle: 'Select a class, time and period',
        ChooseTheClass: 'Choose the class',
        ChooseTime: 'Choose time',
        ChooseTheYear: 'Choose the year',
        ChooseTheMonth: 'Choose the month',
        SearchAthlete: 'Search Athlete',
        Athlete: 'Student',
      },
      Report: {
        Title: 'Attendance report',
        Subtitle: 'Automatically generated report based on provided data',
        Name: 'Name',
        Level: 'Level',
        AbsencePercent: '% of absences',
        PresencePercent: '% of presences',
        Absences: 'Absences',
        Presences: 'Presences',
        Justifications: 'Justifications',
        AllClasses: 'All classes',
      },

      Indicators: {
        TotalStudents: 'Total of students',
        TotalClasses: 'Total of classes',
        StudentAttendanceRate: 'Student attendance rate',
      },
    },
    FinancialReportsPage: {
      Filter: {
        Title: 'Financial report',
      },
      Indicators: {
        TotalExpense: 'Total expense',
        TotalIncome: 'Total income',
        Balance: 'Balance in the period',
      },
      xlsFile: 'financial-report',
    },
    CashFlowReportsPage: {
      PageTitle: 'Cash flow',
      xlsFile: 'cash-flow-report',
    },
    CommunicationPage: {
      PageTitle: 'Research result',
      PageSubtitle: 'See the latest registered news below.',
      AddCommunicationCard: {
        NewsTitle: 'Add news',
        NewsSubtitle: 'Click below to add news for students.',
        NewsButton: 'Add news',
        CommunicationTitle: 'Add communication',
        CommunicationSubtitle: 'Click below to add communication for students',
        CommunicationButton: 'Add communication',
      },
      FilterCommunicationsCard: {
        Title: 'Search for',
        Subtitle: 'Filter communication types.',
      },
      CommunicationCard: {
        Change: 'Change',
        SeeDetails: 'See details',
        SendMail: 'Send mail',
        SendViaWhatsApp: 'Send by WhatsApp',
      },
      Modal: {
        TitleAddNews: 'Include news',
        TitleAddCommunication: 'Include communication',
        TitleEditNews: 'Change news',
        TitleEditCommunication: 'Change communication',
        ButtonAddNews: 'Include news',
        ButtonAddCommunication: 'Include communication',
        ButtonEditNews: 'Change news',
        ButtonEditCommunication: 'Change communication',
      },
      SystemMessage: {
        ClassAlreadyIncluded: 'This class has already been included.',
        DateInvalid: 'The date must be equal to or greater than today.',
        FileNewsMustBeSaved: 'News must be saved before uploading the file!',
        FileCommunicationMustBeSaved: 'Communication must be saved before uploading the file!',
      },
    },
    ViewControlPage: {
      PageTitle: 'Access control',
      PageSubtitle: 'Configure which modules a user has access to below. Changes take effect on reloging.',
    },
    CoursesPage: {
      PageTitle: 'Courses',
      PageSubtitle: 'See below for a list of available courses.',
      NoCoursesAvailable: 'No courses have been made available yet.',
    },
    ExercisesPage: {
      PageTitle: 'Exercises',
      PageSubtitle: 'See below for a list of available exercises.',
      NoCoursesAvailable: 'No exercises have been made available yet.',
    },
    DocumentsPage: {
      PageTitle: 'Attached documents',
      PageSubtitle: 'See below for documents available for download.',
      NoDocumentsProvidedByClub: 'No documents made available by the club.',
    },
    InvoiceCreditConfigPage: {
      PageTitle: 'Invoice credit config',
      PageSubtitle: 'See below for a list of available plans.',
      NoCoursesAvailable: 'No plans have been created yet.',
    },
    ClubSchoolDocumentsPage: {
      PageTitle: 'Attached documents',
      PageSubtitle: 'See below for documents available for download.',
      ButtonAddClubSchoolDocuments: 'Add document',
      Modal: {
        TitleAdd: 'Include document',
        TitleEdit: 'Change document',
        ButtonAdd: 'Include document',
        ButtonEdit: 'Change document',
      },
      SystemMessage: {
        DeleteConfirmation: 'Are you sure you want to delete this document?',
      },
    },
    DocumentsAttachedDefault: {
      'Avaliação de alunos': 'Student assessment',
      'Avaliação de Instrutores do América': 'Instructor Assessment',
      'Avaliação de pais de alunos': 'assessment of parents of students',
      'Avaliações - Testes internos': 'Assessments - Internal tests',
      'Contrato de Matrícula': 'Enrollment Agreement',
      'Ficha de Matrícula': 'Enrollment Form',
      'Modelo Sintese Avaliação de Alunos': 'Student Assessment Synthesis Model',
      'Planejamento de treinos - em branco': 'Practice planning - blank',
      'Planejamento de treinos': 'training planning',
      'Plano de negócios e resultados escolas': 'Business plan and school results',
    },
    ProductsManagerPage: {
      PageTitle: 'Products',
      PageSubtitle: 'See your product chart below.',
      AddProductsCard: {
        Title: 'Add Product',
        Subtitle: 'Click below to add a product',
        Button: 'Add Product',
      },
      FilterProductsCard: {
        Title: 'Search for',
        Subtitle: 'Filter product types.',
      },
      Modal: {
        TitleAdd: 'Include product',
        TitleEdit: 'Change product',
        ButtonAdd: 'Include product',
        ButtonEdit: 'Change product',
      },
    },
    RequestsPage: {
      PageTitle: 'Requests',
      PageSubtitle: 'Follow the progress of your orders here.',
      FilterRequestsCard: {
        Title: 'Search for',
        Subtitle: 'Filter the types of requests.',
      },
    },
    MyRequestsPage: {
      PageTitle: 'Requests',
      PageSubtitle: 'Follow the progress of your orders here',
      FilterCard: {
        Title: 'Search for',
        Subtitle: 'Filter the types of requests.',
      },
    },
    StudentProductsPage: {
      PageTitle: 'Products',
      PageSubtitle: 'See below for products available for purchase.',
      FilterCard: {
        Title: 'Search for',
        Subtitle: 'Filter product types.',
      },
    },
    StudentDocumentsPage: {
      PageTitle: 'Documents',
      PageSubtitle: 'See your documents below.',
      PageSubtitleWarning: 'It is not possible to remove documents sent by the school.',
      CertificateSubtitle: 'Click below to view your current certificate.',
      ButtonCertificate: 'View certificate',
      ActionCard: {
        Title: 'Send document',
        Subtitle: 'Click below to upload a document.',
        Button: 'Upload',
      },
      SystemMessage: {
        DeleteConfirmation: 'Are you sure you want to delete this document?',
        DocumentTypeIsRequired: 'The file type is required.',
      },
    },
    StudentFinancialPage: {
      PageTitle: 'Search result',
      PageSubtitle: 'See below your registered payments.',
      EmptyTable: 'No payments created so far in this period.',
      FilterCard: {
        Title: 'Search',
        Subtitle: 'Filter your payments.',
      },
    },
    StudentCartPage: {
      PageTitle: 'Your Cart',
      PageSubtitle: 'See below the products added to the cart.',
      EmptyCartMessage: 'No products added to cart.',
    },
    StudentContractsPage: {
      PageTitle: 'Search result',
      PageSubtitle: 'See the registered contracts below.',
      ListCard: {
        Title: 'Students with Contracts',
        Subtitle: 'List of students with active contracts',
        List: {
          Student: 'Student',
          Class: 'Class',
          Value: 'Value',
          Status: 'Status',
          Action: 'Action',
          Invoiced: 'Invoiced',
          Yes: 'Yes',
          No: 'No',
        },
      },
      ActionCard: {
        Title: 'Add contract',
        Subtitle: 'Click below to add contracts for students.',
        Button: 'Add contract',
        ButtonMultiple: 'Create Multiple Contracts',
      },
      CustomContractCard: {
        title: 'Customize Contract',
        Subtitle: 'Click below to customize your schools agreement.',
        Button: 'Customize Contract',
      },
      FilterCard: {
        Title: 'Search',
        Subtitle: 'Filter contracts.',
      },
      ActionsTableList: {
        addButton: 'Create new contract',
        editButton: 'Edit contract',
        WhatsAppButton: 'Send WhatsApp',
        printerButton: 'Printer contract',
        showPass: 'Show Pass',
        showStudent: 'Show Student',
      },
      Modal: {
        TitleAdd: 'Include contract',
        TitleEdit: 'Change contract',
        ButtonAdd: 'Include contract',
        ButtonEdit: 'Change contract',
      },
      SystemMessage: {
        WarningInvoiceContract:
          "<0>Attention!</0> After billing, this Student's charges will be scheduled and changes regarding the financial terms of this contract will not be allowed.<2 /><3 />Do you want to continue? <5>If yes, click here!</5>",
        WarningCancelInvoiceContract:
          '<0>Info!</0> This contract is billed, the student is billed at every expiration date.<2 /><3 />Would you like to close or cancel this bill? <5>If yes, click here!</5>',
        WarningInvoiceContractMoney:
          '<0>Attention!</0> After the billing, this Student will be billed in cash and will not be allowed to change the financial terms of this contract.<2 /><3 />Do you want to continue? <5>If yes, click here!</5>',
        WarningCancelInvoiceContractMoney:
          '<0>Info!</0> This contract is invoiced. When canceling, all open installments will be deleted.<2 /><3 />Do you want to close or cancel this charge? <5>If yes, click here!</5>',
        WarningInactiveContractInvoiced:
          'To inactivate a contract with current billing, you must first cancel the billing, then inactivate the contract.',

        FirstDueDateInvalid: 'The "First Due Date" must be greater than "Contract Start Date".',
        EndDateInvalid: 'The "Contract End Date" must be greater than the "Contract Start Date".',
        ResponsibleCPFInvalid: 'The responsible ID is invalid, please change the registration and try again.',
        SubscribeApprovedNotifyStudent: 'Subscription approved. The Student will be notified at every expiration date.',
        SubscribeApproved: 'Subscription Approved.',
        SubscribeUnapproved: 'Subscription not approved, please try again.',
        CommunicationProblemServer: 'We have a problem communicating with the server, please try again later.',
        SchoolMustBeParameterized: 'This school must be parameterized by the Fensor team. Contact.',
        ValidationError: 'There was a validation error. Please review the data and try again.',
        InvoiceCanceled: 'Invoicing cancelled.',
        InvoiceCancelFail: 'Invoicing not canceled. Please try again.',
        InvoiceCancelFailTemp: 'Invoicing not canceled. Please wait a while and try again.',
        ServiceCreatedSuccess: 'Service registered successfully.',
        ServiceDeletedSuccess: 'Service deleted successfully. If you have a contract with this service, please update.',
        MaximumStudentsExceed: 'The maximum number of students in this class has been reached.',
        DateValidationError: 'Error validating dates.',
        CreateSuccess: 'Created successfully.',
        UpdateSuccess: 'Updated successfully.',
      },
    },
    SchoolFinancePage: {
      PageTitle: 'Research result',
      PageSubtitle: 'See the registered contract below.',
      ActionCard: {
        Title: 'Add bill',
        Subtitle: 'Click below to add bill',
        Button: 'Add bill',
      },
      FilterCard: {
        Title: 'Search for',
        Subtitle: 'Filter incoming and outgoing payments.',
      },
      Modal: {
        TitleAdd: 'Include bill',
        TitleEdit: 'Change bill',
        ButtonAdd: 'Include bill',
        ButtonEdit: 'Change bill',
        ButtonDelete: 'Delete bill',
        ButtonDeleteCancelInvoice: 'Delete bill and billing',
        ReceiveInCash: 'Receive in cash',
      },
      SystemMessage: {
        WarningInvoice:
          '<0>Attention!</0> After billing, charges will be scheduled for this customer and changes to this account will not be allowed.<2 /><3 />Do you want to continue? <5>If yes, click here!</5>',
        WarningCancelInvoice:
          '<0>Info.!</0> This account has been billed and the customer receives the charge at every expiration date.<2 /><3 />Really want to delete this charge? <5>If yes, click here!</5>',
      },
    },
    MandatoryDocsPage: {
      PageTitle: 'Mandatory student documents',
      PageSubtitle: 'Configure below which student documents are mandatory.',
    },
    ClubDashboardPage: {
      TotalStudents: '',
      TotalStudentsSubtitle: '',
      TotalStudentsFilename: '',
      ActiveStudents: '',
      ActiveStudentsSubtitle: '',
      ActiveStudentsFilename: '',
      InactiveStudents: '',
      InactiveStudentsSubtitle: '',
      InactiveStudentsFilename: '',
      NoDefaultingStudents: '',
      NoDefaultingStudentsSubtitle: '',
      NoDefaultingStudentsFilename: '',
      DefaultingStudents: '',
      DefaultingStudentsSubtitle: '',
      DefaultingStudentsFilename: '',
      MaleStudents: '',
      MaleStudentsSubtitle: '',
      MaleStudentsFilename: '',
      FemaleStudents: '',
      FemaleStudentsSubtitle: '',
      FemaleStudentsFilename: '',

      MonthlyPlans: '',
      MonthlyPlansSubtitle: '',
      MonthlyPlansFilename: '',
      QuarterlyPlans: '',
      QuarterlyPlansSubtitle: '',
      QuarterlyPlansFilename: '',
      AnnualPlans: '',
      AnnualPlansSubtitle: '',
      AnnualPlansFilename: '',
      PlansToExpire: '',
      PlansToExpireSubtitle: '',
      PlansToExpireFilename: '',
      ExpiredPlans: '',
      ExpiredPlansSubtitle: '',
      ExpiredPlansFilename: '',

      GrossIncome: '',
      GrossIncomeSubtitle: '',
      GrossIncomeFilename: '',
      TuitionsIncome: '',
      TuitionsIncomeSubtitle: '',
      TuitionsIncomeFilename: '',
      IncomeWithOrders: '',
      IncomeWithOrdersSubtitle: '',
      IncomeWithOrdersFilename: '',
      IncomeWithUniforms: '',
      IncomeWithUniformsSubtitle: '',
      IncomeWithUniformsFilename: '',
      IncomeWithEvents: '',
      IncomeWithEventsSubtitle: '',
      IncomeWithEventsFilename: '',
      TuitionsFee: '',
      TuitionsFeeSubtitle: '',
      TuitionsFeeFilename: '',
    },
    ModalSignatureUpload: {
      Title: 'Signature upload',
      Observation:
        'Obs.: This is the signature of the school manager. It will appear on student contracts and payment receipts.',
      Attention:
        '<0>Attention:</0> For the best quality, ensure that the image contains only the signature, with a white background and as little white border as possible.',
    },
    ModalApiSettings: {
      Title: 'Token API',
    },
    ModalHealthForm: {
      AreSmoker: 'Are you a smoker?',
      HowLong: 'How long?',
      Questions: {
        'Tem ou já teve as doenças abaixo?': 'Do you have or have you had the following diseases?',
        'Doenças infecto-contagiosa (AIDS, Hepatite, Chagas, Tuberculose, meningite, outros)':
          'Infectious diseases (AIDS, Hepatitis, Chagas, Tuberculosis, meningitis, others)',
        'Doença respiratória (Efisema, Asthma, Bronquite, Pneumonia) e outros':
          'Respiratory disease (Ephysema, Asthma, Bronchitis, Pneumonia) and others',
        'Problemas ortopédicos (Escoliose, Cifose, Artrose, Fraturas, Hérnia de Disco ou outro distúrbio ósseo ou de Membros)':
          'Orthopedic problems (Scoliosis, Kyphosis, Osteoarthritis, Fractures, Herniated Discs or other bone or limb disorders)',
        'Doença neurológica (Derrame cerebral ou outros / Desmaios)':
          'Neurological disease (Stroke or other / Fainting)',
        'Diabetes (Açúcar no sangue)': 'Diabetes (blood sugar)',
        'Câncer (Tumoração ou Leucemia)': 'Cancer (Tumor or Leukemia)',
        'Hérnia de qualquer natureza': 'Hernia of any kind',
        'Doença cardíaca (Insuficiência Cardíaca, Insuficiência coronariana, Angina, Cardiopatia congênita, arritmia) e outros':
          'Heart disease (Heart failure, Coronary failure, Angina, Congenital heart disease, arrhythmia) and others',
        'Doença vascular (Varizes, Insuf. Arterial, Aneurisma, Tromboangelite) e outros':
          'Vascular disease (Varicose, Arterial Insuf., Aneurysm, Thromboangelitis) and others',
        'Hipertensão arterial': 'Arterial hypertension',
        'Doença Urinária (Rins, Bexiga, Próstata, Uretra, Cálc. Renal, Obstr. Urinária, Prostatismo, Incont. Urinária, Insuf. Renal, Perda de Urina, Pedra nos Rins) outros':
          'Urinary Disease (Kidneys, Bladder, Prostate, Urethra, Kidney Calc, Urinary Obstruction, Prostatism, Urinary Incontinence, Renal Insuf., Urine Loss, Kidney Stones) others',
        'Distúrbio psquiátrico ou psicológico': 'Psychiatric or psychological disorder',
        'Doença ginecológica (Cisto de ovário, endometriose, mioma, incontinência urinária, Tumor de mama) e outros':
          'Gynecological disease (Ovarian cyst, endometriosis, myoma, urinary incontinence, Breast tumor) and others',
        'Doença reumatológica (Reumatismo, artrite) e outros':
          'Rheumatologic disease (Rheumatism, arthritis) and others',
        'Sequela de acidente, Moléstia adquirida ou congênita (nasceu com a doença)':
          'Accident sequel, Acquired or congenital disease (born with the disease)',
        'Úlcera ou doença do Ap. Digestivo (Probl. De Intestino, Hemorróida, Ictericia)':
          'Ulcer or disease of Ap. Digestive (Bowel Problem, Hemorrhoid, Jaundice)',
        'Tem indicação para submeter-se a alguma cirurgia': 'Is there an indication for undergoing any surgery?',
        'Doença de Otorrinolaringologia (Adenóide, Amigdalite, Sinusite, Problemas de ouvido, Audição - Apneia Noturna ) e outros':
          'Otolaryngology Disease (Adenoid, Tonsillitis, Sinusitis, Ear Problems, Hearing - Night Apnea) and others',
        'Doença Oftalmológica ( Miopia de Alto Grau, problemas de retina - córnea) e outros / dificuldade para enxergar de perto ou de longe':
          'Eye Disease (High Grade Myopia, retina-corneal problems) and others / difficulty seeing near or far',
        'Tem qualquer deficiência visual, catarata, glaucoma (pressão alta no olho) e outros':
          'Have any visual impairment, cataracts, glaucoma (high pressure in the eye) and others',
        'Tratamento de Quimioterapia ou Radioterapia': 'Chemotherapy or Radiotherapy Treatment',

        'Cirurgias Realizadas': 'Surgeries performed',
        Especificação: 'Specification',
        'Há quanto tempo?': 'How long?',
        'Já foi indicado para fazer algum tratamento clínico ou cirúrgico que não tenha realizado?':
          'Have you ever been recommended to undergo any clinical or surgical treatment that you have not performed?',
        Tratamento: 'Treatment',
        'Nome do médico que indicou': 'Name of the referring physician',
      },
    },
    WhatsAppComponent: {
      Title: 'WhatsApp Web - QR CODE',
      NewQrCode: 'New QR CODE',
      SendMessageButton: 'Send message',
      SendLinkButton: 'Send WhatsApp',
      SendAssessmentButton: 'Send assessment',
      CloseSession: 'Close session',
      RemoveSessionConfirmation: 'Are you sure you want to remove the session?',
      RemoveSessionInstruction: 'When removing the active session, it will be necessary to re-read the QR CODE.',
      RemoveSession: 'Remove session',
      WriteYourMessage: 'Write your message',
      SendMessage: 'Send message',
      SendingMessages: 'Sending messages...',
      SendButtonText: {
        SendNews: 'Send news',
        SendCommunication: 'Send communication',
        SendReceipt: 'Send receipt',
      },
      SendSuccessMessage: {
        LinkSentSuccessfully: 'Link sent successfully via WhatsApp.',
        SendSuccessfully: 'Message sent successfully via WhatsApp.',
        EvaluationSendSuccessfully: 'Assessment successfully sent via WhatsApp.',
        ContractSendSuccessfully: 'Contract successfully sent by WhatsApp.',
        ModelCardSendSuccessfully: 'Card sent successfully by WhatsApp.',
        ReceiptSendSuccessfully: 'Receipt sent successfully by WhatsApp.',
      },
      SendFailMessage: {
        Fail: 'Some messages were not sent.',
        IncorrectNumber: 'Incorrect number.',
        UnableRetrieveContactList: 'Unable to retrieve contact list.',
        NoContactsToSend: 'There are no contacts to send.',
      },
      Messages: {
        StudentRegistrationLink: 'Hi, *{{name}}*.\nFollow the link to register: {{link}}',
        StudentClassRegistrationLink: 'Hi, *{{name}}*.\nFollow the link to register: {{link}}',
      },
    },
    CertificatesModal: {
      ModalTitle: 'Certificates',
      CertificateHelpText: 'Text between asterisks (*) will be <0>bold</0>',
      LevelPlaceholder: 'Select a Level to configure it',
    },
    StatusType: {
      A: 'Active',
      I: 'Inactive',
      P: 'Pre-registered',
      Active: 'Active',
      Active_question: 'Active?',
      Inactive: 'Inactive',
      PreRegistered: 'Pre-registered',
      Closed: 'Closed',
      Ativo: 'Active',
      Inativo: 'Inactive',
      Encerrado: 'Closed',
      'Pré-cadastrado': 'Pre-registered',
    },
    SportsTypesPage: {
      title: 'Sports',
      textField: 'Add Sports Categories',
      Columns: {
        sports: 'Sports',
        remove: 'Remove',
      },
    },
    Sports: {
      Name: {
        'Futebol Society Fut 7': 'Society football',
        Futsal: 'Futsal',
        Volei: 'Volleyball',
        Basquete: 'Basketball',
        'Futebol Campo': 'Field football',
        Natação: 'Swimming',
        Tênis: 'Tennis',
        Academia: 'Academy',
        Personal: 'Personal',
        Ballet: 'Ballet',
        Dodgeball: 'Dodgeball',
        Hidroginástica: 'Water aerobics',
        Handebol: 'Handball',
        'Little Kickers': 'Little Kickers',
        'Beach Tennis': 'Beach Tennis',
      },
      Position: {
        Atacante: 'Forward',
        'Atacante Beirada': 'Wing',
        Zagueiro: 'Defender',
        '1° Volante': 'First defensive midfielder',
        'Meia Direita': 'Right midfielder',
        'Lateral Esquerdo': 'Left back',
        'Lateral Direito': 'Right back',
        Goleiro: 'Goalkeeper',
        Centroavante: 'Center forward',
        '2° Volante': 'Second defensive midfielder',
        'Meia Esquerda': 'Left midfielder',
        Lateral: 'Side',
        'Meio-Campo': 'Midfield',
        Pivô: 'Pivot',
        Meia: 'Sock',
        Ponta: 'Tip',
        'Armador Central': 'Center Owner',
        Fixo: 'Fixed',
        Ala: 'Wing',
        Libero: 'Líbero',
        Líbero: 'Líbero',
        'Ponta Direita': 'Right end',
        Levantador: 'Lifter',
        'Ponta Esquerda': 'Left end',
        Central: 'Central',
        Oposto: 'Opposite',
        Armador: 'Playmaker',
        'Ala-Armador': 'Wing guard',
        'Ala-Pivô': 'Pivot Wing',
        Natação: 'Swimming',
        'Sem posição': 'No position',
      },
    },
    DocumentTypeModel: {
      'Atestado Médico - 30 dias': 'Medical Certificate - 30 days',
      'Atestado Médico - 90 dias': 'Medical Certificate - 90 days',
      'Autorização dos Pais': 'Parental Authorization',
      CPF: 'ID',
      'Certidão de Nascimento': 'Birth certificate',
      'Comprovante de Endereço': 'Proof of residence',
      Contrato: 'Contract',
      'Declaração de Matrícula Escolar': 'School Enrollment Statement',
      'Eletro com Laudo Médico': 'Electro with Medical Report',
      'Ficha de cadastro': 'Registration form',
      'Foto 3 x 4': 'Photo 3x4',
      Passaporte: 'Passport',
      'RG - Carteira Identidade (Frente)': 'Identity Card (Front)',
      'RG - Carteira Identidade (Verso)': 'Identity Card (Back)',
      'RG e CPF do Responsável': 'Responsible ID',
      'RG/CNH frente (responsável)': 'Responsible ID (Front)',
      'RG/CNH verso (responsável)': 'Responsible ID (Back)',
      RGS3: 'RGS3',
      'Relatório Avaliação Fisica': 'Physical Evaluation Report',
      'Relatório Avaliação Fisica 2': 'TPhysical Evaluation Report 2',
      'Relatório Avaliação Fisica 3': 'TPhysical Evaluation Report 3',
      Tacticup: 'Tacticup',
      'Termo de Responsabilidade': 'Statement of responsibility',
      'Teste COVID': 'Test Covid',
      Boleto: 'Bank slip',
      'Comprovante de pagamento': 'Proof of payment',
      'Carteirinha do convênio médico': 'Medical insurance card',
      'Carta Clube/Escolinha': 'Club/School Letter',
      'Declaração de Freq. Escola': 'School Attendance Statement',
      'Manual do Atleta': "Athlete's Handbook",
      'Contrato do Atleta': 'Athlete Contract',
      'Relatório Avaliação Física 2022/1': 'Physical Assessment Report 2022/1',
      'Relatório Avaliação Física 2022/2': 'Physical Assessment Report 2022/2',
    },
    MonthName: {
      janeiro: 'January',
      fevereiro: 'February',
      março: 'March',
      abril: 'April',
      maio: 'May',
      junho: 'June',
      julho: 'July',
      agosto: 'August',
      setembro: 'September',
      outubro: 'October',
      novembro: 'November',
      dezembro: 'December',

      January: 'January',
      January_abbreviated: 'Jan',
      February: 'February',
      February_abbreviated: 'Feb',
      March: 'March',
      March_abbreviated: 'Mar',
      April: 'April',
      April_abbreviated: 'Apr',
      May: 'May',
      May_abbreviated: 'May',
      June: 'June',
      June_abbreviated: 'June',
      July: 'July',
      July_abbreviated: 'Jul',
      August: 'August',
      August_abbreviated: 'Aug',
      September: 'September',
      September_abbreviated: 'Sept',
      October: 'October',
      October_abbreviated: 'Oct',
      November: 'November',
      November_abbreviated: 'Nov',
      December: 'December',
      December_abbreviated: 'Dec',
    },
    WeekdayName: {
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Domingo: 'Sunday',
      'Segunda-Feira': 'Monday',
      'Terça-Feira': 'Tuesday',
      'Quarta-Feira': 'Wednesday',
      'Quinta-Feira': 'Thursday',
      'Sexta-Feira': 'Friday',
      Sábado: 'Saturday',
      Dom: 'Sun',
      Seg: 'Mon',
      Ter: 'Tue',
      Qua: 'Wed',
      Qui: 'Thu',
      Sex: 'Fri',
      Sáb: 'Sat',
    },
    EmployeeModel: {
      Occupations: {
        Director: 'Director',
        Manager: 'Manager',
        Teacher: 'Teacher',
        Secretary: 'Secretary',
        Coordinator: 'Coordinator',
        Intern: 'Intern',
        Physiotherapist: 'Physiotherapist',
        MassageTherapist: 'Marketing',
        Other: 'Other',
        Adm: 'Adm',
        Atleta: 'Athlete',
        'Diretor(a)': 'Director',
        'Fotógrafo(a)': 'Photographer',
        Visitante: 'Guest',
        'Gestor(a)': 'Manager',
        'Professor(a)': 'Teacher',
        'Secretário(a)': 'Secretary',
        'Coordenador(a)': 'Coordinator',
        'Estagiário(a)': 'Intern',
        'Outro(a)(s)': 'Other',
        Fisioterapeuta: 'Physiotherapist',
        Massagista: 'Marketing',
        Marketing: 'Marketing',
      },
    },
    StudentModel: {
      Type: {
        Social: 'Social',
        Regular: 'Regular',
        Selective: 'Selective',
        Housed: 'Housed',
        Seletiva: 'Selective',
        Alojado: 'Housed',
      },
    },
    RequestModel: {
      PaymentMethod: {
        Cartão: 'Card',
        Boleto: 'Bill',
        Dinheiro: 'Money',
      },
      PaymentStatus: {
        Aprovado: 'Approved',
        Pendente: 'Pending',
        Vencido: 'Overdue',
        Cancelado: 'Canceled',
      },
      Status: {
        'Ag. Confirmação': 'Awt. confirmation',
        'Em Separação': 'In separation',
        Entregue: 'Delivered',
        Cancelado: 'Canceled',
      },
    },
    RegisteredServicesModel: {
      'aulas de futebol': 'Soccer lessons',
      'treinamento de alto rendimento': 'High performance training',
      'personal soccer': 'Personal soccer',
      'treinamento virtual de futebol': 'Virtual soccer training',
      outros: 'Others',
      'aulas de natação': 'Swimming classes',
    },
    EvaluationModel: {
      Características: 'Characteristics',
      Tática: 'Tactic',
      Físicas: 'Physical',
      Fisicas: 'Physical',
      AttributeName: {
        Agilidade: 'Agility',
        'Contato Corporal': 'Body contact',
        Coordenação: 'Coordination',
        'Corrida 5 mts': '5 meter run',
        'Corrida 10 mts': '10 meter run',
        'Corrida 20 mts': '20 meter run',
        'Resistência Velocidade': 'Speed resistance',
        'Velocidade Recuperação': 'Speed recovery',
        '1 x 1 defensivo': '1 x 1 defensive',
        'Dinâmica de jogo': 'Game dynamics',
        'Jogadas combinadas': 'Combined moves',
        'Leitura de jogo': 'Game reading',
        'Movimento sem bola': 'Movement without ball',
        Posicionamento: 'Positioning',
        'Senso de cobertura': 'Sense of coverage',
        Transição: 'Transition',
      },
    },
    ContractModel: {
      DescriptionCardMonthly: 'Agreement with monthly charge by credit card',
      DescriptionCardQuarterly: 'Agreement with quarterly charge by credit card',
      DescriptionCardSemiannually: 'Agreement with semi-annual charge by credit card',
      DescriptionCardYearly: 'Agreement with annual charge by credit card',
      DescriptionBillMonthly: 'Agreement with monthly billing billing',
      DescriptionBillQuarterly: 'Contract with quarterly billet billing',
      DescriptionBillSemiannually: 'Agreement with biannual billing',
      DescriptionBillYearly: 'Annual billing contract',
      DescriptionMoneyMonthly: 'Agreement with monthly cash charge',
      DescriptionMoneyQuarterly: 'Quarterly Cash Billed Agreement',
      DescriptionMoneySemiannually: 'Agreement with semi-annual cash collection',
      DescriptionMoneyYearly: 'Annual Cash Billing Agreement',
    },
    TableBorderedComponent: {
      emptyTableMessage: 'So far, no information has been added',
    },
    SupplierPage: {
      PageTitle: 'Suppliers',
      PageSubtitle: 'See below your suppliers.',
      AddSupplierCard: {
        Title: 'Add Supplier',
        Subtitle: 'Click below to add an supplier.',
        Button: 'Add Supplier',
      },
      FilterSupplierCard: {
        Title: 'Search for',
        Subtitle: 'Filter suppliers types.',
      },
      Modal: {
        TitleAdd: 'Include supplier',
        TitleEdit: 'Change supplier',
        ButtonAdd: 'Include supplier',
        ButtonEdit: 'Change supplier',
        ViewSupplier: 'Supplier information',
      },
    },
    Email: 'Email',
    User: 'User',
    Password: 'Password',
    Attention_exclamation: 'Attention!',
    Authenticated_exclamation: 'Authenticated!',
    Success_exclamation: 'Success!',
    Documents: 'Documents',
    Settings: 'Settings',
    ChangePassword: 'Change Password',
    Disconnect: 'Disconnect',
    Language: 'Language',
    YourCart: 'Your Cart',
    Birthday: 'Birthday',

    Birthday_one: 'Birthday',
    Birthday_other: 'Birthdays',
    Birthday_zero: 'Birthdays',
    Birthday_two: 'Birthdays',
    Birthday_few: 'Birthdays',
    Birthday_many: 'Birthdays',

    Student: 'Student',
    Students: 'Students',
    Student_one: 'Student',

    StudentCount_one: '{{count}} student',
    StudentCount_other: '{{count}} students',
    StudentCount_zero: '{{count}} students',
    StudentCount_two: '{{count}} students',
    StudentCount_few: '{{count}} students',
    StudentCount_many: '{{count}} students',

    Employee_one: 'Employee',
    Birth: 'Birth',
    BirthDate: 'Birth Date',
    ShowMore: 'Show More',
    Name: 'Name',
    Status: 'Status',
    Function: 'Function',
    Occupation: 'Occupation',
    Position: 'Position/proof',
    SeeStudent: 'See student',
    EditStudent: 'Edit student',
    SeeAthlete: 'See athlete',
    EditAthlete: 'Edit athlete',
    SeeEmployee: 'See employee',
    EditEmployee: 'Edit employee',
    SeeSupplier: 'See supplier',
    EditSupplier: 'Edit supplier',
    SeeAll: 'See all',
    All: 'All',

    Search: 'Search',
    CPF: 'ID',
    CellPhone: 'Cell phone',
    CEP: 'Zip code',
    Address: 'Address',
    State: 'State',
    Location: 'Location',
    City: 'City',
    Neighborhood: 'Neighborhood',
    StartDate: 'Start Date',
    EndDate: 'End date',
    Description: 'Description',
    ChangePhoto: 'Change photo',
    DeletePhoto: 'Delete photo',
    Select: 'Select',
    BiologicalSex: 'Biological Sex',
    Feminine: 'Feminine',
    Masculine: 'Masculine',
    StudentCod: 'Student code',
    StudentCode: 'Student code',
    YearOfBirth: 'Year of birth',
    SportType: 'Sport Type',
    StudentType: 'Student Type',
    Contract: 'Contract',
    Contracts: 'Contracts',
    RegisteredStudents: 'Registered students',
    RegisteredStudentsThisSchool: 'Registered students in this school',
    ActiveStudents: 'Active students',
    ActiveStudentsThisSchool: 'Active students in this school',
    InactiveStudents: 'Inactive students',
    InactiveStudentsThisSchool: 'Inactive students in this school',
    ContractsThisSchool: 'Contracts of this school',
    School: 'School',
    Uninformed: 'Uninformed',
    AttachDocs: 'Attach docs',
    Type: 'Type',
    Types: 'Types',
    LoadFile: 'Load file',
    AttachedDocuments: 'Attached documents',
    NoDocumentsAttached: 'No documents attached',
    View: 'View',
    Delete: 'Delete',
    RG: 'Birth registration',
    HowKnowSchool: 'How did you get to know the school?',
    Benefit: 'Benefit',
    Recommendation: 'Recommendation',
    Indicação: 'Recommendation',
    DisabledOption: 'Disabled option',
    MotherName: "Mother's name",
    MotherCPF: "Mother's ID",
    MotherCellPhone: "Mother's cell phone",
    FatherName: "Father's name",
    FatherCPF: "Father's ID",
    FatherCellPhone: "Father's cell phone",
    FinancialResponsible: 'Financial responsible',
    FinancialResponsibleName: 'Financial Responsible name',
    FinancialResponsibleEmail: 'Responsible email',
    FinancialResponsibleRG: 'Responsible birth registration',
    FinancialResponsibleCPF: 'Responsible ID',
    FinancialResponsibleCellPhone: 'Responsible cell phone',
    Indication: 'Indication',
    Grandparent: 'Grandparent',
    Mother: 'Mother',
    Father: 'Father',
    UncleAunt: 'Uncle or Aunt',
    Cousin: 'Cousin',
    Sibling: 'Sibling',
    None: 'None',
    'Avô(ó)': 'Grandparent',
    Pai: 'Mother',
    Mãe: 'Father',
    'Tio(a)': 'Uncle or Aunt',
    'Primo(a)': 'Cousin',
    'Irmão(ã)': 'Sibling',
    Nenhum: 'None',
    Kinship: 'Kinship',
    StudentName: "Student's name",
    InstallmentAmount: 'Installment amount',
    InstallmentDue: 'Installment due',
    Responsible: 'Responsible',
    Responsible_abbreviated: 'Resp.',
    ResponsiblePhoneNumber: 'Responsible cell phone',
    ContractStart: 'Start of contract',
    ContractEnd: 'End of contract',
    BillingCycle: 'Billing cycle',
    RemoveSession: 'Remove session',
    Back: 'Back',
    SendMessage: 'Send message',
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    SendLink: 'Send link',
    RequiredField: 'The field "{{fieldName}}" is required!',
    Laterality: 'Laterality',
    Ambidextrous: 'Ambidextrous',
    LeftHanded: 'Left-handed',
    RightHanded: 'Right-handed',

    Day_one: '{{count}} day',
    Day_other: '{{count}} days',
    Day_zero: '{{count}} days',
    Day_two: '{{count}} days',
    Day_few: '{{count}} days',
    Day_many: '{{count}} days',

    TimeAtSchool: 'Time at school',
    ParticipationInSelectives: 'Participation in selectives',
    ApprovalInSelective: 'Approval in selective',
    NumberOfClasses: 'Number of classes',
    NumberOfAbsences: 'Number of absences',
    Absences: 'Absences',
    Absences_alt: 'Absence(s)',
    StudentInformation: 'Student information',
    BestFoot: 'Best foot',
    Right: 'Right',
    Left: 'Left',
    About: 'About',
    Financial: 'Financial',
    SeeStudentInstallmentsBelow: 'See Student installments below.',
    Everything: 'Everything',
    Overdue: 'Overdue',
    Overdues: 'Overdues',
    Overdue_male_one: 'Overdue',

    Overdue_female_one: 'Overdue',
    Overdue_male_other: 'Overdues',
    Overdue_male_zero: 'Overdues',
    Overdue_male_two: 'Overdues',
    Overdue_male_few: 'Overdues',
    Overdue_male_many: 'Overdues',

    Overdue_female_other: 'Overdues',
    Overdue_female_zero: 'Overdues',
    Overdue_female_two: 'Overdues',
    Overdue_female_few: 'Overdues',
    Overdue_female_many: 'Overdues',

    ToExpire: 'To expire',
    Paid: 'Paid',

    Customer: 'Customer',
    DueDate: 'Due date',
    DueDate_abbreviated: 'Due date',
    Installment: 'Installment',
    Installment_abbreviated: 'instalm.',

    Installment_one: 'Installment',
    Installment_other: 'Installments',
    Installment_zero: 'Installments',
    Installment_two: 'Installments',
    Installment_few: 'Installments',
    Installment_many: 'Installments',

    Value: 'Value',
    Received: 'Received',
    Received_abbreviated: 'Recv.',
    Action: 'Action',
    Receipt: 'Receipt',
    NoOpenInstallment: 'No outstanding installments so far.',
    Yes: 'Yes',
    Not: 'Not',
    S: 'Y',
    N: 'N',
    Change: 'Change',
    Selective: 'Selective',

    Selective_one: 'Selective',
    Selective_other: 'Selectives',
    Selective_zero: 'Selectives',
    Selective_two: 'Selectives',
    Selective_few: 'Selectives',
    Selective_many: 'Selectives',

    StudentSelectiveSubtitle: 'See below the selectives that your student participated.',
    Club: 'Club',
    Place: 'Place',
    Date: 'Date',
    StudentAttendance: 'Student attendance',
    StudentAttendanceSubtitle: 'See student attendance in class below.',
    Class: 'Class',

    Class_one: 'Class',
    Class_other: 'Classes',
    Class_zero: 'Classes',
    Class_two: 'Classes',
    Class_few: 'Classes',
    Class_many: 'Classes',

    Classes: 'Class',

    Classes_one: 'Class',
    Classes_other: 'Classes',
    Classes_zero: 'Classes',
    Classes_two: 'Classes',
    Classes_few: 'Classes',
    Classes_many: 'Classes',
    My_classes: 'My Classes',

    Day: 'Day',
    Days: 'Days',
    Schedule: 'Schedule',
    See: 'See',
    ClassName: 'Class name',
    MaxStudentsInClass: 'Maximum students in class',
    Weekday: 'Day of the week',
    IncludeStudent: 'Include student',
    IncludeEmployee: 'Include employee',
    NumClassPerWeek: 'Number of classes per week',
    CardForgettingFee: 'Card forgetting fee',
    CardFee: 'Card fee',
    ChooseADay: 'Choose a day',
    Information: 'Information',
    Information_abbreviation: 'Info.',
    Information_abbreviation_exclamation: 'Info.!',
    Confirm: 'Confirm',
    Localization: 'Localization',
    CNPJ: 'Tax Code',
    StudentsEvaluated: 'Students evaluated',
    Average: 'Average',
    Sum: 'Sum',
    Actions: 'Actions',
    Assessment: 'Assessment',
    Characteristics: 'Characteristics',
    Observation: 'Observation',
    Observations: 'Observations',
    NoTeacherForThisName: 'No teacher with this name',
    Revenue: 'Revenue',
    Revenues: 'Revenues',
    Expense: 'Expense',
    Expenses: 'Expenses',
    Employee: 'Employee',
    Employees: 'Employees',
    CreatedAt: 'Created at',
    CreatedBy: 'Created by',
    UpdatedAt: 'Updated at',
    UpdatedBy: 'Updated by',
    Profile: 'Profile',
    Role: 'Role',
    Service: 'Service',
    Billing: 'Billing',
    Payment: 'Payment',
    Payments: 'Payments',
    PaymentId: 'Payment ID',
    ContractNumber: 'Contract number',
    Provider: 'Provider',
    Account: 'Account',
    Mode: 'Mode',
    AmountReceived: 'Amount received',
    DowntownUser: 'Downtown user',
    News: 'News',

    News_one: 'News',
    News_other: 'News',
    News_zero: 'News',
    News_two: 'News',
    News_few: 'News',
    News_many: 'News',

    Communication: 'Communication',
    Communications: 'Communications',
    Close: 'Close',
    Notícia: 'News',
    Comunicado: 'Communication',
    Title: 'Title',
    Subtitle: 'Subtitle',
    AsFrom: 'From',
    SendTo: 'Send to',
    Athlete: 'Athlete',
    Athletes: 'Athletes',
    Attachment: 'Attachment',
    Attachments: 'Attachments',
    AttachFile: 'Attach file',
    CurrentPassword: 'Current password',
    NewPassword: 'New password',
    ConfirmNewPassword: 'Confirm new password',
    Module: 'Module',
    MarkAll: 'Mark all',
    MarkAll_question: 'Mark all?',
    AllowAccess: 'Allow access',
    Save: 'Save',
    Product: 'Product',
    Products: 'Products',
    ProductCode: 'Product code',
    ServiceCode: 'Service code',
    ProductCode_abbreviated: 'Product cod.',
    RegisteredProducts: 'Registered products',
    TotalStockValue: 'Total amount in stock',
    ProductsSold: 'Products sold',
    Price: 'Price',
    Photo: 'Photo',
    Photos: 'Photos',
    EditProduct: 'Edit product',
    AttachPhotos: 'Attach photos',
    LoadImage: 'Load image',
    AttachedPhotos: 'Attached Photos',
    NoPhotoAttached: 'No photo attached',
    Cover: 'Cover',
    BarCode: 'Bar code',
    Size: 'Size',
    Sizes: 'Sizes',

    Size_one: 'Size',
    Size_other: 'Sizes',
    Size_zero: 'Sizes',
    Size_two: 'Sizes',
    Size_few: 'Sizes',
    Size_many: 'Sizes',

    Letter: 'Letter',
    Number: 'Number',
    Single: 'Single',
    PlaceholderMoneyZero: '$0.00',
    RequestNumber: 'Request number',
    ProductName: "Product's name",
    RequestDate: 'Request date',
    Approved: 'Approved',
    Pending: 'Pending',
    AwaitingConfirmation: 'Awaiting confirmation',
    AwaitingConfirmation_abbreviated: 'Awt. confirmation',
    InSeparation: 'In separation',
    Delivered: 'Delivered',
    EnterStudentName: 'Enter student name',
    EnterRequestNumber: 'Enter request number',
    EnterProductName: 'Enter product name',
    TotalOrders: 'Total orders',
    TotalApproved: 'Total approved',
    TotalPending: 'Total pending',
    RequestNumberValue: 'Request {{number}}',
    SeeDetails: 'See details',
    SendMail: 'Send mail',
    ChangeToDelivered: 'Change to delivered',
    Quantity: 'Quantity',
    PaymentMethod: 'Payment method',
    PaymentMethods: 'Payment methods',
    TotalOrder: 'Total order',
    MyAttendance: 'My attendance',
    PurchaseDate: 'Purchase date',
    TotalPurchases: 'Total purchases',
    ProductsInCart: 'Products in cart',
    InitialPrice: 'Initial price',
    FinalPrice: 'Final price',
    Example123456: 'Example: 123456',
    AvailableProducts: 'Available products',
    SeeProduct: 'See product',
    Add: 'Add',
    Choose: 'Choose',

    UnitCount_one: '{{value}} unit',
    UnitCount_other: '{{value}} units',
    UnitCount_zero: '{{value}} units',
    UnitCount_two: '{{value}} units',
    UnitCount_few: '{{value}} units',
    UnitCount_many: '{{value}} units',

    AddToCart: 'Add to cart',
    EnterTheSize: 'Enter the size!',
    DocumentType: 'Document type',
    IsSure: 'Is sure?',
    Cancel: 'Cancel',
    Card: 'Card',
    Bill: 'Bill',
    Money: 'Money',
    InitialOverdue: 'Initial expiration date',
    EndOverdue: 'Final expiration date',
    InitialPaymentDate: 'Initial payment date',
    EndPaymentDate: 'Final payment date',
    TotalPayment: 'Total payment',
    Subtotal: 'Subtotal',
    OrderSummary: 'Order summary',
    Total: 'Total',
    Checkout: 'Checkout',
    KeepBuying: 'Keep buying',
    ContractedService: 'Contracted service',
    ActiveContracts: 'Active Contracts',
    InactiveContracts: 'Inactive Contracts',
    SoccerLessons: 'Soccer lessons',
    HighPerformanceTraining: 'High performance training',
    Other: 'Other',
    Others: 'Others',
    PersonalSoccer: 'Personal soccer',
    SwimmingClasses: 'Swimming classes',
    VirtualSoccerTraining: 'Virtual soccer training',
    ContractNumberValue: 'Contract {{number}}',
    EditContract: 'Edit contract',
    PDFContract: 'PDF contract',
    SendWhatsApp: 'Send WhatsApp',
    ModelCard: 'Model card',
    SendContract: 'Send contract',
    SendModelCard: 'Send model card',
    President: 'President',
    PDFModelCard: 'PDF model card',
    InactivatedClass: 'inactivated class',
    NoStudentName: 'No student with this name',
    AddDeleteServices: 'Add or delete services',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Semiannually: 'Semiannually',
    Yearly: 'Yearly',
    TakeInsurance: 'Take out DMHO insurance',
    InsuranceTypeFormatted: '{{value, currency(currency: USD)}}/month (Coverage {{coverage, currency(currency: USD)}})',
    NoInsurance: 'No insurance',
    DiscountType: 'Discount Type',
    LateInterestType: 'Interest Type',
    LateFeeType: 'Type of fine',
    NoDiscount: 'No discount',
    NoLateInterest: 'No interest',
    NoLateFee: 'No Fine',
    Percentage: 'Percentage',
    Fixed: 'Fixed',
    DiscountAmount: 'Discount amount',
    PlaceholderPercentageZero: '0.00%',
    MonthlyAmountWithoutDiscount: 'Amount without discount',
    AmountInsurance: 'Amount + insurance',
    LateInterestAmount: 'Interest Amount',
    LateFeeAmount: 'Fine Amount',
    ContractStatus: 'Contract status',
    MonthlyWorkload: 'Monthly workload',
    Workload: 'Workload',
    FirstDueDate: 'First due date',
    ContractStartDate: 'Contract start date',
    ContractEndDate: 'Contract end date',
    InvoiceContract: 'Invoice contract',
    CancelInvoicing: 'Cancel invoicing',
    AddContractedServices: 'Add contracted services',
    ServiceName: 'Service name',
    Include: 'Include',
    NoRegisteredServices: 'No services registered',
    ListRegisteredServices: 'List of registered services',
    CustomerOrSupplier: 'Customer or Supplier',
    NoCustomerOrSupplier: 'No customer or supplier with this name',
    EnterCustomerSupplierName: 'Enter the name of the customer or supplier',
    EnterTheName: 'Enter the name',
    BillsToReceive: 'Bills to receive',
    BillsToPay: 'Bills to pay',
    PeriodBalance: 'Period balance',
    SearchCustomer: 'Search customer',
    SearchSupplier: 'Search supplier',
    NoRevenueInPeriod: 'No recipes created so far in this period.',
    NoExpenseInPeriod: 'No expenses created so far in this period.',
    CreateOrUpdateCustomer: 'Create or update customer',
    PostingAccount: 'Posting Account',
    IntegratedOnly: 'Only Integrated',
    Integrated: 'Integrated',
    InCash: 'Cash',
    PaymentInstallments: 'Installments',
    ContractSigning: 'Contract Signing',
    ExpirationDate: 'Expiration date',
    InstallmentValueWithoutDiscount: 'Undiscounted instalm. value',
    NumberOfInstallments: 'Number of installments',
    PayDay: 'Payment date',
    PayDay_abbreviated: 'Paym. date',
    NoDocument: 'No document',
    ViewAttachedDocument: 'View Attached Document',
    CustomerAlreadyExists: 'This customer already exists. Do the survey again to check the data.',
    SupplierAlreadyExists: 'This supplier already exists. Do the survey again to check the data.',
    Research: 'Research',
    CPFOrCNPJ: 'Id or Tax Code',
    Alert: 'Alert',
    ManagedClasses: 'Managed classes',
    EmployeeInformation: 'Employee information',
    EmployeeClassesSubtitle: 'See below the groups registered for this employee.',
    SeeClasses: 'See classes',
    ExpirationDateMedicalExamination: 'Expiration date of the medical examination',
    StudentDontHaveClassesRegistered: 'This student is not registered in any class',
    StudentBirthYear: "Student's birth year",
    Link: 'Link',
    TrainingTime: 'Training time',
    Morning: 'Morning',
    Evening: 'Evening',
    Night: 'Night',
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    Internet: 'Internet',
    Signed: 'Signed',
    ResetPassword: 'Reset password',
    Active: 'Active',
    Inactive: 'Inactive',
    ContractValue: 'Contract value',
    RemoveAll: 'Remove all',
    RemoveFromClass: 'Remove from class',
    CloseAll: 'Close all',
    TerminateContractCancelBillings: 'Terminate contract and cancel billings',
    AwaitingPayment: 'Awaiting payment',
    OverdueBilling: 'Overdue billing',
    Finalize: 'Finalize',
    Present: 'Present',
    Absent: 'Absent',
    Recipient_alt: 'Recipient(s)',
    ContractedInsurance: 'Contracted insurance',
    AmountPaid: 'Amount paid',
    MakeMandatory: 'Make mandatory',
    Mandatory: 'Mandatory',
    Mandatory_question: 'Mandatory?',
    MissingDocuments: 'Missing documents',
    Weight: 'Weight',
    Height: 'Height',
    Age: 'Age',
    AnamnesisForm: 'Anamnesis Form',
    AnamnesisForm_abbreviated: 'Anamnesis Form',
    Remove: 'Remove',
    AddLine: 'Add line',
    Print: 'Print',
    BalanceMonthStart: 'Balance at the beginning of the month',
    Balance: 'Balance',
    Export: 'Export',
    Complement: 'Complement',
    'E-commerce': 'E-commerce',
    Document: 'Document',
    DeleteDocument: 'Delete document',
    PermanentAction: 'This action is permanent',
    PermanentAction_dot: 'This action is permanent.',
    FileIsRequired: 'It is required to choose a file.',
    ContractAlreadyInvoiced: 'This contract is already invoiced.',
    SkillLevel: 'Skill level',
    Sublevel: 'Sublevel',
    SchoolYear: 'School year',
    Beginner: 'Beginner',
    Intermediary: 'Intermediary',
    Advanced: 'Advanced',
    ComplimentaryProduct: 'Complimentary product',
    Discontinued: 'Discontinued',
    BodyFatPercentage: 'Body fat percentage',
    IMC: 'BMI',
    IMCPlaceholder: 'Fill in the "Weight" and "Height" fields',
    AgeNotTabulated: 'Age not tabulated',
    VideoURL: 'Video URL',
    Lesson: 'Lesson',
    Lessons: 'Lessons',
    Summary: 'Summary',
    Forward: 'Forward',
    Planning: 'Planning',
    FilterByDate: 'Filter by date',
    CTLocation: 'CT Location',
    AddDeleteCTs: "Add or delete TC's",
    TrainingCenter: 'Training center',
    NoRegisteredTrainingCenter: 'No registered training center',
    ListRegisteredTrainingCenters: 'List of registered training centers',
    EditTrainingCenter: 'Editar training center',
    Clear: 'Clear',
    BackgroundImage: 'Background image',
    NotRequired: 'not required',
    MaxSize: 'Max size',
    Certificates: 'Certificates',
    Certificate: 'Certificate',
    Level: 'Level',
    Complete: 'Complete',
    SearchByStudentName: 'Search by student name',
    FrequencyInClasses: 'Frequency in classes',
    FrequencyInClasses_abbreviated: 'Freq. in classes',
    UnjustifiedAbsences: 'Unjustified absences',
    UnjustifiedAbsences_abbreviated: 'Unjustified absences',
    JustifiedAbsences: 'Justified absences',
    JustifiedAbsences_abbreviated: 'Justified absences',
    CallTheRoll: 'Call the roll',
    SelectDate: 'Select date',
    FetchStudents: 'Fetch students',
    JustifyAbsence: 'Justify absence',
    AddJustify: 'Add justify',
    ViewJustifiedAbsences: 'Justified absences',
    AbsenceReason: 'Absence reason',
    MaxCharacters: 'Maximum {{value}} characters',
    Remuneration: 'Remuneration',
    Canceled: 'Canceled',
    ReleaseCertificate: 'Release certificate?',
    SecondCopy_abbreviated: '2nd copy',
    CancelOrder: 'Cancel order',
    PayNow: 'Pay now',
    Choose_a_category: 'Choose a category',
    Choose_a_type: 'Choose a type service',
    Create_Purchase_Order: 'Create purchase order',
    AnnualReview: 'Annual Review',
    Registration: 'Registration',
    Dropouts: 'Dropouts',
    Defaulters: 'Defaulters',
    PaidOut: 'Paid Out',
    TotalOfStudents: 'T. Students',
    SignatureCreditPage: {
      Balance: 'Balance',
      BalanceDescription: 'The amount of documents you still have available for digital signature.',
      BuyCredits: 'Buy signature credits',
      FilterTitle: 'Bought/signed documents statement',
      AdvancedFilters: 'Advanced filters',
      StartPeriod: 'Start period:',
      EndPeriod: 'End period:',
      Status: 'Status',
      Date: 'Date',
      Quantity: 'Qty.',
      Value: 'Value',
      Description: 'Description',
      Link: 'Link',
      NoOption: 'None of the options',
      PendingPayment: 'Pending payment',
      DonePayment: 'Payment done',
      Spent: 'Spent',
    },
    BuySignatureCreditPage: {
      ChoosePlanDescription: 'Choose a plan to proceed:',
      Back: 'Back',
    },
    PlanCard: {
      Plano: 'Plan',
    },
    SignatureContractModal: {
      Title: 'Sign contract',
      AddSigner: 'Add signer',
      RequestSigns: 'Request signatures',
      Name: 'Full Name',
      DeliveryMethod: 'Delivery method',
      Phone: 'Phone',
      SignAction: 'Signature action',
      Email: 'Email',
      Signed: 'Signed',
      Unsigned: 'Not Signed',
      NameValidationError: 'O campo deve ter o nome completo'
    },
  },
};

export default translation;
