import * as React from 'react';
import moment from 'moment';
import api from '../services/api';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import ButtonRounded from '../components/buttons/ButtonRounded';
import Footer from '../components/school/Footer';

export const StudentFormContext = React.createContext();

export default function ({ children, navigation }) {
  const alertRef = React.useRef(null);

  const history = useHistory();

  const [state, _setState] = React.useState({
    atleta: stateAtletaSchema,
    esportes: stateEsportesSchema,
    matricula: stateMatriculaSchema,
    fiador: stateFiadorSchema,
    mensal: stateMensalSchema,
    cobranca: stateCobrancaSchema,
    responsaveis: stateResponsaveisSchema,
    turmas: stateSelectedTurmasSchema,
    done: false,
  });

  const handlerFinally = () => {
    history.push('/student-school');
    formContext.setState({ done: false });
  };

  const formContext = React.useMemo(
    () => ({
      atleta: state.atleta,
      esportes: state.esportes,
      matricula: state.matricula,
      fiador: state.fiador,
      mensal: state.mensal,
      cobranca: state.cobranca,
      responsaveis: state.responsaveis,
      turmas: state.turmas,
      school: JSON.parse(localStorage.getItem('school')),
      company: JSON.parse(localStorage.getItem('company')),
      user: JSON.parse(localStorage.getItem('user')),
      done: state.done,
      handlerFinally,
      request: async (url) => {
        const { data } = await api.get(url);
        return data;
      },
      requestValidate: async (url, payload) => {
        const { data } = await api.post(url, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return data;
      },
      setState(state) {
        _setState((prevState) => ({ ...prevState, ...state }));
      },
      reset: () => {
        _setState({
          atleta: stateAtletaSchema,
          esportes: stateEsportesSchema,
          matricula: stateMatriculaSchema,
          fiador: stateFiadorSchema,
          mensal: stateMensalSchema,
          cobranca: stateCobrancaSchema,
          responsaveis: stateResponsaveisSchema,
          turmas: stateSelectedTurmasSchema,
        });
      },
      normalResponse: (res) => {
        const transformedPayload = {};

        for (const key in res) {
          transformedPayload[key] = { value: res[key], error: '' };
        }
        return transformedPayload;
      },
    }),
    [state]
  );
  return (
    <main>
      <SweetAlert
        ref={alertRef}
        show={state.done}
        success
        title='Pronto!'
        onConfirm={handlerFinally}
        customButtons={
          <ButtonRounded isBig={true} onClick={handlerFinally} className='w-100 m-4'>
            Pronto
          </ButtonRounded>
        }
      >
        <p> Aluno cadastrado com sucesso.</p>
      </SweetAlert>
      <div className='container-fluid container_painel'>
        <div className='container'>
          {/* <div className='row d-flex align-items-center justify-content-center pt-1 pb-5'>
            <img src='/img/clubes/fensor.svg' className='logo' alt='' />
          </div> */}
          <div className='row'>
            <main className='content_panel'>
              <StudentFormContext.Provider value={formContext}>
                {state.done === false && children}
              </StudentFormContext.Provider>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
}
//contract form -------------------------------------------------------------------------------------------
const stateEsportesSchema = {
  schoolYear: { value: '', error: '' },
  skillLevel: { value: '1', error: '' },
  sublevel: { value: 'BEGINNER', error: '' },
  position: { value: '', error: '' },
  sport: { value: '', error: '' },
  athLateralidade: { value: '', error: '' },
  certificado: { value: false, error: '' },
};

const stateAtletaSchema = {
  id: { value: 0, error: '' },
  user_id: { value: 0, error: '' },
  athId: { value: 0, error: '' },
  name: { value: '', error: '' },
  usuStatus: { value: 'A', error: '' },
  login: { value: '', error: '' },
  email: { value: '', error: '' },
  athRG: { value: '', error: '' },
  usuCPF: { value: '', error: '' },
  usuBirth: { value: '', error: '' },
  usuPhone: { value: '', error: '' },
  usuGender: { value: '', error: '' },
  athCodigoAluno: { value: '', error: '' },
  athTipoStudent: { value: 'Regular', error: '' },
  expirationDateMedicalExamination: { value: '', error: '' },
  foto: { value: '', error: '' },
  companie_id: { value: '', error: '' },
  school_id: { value: '', error: '' },
  role_id: { value: '', error: '' },
  usuStudent: { value: '', error: '' },
  location: { value: 'pt-BR', error: '' },
  athIndication: { value: '', error: '' },
  session_user_id: { value: 0, error: '' },
};

const stateSelectedTurmasSchema = {
  classs: { value: [], error: '' },
};

const stateMatriculaSchema = {
  id: { value: 0, error: '' },
  paymentDate: { value: null, error: '' },
  expirationDate: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentModo: { value: 'A vista', error: '' },
  parcelas: { value: 1, error: '' },
  valorOriginal: { value: 0, error: '' },
  contaContabil: { value: '', error: '' },
  observation: { value: '', error: '' },
  payment_status: { value: '', error: '' },
  discount_type: { value: 'NONE', error: '' },
  discount: { value: '', error: '' },
  payment_id_asaas: { value: '', error: '' },
  late_interest_type: { value: 'NONE', error: '' },
  late_interest: { value: '', error: '' },
  late_fee_type: { value: 'NONE', error: '' },
  late_fee: { value: '', error: '' },
  receber: { value: 'N', error: '' },
  valorReceber: { value: '', error: '' },
  anexo: { value: '', error: '' },
  contract: { value: null, error: '' },
  billingType: { value: 'MONEY', error: '' },
  faturado: { value: 'N', error: '' },
};
const stateFiadorSchema = {
  athNomeRespFin: { value: '', error: '' },
  athRGRespFin: { value: '', error: '' },
  athCPFRespFin: { value: '', error: '' },
  athEmailRespFin: { value: '', error: '' },
  athParentescoRespFin: { value: '', error: '' },
  athPhoneRespFin: { value: '', error: '' },
};

const stateMensalSchema = {
  id: { value: 0, error: '' },
  dataVencimento: { value: '', error: '' },
  dataInicio: { value: moment().format('YYYY-MM-DD'), error: '' },
  dataFim: { value: '', error: '' },
  paymentMethod: { value: '', error: '' },
  paymentCycle: { value: 'MONTHLY', error: '' },
  service: { value: '', error: '' },
  seguro: { value: '', error: '' },
  tipoDesconto: { value: 'NONE', error: '' },
  desconto: { value: '', error: '' },
  valor: { value: '', error: '' },
  valorOriginal: { value: '', error: '' },
  valorComSeguro: { value: '', error: '' },
  tipoJuros: { value: 'NONE', error: '' },
  valorJuros: { value: '', error: '' },
  tipoMulta: { value: 'NONE', error: '' },
  valorMulta: { value: '', error: '' },
  statusContrato: { value: 'Ativo', error: '' },
  cargaHora: { value: '', error: '' },
  observacao: { value: '', error: '' },
  novoContrato: { value: 0, error: '' },
  faturado: { value: 'N', error: '' },
  anexo: { value: '', error: '' },
};

const stateCobrancaSchema = {
  financas: [],
};

const stateResponsaveisSchema = {
  athCep: { value: '', error: '' },
  athLogradouro: { value: '', error: '' },
  athBairro: { value: '', error: '' },
  athMaeNome: { value: '', error: '' },
  athPaiNome: { value: '', error: '' },
  athMaeCPF: { value: '', error: '' },
  athPaiCPF: { value: '', error: '' },
  athMaeCelular: { value: '', error: '' },
  athPaiCelular: { value: '', error: '' },
  uf: { value: '', error: '' },
  numero: { value: '', error: '' },
  cidade: { value: '', error: '' },
  cidadeID: { value: 0, error: '' },
  complemento: { value: '', error: '' },
  descricao: { value: '', error: '' },
  questionario: { value: '', error: '' },
};
