import React, { useEffect, useState, useRef, useContext } from 'react';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, {
  correctImageOrientation,
  getErrorMessageFromFileResponse
} from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  getUserPhoneNumbers,
  sendWhatsAppMessageWithFileAlt,
  sendWhatsAppMessage,
} from '../../services/WhatsAppService';
import { UserContext } from '../../Context/UserContext';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import { GrDocument } from 'react-icons/gr';
import { Autocomplete } from '@material-ui/lab';
import { Avatar, Box, TextField } from '@material-ui/core';
import CKEditorDocument from '../../components/school/EditorRich';

const Communication = () => {
  const { t, i18n } = useTranslation();
  const [id, setID] = useState(null);
  const [role_id, setRoleID] = useState('');
  const [user_id, setUserID] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [from_json, setFromJson] = useState('');
  const [school_id, setSchoolID] = useState('');
  const [status, setStatus] = useState('');
  const [date_show, setDateShow] = useState('');
  const [dirImage, setDirImage] = useState('');
  const [foto, setFoto] = useState('');
  const [label, setLabel] = useState('');
  const [documents, setDocuments] = useState([]);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));

  // carregamentos
  const [communications, setCommunications] = useState([]);
  const [classs, setClasss] = useState([]);
  const [classModal, setClassModal] = useState('');
  const [classsSavedModal, setClasssSavedModal] = useState({ from_json: null, class_json: [] });
  const [classsModal, setClasssModal] = useState([]);
  const [studentsModal, setStudentsModal] = useState([]);

  // filtros
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDescription, setFilterDescription] = useState('');
  const [filterData, setFilterData] = useState('');
  const [filterStatus, setFilterStatus] = useState('A');
  const [communicationsFilter, setCommunicationsFilter] = useState([]);
  const [display, setDisplay] = useState({});
  const [renderDisplay, setRenderDisplay] = useState(false);
  const [displayDetail, setDisplayDetail] = useState(false);

  // process
  const [process, setProcess] = useState(false);
  const [sendUpload, setSendingUpload] = useState(false);
  const [sendSave, setSendSave] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [selectFilerData, setSelectfiltered] = useState([]);

  //WhatsApp
  const [detail, setDetail] = useState({});

  // libraries
  const baseURL = Api();
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  const modalScroll = useRef();
  const whatsAppComponentRef = useRef();
  const { school, user } = useContext(UserContext);

  useEffect(() => {
    async function getCommunications(school_id, role_id, user_id) {
      setProcess(true);
      const res = await axios.post(baseURL + 'get-communication-school', {
        school_id,
        role_id,
        user_id,
      });

      setCommunications(res.data.communications);
      setCommunicationsFilter(
        res.data.communications.filter((communication) => {
          return communication.status === filterStatus;
        })
      );
      const url = new URL(res.data.dirImage);

      setDirImage(res.data.dirImage);

      setClasss(res.data.class);

      setStudentsModal(
        res.data.students.map((student) => {
          student.path_avatar = `${url.origin}/upload/schools/students/${student.usuFotoPrincipal}`;
          return student;
        })
      );
      setProcess(false);
    }

    async function getSession() {
      setRoleID(user.role_id);
      setUserID(user.id);
      setSchoolID(school.id);
      getCommunications(school.id, user.role_id, user.id);
    }

    getSession();

    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      close();
      whatsAppComponentRef.current.closeModal();
    }
  };

  const search = (e) => {
    e.preventDefault();
    let registers = [];
    if (filterTitle !== '') {
      registers = communications.filter((item) => {
        return (
          (item.title.toLowerCase().indexOf(filterTitle) !== -1 ||
            item.description.toLowerCase().indexOf(filterTitle) !== -1) &&
          item.type == 'Notícia'
        );
      });
    } else {
      registers = communications;
    }

    if (filterDescription !== '') {
      registers = registers.filter((item) => {
        return (
          (item.title.toLowerCase().indexOf(filterTitle) !== -1 ||
            item.description.toLowerCase().indexOf(filterTitle) !== -1) &&
          item.type == 'Comunicado'
        );
      });
    }

    if (filterData !== '') {
      registers = registers.filter((item) => {
        return item.date_show.substr(0, 10) == filterData;
      });
    }

    if (filterStatus !== '') {
      registers = registers.filter((item) => {
        return item.status == filterStatus;
      });
    }

    setCommunicationsFilter(registers);
  };

  const addClass = ({ value, name }) => {
    setSuccess(false);
    setError(false);

    setClasssModal((current) => [
      ...current.filter((c) => +c.id !== +value),
      {
        id: value,
        name: name,
      },
    ]);

    setClassModal('');
  };

  const delClass = (item) => {
    setSuccess(false);
    setError(false);
    setClasssModal((current) => current.filter((e) => e.id !== item.id));
  };

  useEffect(() => {
    const add = urlParams.get('add');
    if (add === 'true') {
      showComunicado();
    }
  }, [urlParams]);
  const showNoticia = () => {
    setError(false);
    setSuccess(false);

    setTitle('');
    setDescription('');
    setType('Notícia');
    setStatus('');
    setFromJson([]);
    setClasssSavedModal({ from_json: null, class_json: [] });
    setClasssModal([]);
    setID(null);
    setLabel('Notícia');
    setFoto('');
    setDocuments([]);
    setDateShow(new Date().toISOString().substr(0, 10));

    setRenderDisplay(true);

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const showComunicado = () => {
    setError(false);
    setSuccess(false);

    setTitle('');
    setDescription('');
    setStatus('');
    setType('Comunicado');
    setFromJson([]);
    setClasssSavedModal({ from_json: null, class_json: [] });
    setClasssModal([]);
    setID(null);
    setLabel('Comunicado');
    setFoto('');
    setDocuments([]);
    setDateShow(new Date().toISOString().substr(0, 10));
    setRenderDisplay(true);
    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const close = () => {
    setRenderDisplay(false);
    setDisplay({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    if (!id && date_show < new Date().toISOString().substr(0, 10)) {
      setError(true);
      setMessage('CommunicationPage.SystemMessage.DateInvalid');
      return;
    }

    let data = {};
    let endpoint = '';
    if (id === null) {
      endpoint = 'save-communication-school';
      data = {
        user_id: user_id,
        school_id: school_id,
        title: title,
        description: description,
        type: type,
        status: status,
        from_json: from_json,
        class_json: JSON.stringify(classsModal),
        date_show: date_show,
        img: foto,
        documents: documents,
      };
    } else {
      endpoint = 'update-communication-school';
      data = {
        id: id,
        school_id: school_id,
        title: title,
        description: description,
        type: type,
        status: status,
        from_json: from_json,
        class_json: JSON.stringify(classsModal),
        date_show: date_show,
        img: foto,
        documents: documents,
      };
    }

    setSendSave(true);
    const res = await axios.post(baseURL + endpoint, data);
    setSendSave(false);
    setSuccess(true);
    setID(res.data.create.id);
    setMessage('SystemMessage.Success.' + res.data.messageKey);
    setCommunications(res.data.communications);
    setCommunicationsFilter(
      res.data.communications.filter((communication) => {
        return communication.status === filterStatus;
      })
    );
    scrollTop(modalScroll);
  };

  const handleFoto = async (e) => {
    const foto = await correctImageOrientation(e.target.files[0]);
    const formData = new FormData();
    formData.append('foto', foto);

    setError(false);
    setSuccess(false);
    setSendingUpload(true);
    const res = await axios.post(baseURL + 'upload-communication-school', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (res.data.success === false) {
      setError(true);
      setSuccess(false);

      if (res.data.erro) {
        setMessage(getErrorMessageFromFileResponse(res.data.erro));
      } else {
        setMessage('SystemMessage.File.Fail.' + res.data.messageKey);
      }
    } else {
      setSuccess(true);
      setMessage('SystemMessage.File.Success.PhotoUpdateSuccess');
      setFoto(res.data.nomeArq);
      scrollTop(modalScroll);
    }

    setSendingUpload(false);
  };

  const handleAnexo = async (e) => {
    const anexo = e.target.files[0];

    const formData = new FormData();
    formData.append('anexo', anexo);

    setError(false);
    setSuccess(false);
    setSendingUpload(true);

    const res = await axios.post(baseURL + 'attach-communication-school', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (res.data.success === false) {
      setError(true);
      setSuccess(false);

      if (res.data.erro) {
        setMessage(getErrorMessageFromFileResponse(res.data.erro));
      } else {
        setMessage('SystemMessage.File.Fail.' + res.data.messageKey);
      }
    } else {
      setSuccess(true);
      setMessage('SystemMessage.File.Success.DocumentAttachedSuccess');
      setDocuments([...documents, { id: null, filename: res.data.nomeArq, name: res.data.arq }]);

      scrollTop(modalScroll);
    }

    setSendingUpload(false);
  };

  const handleDeleteAnexo = async ({ id, filename }) => {
    setError(false);
    setSuccess(false);
    setSendingUpload(true);

    try {
      const {
        data: { success, messageKey },
      } = await axios.post(baseURL + 'detach-communication-school', { id, filename });
      if (success) {
        setDocuments([...documents].filter((document) => document.id !== id));
        setSuccess(true);
        setMessage('SystemMessage.File.Success.' + messageKey);
      } else {
        setError(true);
        setMessage('SystemMessage.File.Fail.' + messageKey);
      }
    } catch (error) {
      setError(true);
      setMessage('SystemMessage.Fail.InternalError');
    }
    setSendingUpload(false);
    scrollTop(modalScroll);
  };

  const edit = (e, comm) => {
    setError(false);
    setSuccess(false);

    setID(comm.id);
    setTitle(comm.title);
    setType(comm.type);
    setDescription(comm.description);
    setStatus(comm.status);
    setFromJson(comm.from_json);
    setClasssSavedModal({
      from_json: comm.from_json,
      class_json: comm.class_json !== '' ? JSON.parse(comm.class_json) : [],
    });
    setClasssModal(comm.class_json !== '' ? JSON.parse(comm.class_json) : []);
    setDateShow(comm.date_show);
    setID(comm.id);
    setLabel(comm.type);
    setFoto('');
    if (comm.img !== null) setFoto(comm.img);

    setDocuments([]);
    if (comm.communication_documents && comm.communication_documents.length > 0) {
      setDocuments(comm.communication_documents);
    }
    setRenderDisplay(true);
    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const setErrorFoto = () => {
    scrollTop(modalScroll);
    setError(true);
    setMessage(
      label === 'Notícia'
        ? 'CommunicationPage.SystemMessage.FileNewsMustBeSaved'
        : 'CommunicationPage.SystemMessage.FileCommunicationMustBeSaved'
    );
  };

  const openDetail = (item) => {
    setDisplayDetail(true);
    setDetail(item);
    window.scrollTo(0, 0);
  };

  const closeDetail = () => {
    setDisplayDetail(false);
    setDetail({});
  };

  const openSendWhatsApp = async (item) => {
    let itemSemHTML = JSON.parse(JSON.stringify(item));
    itemSemHTML.description = item.description.replace(/<[^>]*>/g, '');
    setDetail(itemSemHTML);
    whatsAppComponentRef.current.showModal();
  };

  const sendMessageWhatsApp = async (item) => {
    const class_json = JSON.parse(item.class_json).map((e) => e.id);

    const sendTo = {
      T: 'ALL',
      F: 'EMPLOYEE',
      A: 'STUDENT_CLASS',
      E: 'STUDENT',
    };

    const { status, users } = await getUserPhoneNumbers({ ids: class_json, type: sendTo[item.from_json] });

    if (status !== 'SUCCESS') {
      return { success: false, message: t('WhatsAppComponent.SendFailMessage.UnableRetrieveContactList') };
    }

    const phoneNumbers = users.map(({ phone }) => phone);

    if (phoneNumbers.length == 0) {
      return { success: false, message: t('WhatsAppComponent.SendFailMessage.NoContactsToSend') };
    }

    const phoneNumbersDDI =
      i18n.resolvedLanguage === 'pt-BR' ? phoneNumbers.map((phoneNumber) => `55${phoneNumber}`) : phoneNumbers;

    const documents =
      item.communication_documents && item.communication_documents.length > 0 ? item.communication_documents : [];

    const response = item.img
      ? await sendWhatsAppMessageWithFileAlt({
          numbersList: phoneNumbersDDI,
          message: `*${item.title.toUpperCase()}*\n${item.description}`,
          fileLink: [`${dirImage}/${item.img}`, ...documents.map((d) => dirImage + '/' + d.filename)],
        })
      : await sendWhatsAppMessage({
          numbersList: phoneNumbersDDI,
          message: `*${item.title.toUpperCase()}*\n${item.description}`,
        });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.SendSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            {response.data?.map(({ to: name }) => (
              <>
                <b>{name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
                <br />
              </>
            ))}
          </div>
        ),
      };
    }
  };

  const handlerAplyFilter = (e) => {
    let filtered = studentsModal || [];

    e.preventDefault();

    if (e.target.value === 'A') {
      filtered = classs.filter((studentClass) => !classsModal.some((c) => c.id == studentClass.id));
    }

    if (e.target.value === 'I') {
      filtered = filtered.filter((s) => s.student_finances_count > 0);
    }

    filtered.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    setSelectfiltered(filtered);
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                {role_id !== 2 && (
                  <div className='card' style={{ flexDirection: 'column' }}>
                    <div className='card_header'>
                      <h2 className='card_title'>{t('CommunicationPage.AddCommunicationCard.NewsTitle')}</h2>
                      <p className='card_subtitle'>{t('CommunicationPage.AddCommunicationCard.NewsSubtitle')}</p>
                    </div>
                    <div className='card-actions'>
                      <div className='card-action-row'>
                        <button
                          onClick={showNoticia}
                          className='btn_card mx-auto mt-4 btn_open_modal'
                          data-modal='#noticia'
                        >
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('CommunicationPage.AddCommunicationCard.NewsButton')}
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className='card_header'>
                      <h2 className='card_title'>{t('CommunicationPage.AddCommunicationCard.CommunicationTitle')}</h2>
                      <p className='card_subtitle'>
                        {t('CommunicationPage.AddCommunicationCard.CommunicationSubtitle')}
                      </p>
                    </div>
                    <div className='card-actions'>
                      <div className='card-action-row'>
                        <button
                          onClick={showComunicado}
                          className='btn_card mx-auto mt-4 btn_open_modal'
                          data-modal='#comunicado'
                        >
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('CommunicationPage.AddCommunicationCard.CommunicationButton')}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('CommunicationPage.FilterCommunicationsCard.Title')}</h2>
                    <p className='card_subtitle'>{t('CommunicationPage.FilterCommunicationsCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='news' className='card_details_label'>
                        {t('News', { count: 2 })}
                      </label>
                      <input
                        type='text'
                        name='news'
                        className='form_control'
                        onChange={(e) => setFilterTitle(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('Communications')}
                      </label>
                      <input
                        type='text'
                        name='schedule'
                        className='form_control'
                        onChange={(e) => setFilterDescription(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='date' className='card_details_label'>
                        {t('Date')}
                      </label>
                      <input
                        type='date'
                        name='date'
                        className='form_control'
                        onChange={(e) => setFilterData(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('Status')}
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value='A'>{t('StatusType.Active')}</option>
                        <option value='I'>{t('StatusType.Inactive')}</option>
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card_noticia' style={{ display: displayDetail ? 'inline' : 'none' }}>
                  <div className='img_noticia'>
                    <img src={dirImage + '/' + detail.img} alt='' />
                  </div>
                  <div className={`comunicado_type ${String(detail.type).toLowerCase().replace('í', 'i')}`}>
                    {t(detail.type)}
                  </div>
                  <div className='noticia_content'>
                    <h2 className='comunicado_title'>{detail.title}</h2>
                    <div className='comunicado_text' dangerouslySetInnerHTML={{ __html: detail.description }}></div>
                    <div>
                      {detail.communication_documents &&
                        detail.communication_documents.length > 0 &&
                        detail.communication_documents.map((document) => (
                          <li key={document.id + '_document_list'}>
                            <a className='table_mes' target='_black' href={dirImage + '/' + document.filename}>
                              {document.name}
                            </a>
                          </li>
                        ))}
                    </div>

                    <button
                      onClick={closeDetail}
                      className='btn_card mx-auto mt-4 btn_open_modal'
                      data-modal='#comunicado'
                      style={{ background: 'grey', border: 'grey' }}
                    >
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Close')}
                    </button>
                  </div>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('CommunicationPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('CommunicationPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      communicationsFilter.map((item, i) => {
                        return (
                          <div className='card_list_comunicado' key={i}>
                            <div className='img_list_comunicado'>
                              <img src={dirImage + '/' + item.img} alt='' style={{ width: '100%' }} />
                            </div>
                            <div className='content_card'>
                              <div className={`comunicado_type ${item.type.toLowerCase().replace('í', 'i')}`}>
                                {t(item.type)}
                              </div>
                              <h2 className='comunicado_title'>{item.title}</h2>
                              <div
                                className='comunicado_text'
                                dangerouslySetInnerHTML={{ __html: item.description }}
                              ></div>
                              <div
                                style={{ flexDirection: 'row', display: 'flex', gap: '7px' }}
                                className='flex-column flex-sm-row align-items-center'
                              >
                                {role_id !== 2 && (
                                  <span
                                    onClick={(e) => edit(e, item)}
                                    className='btn_card'
                                    style={{
                                      backgroundColor: 'orange',
                                      border: '1px solid orange',
                                      fontWeight: '500',
                                      // marginRight: 7,
                                    }}
                                  >
                                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                    </svg>
                                    {t('CommunicationPage.CommunicationCard.Change')}
                                  </span>
                                )}

                                <span onClick={() => openDetail(item)} className='btn_card'>
                                  <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                  </svg>
                                  {t('CommunicationPage.CommunicationCard.SeeDetails')}
                                </span>
                                {role_id !== 2 && (
                                  <span
                                    onClick={() => openSendWhatsApp(item)}
                                    className='btn_card'
                                    style={{
                                      backgroundColor: 'green',
                                      border: '1px solid green',
                                      fontWeight: '500',
                                      // marginLeft: '7px',
                                    }}
                                  >
                                    <svg id='whatsapp' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                      <g>
                                        <path
                                          d='M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                                  C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                                  c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                                  c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                                  c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                                  c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                                  c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                                  c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                                  c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z'
                                        />
                                      </g>
                                    </svg>
                                    {t('CommunicationPage.CommunicationCard.SendViaWhatsApp')}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <WhatsAppComponent
                ref={whatsAppComponentRef}
                sendMessageWhatsApp={() => sendMessageWhatsApp(detail)}
                onClose={() => {
                  setDetail({});
                }}
                sendButtonText={
                  detail.type === 'Notícia'
                    ? t('WhatsAppComponent.SendButtonText.SendNews')
                    : detail.type === 'Comunicado'
                    ? t('WhatsAppComponent.SendButtonText.SendCommunication')
                    : ''
                }
              />
              {renderDisplay && (
                <div className='modal' id='add_noticia_comunicado' style={display}>
                  <div className='modal_content' ref={modalScroll}>
                    <div className='modal_close' onClick={close}>
                      X
                    </div>
                    <h3 className='modal_title'>
                      {label === 'Notícia' && id === null
                        ? t('CommunicationPage.Modal.TitleAddNews')
                        : label === 'Notícia' && id !== null
                        ? t('CommunicationPage.Modal.TitleEditNews')
                        : label === 'Comunicado' && id === null
                        ? t('CommunicationPage.Modal.TitleAddCommunication')
                        : label === 'Comunicado' && id !== null
                        ? t('CommunicationPage.Modal.TitleEditCommunication')
                        : ''}
                    </h3>
                    {error && (
                      <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                    )}
                    {success && (
                      <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                    )}
                    <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='title' className='card_details_label'>
                            {t('Title')}
                          </label>
                          <input
                            type='text'
                            name='title'
                            value={title}
                            required
                            className='form_control'
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                        <div className='group_50'>
                          <div className='form_group'>
                            <label htmlFor='title' className='card_details_label'>
                              {t('AsFrom')}
                            </label>
                            <input
                              type='date'
                              name='date_show'
                              value={date_show}
                              required
                              className='form_control'
                              onChange={(e) => setDateShow(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='group_50'>
                          <div className='form_group'>
                            <label htmlFor='title' className='card_details_label'>
                              {t('Status')}
                            </label>
                            <select
                              required
                              value={status}
                              className='form_control select'
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value=''></option>
                              <option value='A'>{t('StatusType.Active')}</option>
                              <option value='I'>{t('StatusType.Inactive')}</option>
                            </select>
                          </div>
                        </div>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('Description')}
                          </label>
                          <CKEditorDocument
                            name='descript'
                            initialValue={description}
                            handleEditorData={(e) => {
                              setDescription(e);
                            }}
                            visible={false}
                          />
                        </div>
                      </div>
                      {sendUpload && (
                        <div
                          className='group_50'
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#218135',
                            borderRadius: '10px',
                            color: 'white',
                            flexDirection: 'column',
                          }}
                        >
                          <label htmlFor='file'>{t('SystemMessage.Loading.WaitSending')}</label>
                          <Loading type='3' style={{ color: 'white', marginTop: 5 }} />
                        </div>
                      )}
                      {foto !== '' && !sendUpload && (
                        <div className='group_50' style={{ justifyContent: 'space-around' }}>
                          <img
                            style={{ borderRadius: 10, marginBottom: 10, width: '100%', height: '230px' }}
                            src={`${dirImage}/${foto}`}
                          />
                          <label
                            style={{
                              cursor: 'pointer',
                              padding: '12px 22px',
                              borderRadius: '50px',
                              border: '1px solid rgb(2, 132, 44)',
                              color: 'rgb(2, 132, 44)',
                              fontSize: '13px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignSelf: 'center',
                            }}
                          >
                            <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                            {t('ChangePhoto')}
                          </label>
                        </div>
                      )}
                      {!foto && (
                        <div className='group_50 upload_file' style={{ position: 'relative' }}>
                          <label htmlFor='file'></label>
                          <input
                            type='file'
                            name='file[]'
                            id='file'
                            onChange={(e) => handleFoto(e)}
                            style={{ display: 'initial', opacity: 0, cursor: 'pointer', width: '100%', height: '100%' }}
                          />
                        </div>
                      )}

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='position' className='card_details_label'>
                            {t('SendTo')}
                          </label>
                          <select
                            required
                            multiple={false}
                            className='form_control select'
                            onChange={(e) => {
                              setFromJson(e.target.value);

                              handlerAplyFilter(e);

                              if (e.target.value === classsSavedModal.from_json)
                                setClasssModal(classsSavedModal.class_json);
                              else setClasssModal([]);
                            }}
                          >
                            <option value=''></option>
                            <option value='T'>{t('All')}</option>
                            <option value='F'>{t('Employees')}</option>
                            <option value='A'>{t('Classes_other')}</option>
                            <option value='E'>{t('Athletes')}</option>
                            <option value='I'>{t('Athletes')} Inadimplentes</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='position' className='card_details_label'>
                            {t('Recipient_alt')}
                          </label>
                          <Autocomplete
                            disabled={!['A', 'E', 'I'].includes(from_json)}
                            autoComplete
                            renderInput={(params) => <TextField {...params} variant='outlined' />}
                            options={selectFilerData}
                            getOptionLabel={(option) => option.name}
                            autoHighlight
                            renderOption={(props, option) => {
                              const { key, ...optionProps } = props;

                              const user = studentsModal.find((student) => student.id === props?.id);

                              if (user) {
                                return (
                                  <Box component='li' {...optionProps} key={key}>
                                    <Avatar
                                      alt={user.name}
                                      src={user.path_avatar}
                                    />
                                    {user.name}
                                    {props.usuCPF ? <small> - {props.usuCPF}</small> : ''}
                                  </Box>
                                );
                              }
                              return (
                                <Box component='li' {...optionProps} key={key}>
                                  {props.name}{' '}
                                </Box>
                              );
                            }}
                            onChange={(e, data) => {
                              e.preventDefault();
                              if (data) {
                                addClass({ value: data.id, name: data.name });
                              }
                            }}
                            inputValue={classModal}
                            onInputChange={(_, newInputValue) => setClassModal(newInputValue)}
                          />
                        </div>
                        <div className='list_select'>
                          {classsModal.map((item, i) => (
                            <div className='list_item' key={`resp_${i}`}>
                              <p style={{ marginRight: '2px' }}>{item.name}</p>
                              <button type='button' className='remove' onClick={() => delClass(item)}>
                                <svg
                                  width='10'
                                  height='10'
                                  viewBox='0 0 10 10'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z'
                                    fill='#B2B1B1'
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <h3 className='modal_title' style={{ marginBottom: 10 }}>
                        {t('Attachments')}
                        <label
                          className='btn_card'
                          htmlFor='file-attach'
                          style={{
                            marginTop: 0,
                            height: '28px',
                            display: 'inline-block',
                            marginLeft: '10px',
                            minWidth: '50px',
                            fontSize: 'small',
                          }}
                        >
                          {sendUpload ? t('SystemMessage.Loading.Processing') : t('AttachFile')}
                        </label>
                      </h3>

                      <div className='form_group'>
                        <input id='file-attach' type='file' onChange={handleAnexo} style={{ display: 'none' }} />
                        <div
                          style={{
                            border: '2px dashed var(--main-color)',
                            minHeight: '50px',
                            borderRadius: '8px',
                            paddingTop: '30px',
                          }}
                        >
                          <ul style={{ marginLeft: '30px', listStyle: 'disc' }}>
                            {documents.map((document) => (
                              <li key={document.name + '_document_list'} className='d-flex mb-5 align-items-center'>
                                {document.filename.substr(document.filename.length - 3) === 'png' ? (
                                  <img
                                    src={dirImage + '/' + document.filename}
                                    style={{ width: '130px', borderRadius: '4px' }}
                                  />
                                ) : document.filename.substr(document.filename.length - 3) === 'jpg' ? (
                                  <img
                                    src={dirImage + '/' + document.filename}
                                    style={{ width: '130px', borderRadius: '4px' }}
                                  />
                                ) : (
                                  <div style={{ width: '130px' }} className='d-flex justify-content-center'>
                                    <GrDocument size={44} />
                                  </div>
                                )}
                                <a className='table_mes mx-3' target='_black' href={dirImage + '/' + document.filename}>
                                  {document.name}
                                </a>
                                <svg
                                  onClick={() => handleDeleteAnexo(document)}
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='25'
                                  height='25'
                                  fill='red'
                                  className='bi bi-x'
                                  viewBox='0 0 16 16'
                                  style={{ cursor: 'pointer' }}
                                >
                                  <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
                                </svg>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <button className='btn_card mx-auto' disabled={sendSave}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {sendSave ? (
                          <>
                            {t('SystemMessage.Loading.Processing')}
                            <Loading type='1' style={{ marginLeft: '2%' }} />
                          </>
                        ) : label === 'Notícia' && id === null ? (
                          t('CommunicationPage.Modal.ButtonAddNews')
                        ) : label === 'Notícia' && id !== null ? (
                          t('CommunicationPage.Modal.ButtonEditNews')
                        ) : label === 'Comunicado' && id === null ? (
                          t('CommunicationPage.Modal.ButtonAddCommunication')
                        ) : label === 'Comunicado' && id !== null ? (
                          t('CommunicationPage.Modal.ButtonEditCommunication')
                        ) : (
                          ''
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Communication;
