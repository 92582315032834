import axios from 'axios';
import Api from '../utils/api';
import { buildFormData } from '../utils/utils';
import { defaultApiObject, handleFail } from '../utils/utils-api';
import api from './api';
import { APIError } from './ExceptionClass/APIError';

const baseURL = Api();

export const getStudentsSchool = ({ schoolId, companieId, paginationOffset, paginationLimit, filters }) =>
  new Promise((response, reject) => {
    const requestBody = {
      school_id: schoolId,
      companie_id: companieId,
      pagination: { offset: paginationOffset, limit: paginationLimit },
      filters: filters,
    };
    axios
      .post(baseURL + 'get-students-school', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const saveStudentSchool = async (student) => {
  const studentFormData = serializeStudentData(student);

  const { data } = await axios.post(baseURL + 'save-student-school', studentFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data);
  }
};

export const saveStudentLinkSchool = async (student, type) => {
  const studentFormData = serializeStudentData(student);

  const url = type === 'full' ? 'save-student-link-school' : 'save-student-short-link-school';

  const { data } = await axios.post(baseURL + url, studentFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data);
  }
};

export const updateStudentSchool = async (student) => {
  const studentFormData = serializeStudentData(student);

  const { data } = await axios.post(baseURL + 'update-student-school', studentFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data);
  }
};

export const updateStudentLinkSchool = async (student, type) => {
  const studentFormData = serializeStudentData(student);

  const url = type === 'full' ? 'update-student-link-school' : 'update-student-short-link-school';

  const { data } = await axios.post(baseURL + url, studentFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data);
  }
};

const serializeStudentData = (student) => {
  return buildFormData(student);
};

export const uploadStudentPhotoSchool = ({ schoolId, userId, photo, paginationLimit, filters }) =>
  new Promise((response, reject) => {
    const formData = new FormData();
    formData.append('school_id', schoolId);
    formData.append('id', userId);
    formData.append('foto', photo);
    formData.append('pagination', JSON.stringify({ offset: 0, limit: paginationLimit }));
    formData.append('filters', JSON.stringify(filters));

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'upload-student-school', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const uploadStudentLinkPhotoSchool = ({ schoolId, userId, photo }) =>
  new Promise((response, reject) => {
    const formData = new FormData();
    formData.append('school_id', schoolId);
    formData.append('id', userId);
    formData.append('foto', photo);

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'upload-student-link-school', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getDocumentTypes = () =>
  new Promise((response, reject) => {
    axios
      .get(baseURL + 'get-document-types')
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getDocumentStudent = (athleteId) =>
  new Promise((response, reject) => {
    const requestBody = {
      athlete_id: athleteId,
    };
    axios
      .post(baseURL + 'get-document-student', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const uploadDocumentStudent = ({ athleteID, documentID, athletedocument_id, file, from_school = 1 }) =>
  new Promise((response, reject) => {
    const formData = new FormData();
    formData.append('documento', file);
    formData.append('document_id', documentID);
    formData.append('athletedocument_id', athletedocument_id);
    formData.append('id', athleteID);
    formData.append('from_school', from_school);

    const requestOptions = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .post(baseURL + 'upload-document-athlete', formData, requestOptions)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const destroyDocumentStudent = (id) =>
  new Promise((response, reject) => {
    const requestBody = {
      id: id,
    };
    axios
      .post(baseURL + 'destroy-document-athlete', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getThemeBySchool = async (schoolId) =>
  new Promise((response, reject) => {
    const requestBody = {
      id: schoolId,
    };
    axios
      .post(baseURL + 'get-school-theme', requestBody)
      .then(({ data }) => response(data))
      .catch((error) => reject(error));
  });

export const getStudentClasses = async (studentId) => {
  try {
    const { data } = await axios.post(baseURL + 'get-student-classes', { user_id: studentId });
    return data;
  } catch (error) {
    return { success: false, error };
  }
};

export const getStudentInactivate = async (studentId) => {
  try {
    const { data } = await axios.get(baseURL + 'get-student-inactivate/' + studentId);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const removeStudentFromClass = async ({ studentId, classId }) => {
  try {
    const { data } = await axios.post(baseURL + 'remove-student-from-class', { studentId, classId });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const removeStudentFromAllClasses = async (studentId) => {
  try {
    const { data } = await axios.post(baseURL + 'remove-student-from-all-classes', { studentId });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getStudentAnamnesisForm = async (athleteId) => {
  try {
    const { data } = await api.get(`get-student-anamnesis-form/${athleteId}`);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const saveStudentAnamnesisForm = async (athleteId, form) => {
  try {
    const { data } = await api.post('save-student-anamnesis-form', { athleteId, ...form });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const createTrainingCenter = async ({ name }) => {
  try {
    const { data } = await api.post('create-training-center', { name });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const updateTrainingCenter = async ({ id, name }) => {
  try {
    const { data } = await api.put(`update-training-center/${id}`, { name });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const deleteTrainingCenter = async ({ id }) => {
  try {
    const { data } = await api.delete(`delete-training-center/${id}`);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const getAllTrainingCenters = async () => {
  try {
    const { data } = await api.get('get-training-centers');
    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const fetchCertificateConfigurations = async () => {
  try {
    const { data } = await api.get(`get-certificate-configuration`);

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};

export const updateCertificateConfiguration = async ({ id, level, title, subtitle, background_file }) => {
  try {
    const { data } = await api.post(
      `update-certificate-configuration/${id}`,
      buildFormData(
        {
          level,
          title,
          subtitle,
          background_file,
          _method: 'PUT',
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    );

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error);
  }
};
export const wizardCreateStudent = async (formData) => {
  try {
    return await axios.post(`${baseURL}create-complete-student`, formData);
  } catch (error) {
    return defaultApiObject(true, error);
  }
};
export const activeStudents = async (id_school) => {
  try {
    return await axios.get(`${baseURL}school/students/${id_school}`);
  } catch (error) {
    return defaultApiObject(true, error);
  }
};
